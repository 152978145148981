import 'animate.css/animate.min.css'
import { ToastContainer, cssTransition } from 'react-toastify'

import { AUTO_CLOSE_TIMER } from 'constants/NotifierTypes'

const goRight = cssTransition({
  enter: 'animate__animated animate__fadeInRight',
  exit: 'animate__animated animate__fadeOutUp'
})

const Notifier = (props) => (
  <ToastContainer
    position="bottom-right"
    autoClose={AUTO_CLOSE_TIMER}
    transition={goRight}
    hideProgressBar
    closeOnClick
    pauseOnFocusLoss
    pauseOnHover={false}
    draggable={false}
    newestOnTop={false}
    rtl={false}
    closeButton
    // limit={MESSAGES_LIMIT}
    {...props}
  />
)

export default Notifier
