import DatePicker, { registerLocale } from 'react-datepicker'
import ruLocale from 'date-fns/locale/ru'
import s from 'styled-components'
import { months } from 'constants/Date'

import { Box } from 'layout'
import { Text } from 'components/typo'
import ButtonRound from 'components/base/ButtonRound'

import { useEffect, useState } from 'react'
import { ReactComponent as ChevronLeft } from './icons/left.svg'
import { ReactComponent as ChevronLeftDouble } from './icons/left-double.svg'
import { ReactComponent as ChevronRight } from './icons/right.svg'
import { ReactComponent as ChevronRightDouble } from './icons/right-double.svg'

import 'react-datepicker/dist/react-datepicker.css'
import 'assets/scss/components/_custom-datepicker.scss'

registerLocale('ru', ruLocale) // register it with the name you want

const getYear = (date) => date.getFullYear()
const getMonth = (date) => date.getMonth()

const DatepickerContainer = s(DatePicker)`
  .arrow {
    color: ${({ theme, $color }) => theme.colors[$color] || theme.colors.grey500};
    transition: color 0.3s ease-in-out;

    svg {
      fill: currentColor;
    }
  }

  & input {
    color: ${({ theme, $color }) => theme.colors[$color] || 'currentColor'};
  }
`

const renderCustomHeader = ({
  date,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const year = getYear(date)
  const month = months[getMonth(date)]

  const btnProps = {
    br: '8px',
    size: 'xs'
  }

  const iconProps = {
    width: 12,
    height: 12
  }

  return (
    <Box
      p="0"
      ai="center"
      jc="space-between"
    >
      <Box>
        <ButtonRound
          className="mr-2"
          onClick={decreaseYear}
          {...btnProps}
        >
          <ChevronLeftDouble {...iconProps} />
        </ButtonRound>
        <ButtonRound
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          {...btnProps}
        >
          <ChevronLeft {...iconProps} />
        </ButtonRound>
      </Box>

      <Box jc="center" ai="center">
        <Text
          size="normal"
          fw="b"
          color="black"
        >
          {month}
          {' '}
          {year}
        </Text>
      </Box>

      <Box>
        <ButtonRound
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          {...btnProps}
        >
          <ChevronRight {...iconProps} />
        </ButtonRound>
        <ButtonRound
          className="ml-2"
          onClick={increaseYear}
          {...btnProps}
        >
          <ChevronRightDouble {...iconProps} />
        </ButtonRound>
      </Box>
    </Box>
  )
}

const renderCustomHeaderMonth = ({
  date,
  decreaseYear,
  increaseYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const year = getYear(date)
  const month = months[getMonth(date)]

  const btnProps = {
    br: '8px',
    size: 'xs'
  }

  const iconProps = {
    width: 12,
    height: 12
  }

  return (
    <Box
      p="0"
      ai="center"
      jc="space-between"
    >
      <Box>
        <ButtonRound
          className="mr-2"
          onClick={decreaseYear}
          {...btnProps}
        >
          <ChevronLeftDouble {...iconProps} />
        </ButtonRound>
      </Box>

      <Box jc="center" ai="center">
        <Text
          size="normal"
          fw="b"
          color="black"
        >
          {year}
        </Text>
      </Box>

      <Box>
        <ButtonRound
          className="ml-2"
          onClick={increaseYear}
          {...btnProps}
        >
          <ChevronRightDouble {...iconProps} />
        </ButtonRound>
      </Box>
    </Box>
  )
}

const Datepicker = ({
  dateFormat = 'd MMMM, yyyy',
  timeIntervals = 15,
  timeFormat = 'HH:mm',
  showTimeSelect = false,
  customInput = null,
  inline = true,
  selected = '',
  color = '',
  showMonthYearPicker = false,
  selectsRange = false,
  ...props
}) => {
  const [ openDate, setOpenDate ] = useState('')
  useEffect(() => {
    setOpenDate(selected)
  }, [ selected ])

  return (
    <div className={`custom-datepicker ${selectsRange ? 'custom-datepicker-range' : ''}`}>
      <DatepickerContainer
        {...props}
        selectsRange={selectsRange}
        selected={openDate}
        openToDate={openDate}
        inline={inline}
        $color={color}
        customInput={customInput}
        showTimeSelect={showTimeSelect}
        timeFormat={timeFormat}
        timeIntervals={timeIntervals}
        timeCaption="Время"
        locale="ru"
        dateFormat={dateFormat}
        // disabledKeyboardNavigation
        renderCustomHeader={showMonthYearPicker ? renderCustomHeaderMonth : renderCustomHeader}
        // showMonthDropdown
        // showYearDropdown
        // adjustDateOnChange
        showMonthYearPicker={showMonthYearPicker}
      />
    </div>
  )
}

export default Datepicker
