import { AnalyticClinicTypes } from 'redux/dashboard/Analytic/AnalyticClinic/AnalyticClinicTypes'

const startMonth = new Date()
const endMonth = new Date()

endMonth.setDate(1)
startMonth.setDate(1)
startMonth.setMonth(startMonth.getMonth() - 11)

const INITIAL_STATE = {
  startDate: startMonth,
  endDate: endMonth,
  clinic: null,
  isFullScreen: false,
  clinicData: null,
}

const analyticClinicReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AnalyticClinicTypes.SET_MONTH_CLINIC_START:
    return {
      ...state,
      startDate: action.payload
    }
  case AnalyticClinicTypes.SET_MONTH_CLINIC_END:
    return {
      ...state,
      endDate: action.payload
    }
  case AnalyticClinicTypes.SET_FULL_SCREEN_CLINIC:
    return {
      ...state,
      isFullScreen: action.payload
    }
  case AnalyticClinicTypes.SET_ANALYTICS_DATA:
    return {
      ...state,
      clinicData: action.payload
    }
  default:
    return state
  }
}

export default analyticClinicReducer
