import { components } from 'react-select'

import { ReactComponent as IconChecked } from 'assets/icons/check-small.svg'
import Text from 'components/typo/Text'

const optionStyles = () => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0)',
  cursor: 'pointer',
})

const FormSelectOption = ({
  optionProps,
  renderOption,
  valueSize,
  labelName
}) => {
  const { data, isSelected } = optionProps

  return (
    <components.Option
      {...optionProps}
      getStyles={optionStyles}
    >
      {renderOption ? renderOption(data)
        : (
          <Text
            className="mr-2"
            color="current"
            noPointer
            nowrap
            size={valueSize}
          >
            {data[labelName]}
          </Text>
        )}
      {isSelected && (
        <IconChecked
          width="16px"
          height="16px"
          className="ml-a"
          stroke="black"
        />
      )}
    </components.Option>
  )
}

export default FormSelectOption
