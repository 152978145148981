import { CallStatusTypes } from 'constants/CallTypes'
import { normalizeFullTime } from 'helpers/Date'

export const getCallStatusTitle = (status, duration, fromMe) => {
  let title = ''

  if (status === CallStatusTypes.SUCCESS) {
    title = normalizeFullTime(duration)
  }
  else if (fromMe) {
    title = 'Отменен'
  }
  else {
    title = 'Пропущен'
  }

  return title
}
