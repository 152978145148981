/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
// @ts-ignore
import {
  log,
  logError,
  logHelp
} from 'utils/utils'
import * as VoxImplant from 'voximplant-websdk'
import store from 'redux/store/configureStore'
import {
  onConversationCreated, updateChatConversationItem, updateChatConversationLastRead, updateCurrentConversationLastEvent
} from 'redux/dashboard/Messanger/MesengerConversationsActions'
import {
  addMessagesToConversation, chatScrollToBottom, markedAsRead,
  onMessageDeleted,
  onMessageMarkAsRead,
  onMessageSent,
  onOnlineReceived, onUpdateChatConversationItem, onUpdateChatEditItem, updateMessagesEditInConversationUploaded,
} from 'redux/dashboard/Messanger/MessengerMessagesActions'
import { chatEventFilter, getUserUri } from 'redux/dashboard/Chat/ChatHelpers'
import {
  setChatHistory, setChatLastSeq, setChatTotalLastSeq
} from 'redux/dashboard/Chat/ChatActions'
import {
  MY_APP,
  URL_NEW_USERS
} from './messenger.config'
import { createSegments, groupArray } from '../../helpers/Array'

const TIME_NOTIFICATION = 5000

export default class MessengerService {
  constructor() {
    this.messenger = null
    this.inst = null
    this.setStatusTimer = null
    this.conversationEvents = {}
  }

  static get = () => {
    if (!MessengerService.inst) {
      MessengerService.inst = new MessengerService()
    }
    return MessengerService.inst
  }

  removeChat = () => Promise.all([
    this.sendStatusOfflineStatus(),
    this.removeMessengerEventListeners()
  ])

  /** *********************************************************************************************
   * INIT VOXIMPLANT.MESSENGER
   ********************************************************************************************* */

  init = async () => {
    // Get Voximplant Messenger instance
    try {
      MessengerService.messenger = VoxImplant.getMessenger()
      log('Messenger v2', MessengerService.messenger)
      log('VoxImplant.Messaging v2', VoxImplant.Messaging)
    }
    catch (e) {
      // Most common error 'Not authorised', so redirect to login
      logError(e)
      await store.dispatch('auth/relogin')
    }

    // Get the current user data
    const initialData = {
      currentUser: {},
      conversations: [],
      users: [],
    }

    await MessengerService.messenger.getUser(MessengerService.messenger.getMe())
      .then(async (evt) => {
        logHelp('Current user data received', evt)
        initialData.currentUser = evt.user
        initialData.unreaded = []
        const allConv = evt.user.conversationsList || []
        const allConvLength = allConv.length
        const splittedConv = []
        const cutLength = 30

        for (let idx = 0; idx < allConvLength; idx += cutLength) {
          const idxLast = idx + cutLength
          splittedConv.push(allConv.slice(idx, idxLast))

          if (allConvLength - idxLast < cutLength && allConvLength - idxLast > 1) {
            splittedConv.push(allConv.slice(idxLast))
            break
          }
        }

        let convArrs = []
        for (const convRowGroup of groupArray(splittedConv, 1)) {
          const convResults = await Promise.all(convRowGroup.map((convRow) => this.getCurrentConversations(convRow).catch((e) => console.log(e))))
          convArrs = [ ...convArrs, ...convResults ]
        }

        return convArrs
      })
      .then(async (convArrs) => {
        const evts = convArrs.flat()
        logHelp('Current user conversations received', evts)

        initialData.conversations = evts.length ? evts.map((e) => e.conversation) : []

        const directUsersId = initialData.conversations.filter((conversation) => conversation.direct).map((conversation) => {
          const directUser = conversation.participants.find((participant) => participant.userId !== initialData.currentUser.userId)
          return directUser.userId
        })

        // subscribe to get status events
        if (directUsersId.length) {
          const usersLength = directUsersId.length
          const splittedUsers = []

          for (let idx = 0; idx < usersLength; idx += 30) {
            const idxLast = idx + 30
            splittedUsers.push(directUsersId.slice(idx, idxLast))

            if (usersLength - idxLast < 30 && usersLength - idxLast > 1) {
              splittedUsers.push(directUsersId.slice(idxLast))
              break
            }
          }

          // В случае медленной загрузки, тоже сделать пакетную загрузку :)
          for (const usersRow of splittedUsers) {
            await MessengerService.messenger.subscribe(usersRow)
              .then((e) => {
                log('Subscribed to conversation participants', e)
              })
              .catch((e) => {
                const a = 1
                MessengerService.messenger.subscribe(usersRow)
                  .then((e) => {
                    log('Subscribed to conversation participants', e)
                  })
                  .catch((e) => {
                    console.log('Не подписался на эти покеты')
                    console.log(usersRow)
                  })
              })
          }

          let usersByIdPromises = []
          for (const convRowGroup of groupArray(splittedUsers, 1)) {
            const usersByIdPromise = await Promise.all(convRowGroup.map((usersRow) => MessengerService.messenger.getUsersById(usersRow).catch((e) => console.log(e))))
            usersByIdPromises = [ ...usersByIdPromises, ...usersByIdPromise ]
          }

          return usersByIdPromises
        }

        return Promise.reject(new Error('No users'))
      })
      .then((usersEvts) => {
        const evts = usersEvts.flat()
        logHelp('Conversation participants user info received', evts)
        initialData.users = evts.map((e) => e.user)
      })
      .then(async () => {
        const unreadConversation = initialData.conversations.filter(
          (conv) => conv.participants.find(
            (p) => p.userId === initialData.currentUser.userId
              && Number(p.lastRead) < Number(conv?.customData?.lastMessage?.seq)
          )
        )
        for (const conversation of unreadConversation) {
          let conversationEvents = []
          const { lastRead } = conversation.participants.find((p) => p.userId === initialData.currentUser.userId)
          const { lastSeq } = conversation
          const looseEvents = lastSeq - lastRead
          const length = 99
          const currentLastSeqLoaded = lastSeq
          const segments = createSegments(Number(lastSeq), Number(lastRead), length)

          for (const segment of segments) {
            const e = await this.retransmitMessageEvents(conversation, segment.end, segment.start)
            conversationEvents = [ ...e, ...conversationEvents ]
          }

          initialData.unreaded.push({
            uuid: conversation.uuid,
            count: chatEventFilter(conversationEvents).length,
            list: chatEventFilter(conversationEvents),
          })
        }
        console.log('Новая супер машина БРБРБРБР!!!!')
      })
      .catch((e) => {
        console.log(e)
        logError(e)
      })

    this.addMessengerEventListeners()

    /**
     * You have to send user presence status periodically to notify the new coming users if you are online
     * TODO You can implement invisible mode by sending setStatus(false)
     */
    const sendStatus = () => setTimeout(() => {
      MessengerService.messenger.setStatus(true)
      this.setStatusTimer = sendStatus()
    }, TIME_NOTIFICATION)

    this.setStatusTimer = sendStatus()
    console.log(initialData)
    return initialData
  }

  getMe = () => VoxImplant.getMessenger().getMe()

  sendStatusOfflineStatus = () => {
    if (MessengerService.messenger) {
      clearTimeout(this.setStatusTimer)
      return MessengerService.messenger.setStatus(false)
    }
  }

  listenerStatus = (e) => store.dispatch(onOnlineReceived(e))

  listenerCreateConversation = (e) => store.dispatch(onConversationCreated(e))

  listenerEditConversation = (e) => {
    console.log(e)
    store.dispatch(updateChatConversationItem(e.conversation))
  }

  listenerSendMessage = (e) => {
    console.log(e)
    store.dispatch(onUpdateChatConversationItem(e))
  }

  listenerEditMessage = (e) => {
    console.log(e)
    store.dispatch(onUpdateChatEditItem(e))
  }
  /* store.dispatch({
    type: 'conversations/onMessageEdited',
    payload: e
  }) */

  listenerRemoveMessage = (e) => store.dispatch(onMessageDeleted(e))

  // Эта строка используется для того, чтобы пометить галочкой прочитанное сейчас сообщение
  listenerMarkAsRead = (e) => store.dispatch(onMessageMarkAsRead(e))

  listenerTyping = (e) => store.dispatch({
    type: 'conversations/onNotifyTyping',
    payload: e
  })

  /**
   * The maximum number of conversations that SDK enables to get at once is 30
   * This method resolves to an array of VoxImplant.Messaging.MessengerEvents.GetConversation events
   */
  getCurrentConversations = (conversationsList = []) => MessengerService.messenger
    .getConversations(conversationsList)
    .catch((e) => {
      logError('MessengerService.getCurrentConversations', e)
      return []
    })

  getConversation = (conversationId) => MessengerService.messenger
    .getConversation(conversationId)
    .catch((e) => {
      logError('MessengerService.getCurrentConversation', e)
    })

  getUserByName = (username) => {
    const userUri = getUserUri(username)
    return MessengerService.messenger.getUser(userUri)
  }

  getUserById = (directUserId) => MessengerService.messenger.getUserById(directUserId)

  getAllUsers = async () => {
    const getAllUsers = await fetch(URL_NEW_USERS)
    let jsonAllUsers

    if (getAllUsers.ok) {
      jsonAllUsers = await getAllUsers.json()
    }
    else {
      logError(`Error HTTP: ${getAllUsers.status}`)
    }
    const usersNames = jsonAllUsers.result.map((u) => `${u.user_name}@${MY_APP}`)
    return this.getUserIds(usersNames)
  }

  getUserIds = (filteredUserNames = []) => MessengerService.messenger.getUsers(filteredUserNames)

  giveIsOwner = (conversation) => {
    const participant = conversation._participants.find((i) => !i.isOwner)
    if (participant) {
      conversation.editParticipants([{
        ...participant,
        isOwner: true
      }])
    }
  }

  createDirect = async (userId, isDoctorToPatient = false) => {
    this.getUserById(userId)

    const response = await this.createNewConversation(
      {
        participants: [{ userId }],
        title: '',
        direct: true,
        publicJoin: false,
        uber: false,
        customData: { type: 'direct' }
      }
    )

    if (isDoctorToPatient) this.giveIsOwner(response)
    return response
  }

  createNewConversation = (
    {
      participants = [],
      title = '',
      direct = false,
      publicJoin = false,
      uber = false,
      customData = {}
    }
  ) => MessengerService.messenger.createConversation(
    participants,
    title,
    direct,
    publicJoin,
    uber,
    customData
  )

  /**
   * Notify other that current user typing in conversation. This method trigger VoxImplant.Messaging.MessengerEvents.Typing event
   * Subscribe it to resolve events, min time between notifications 10sec
   * @param currentConversation
   */
  notifyTyping = (currentConversation) => currentConversation.typing()

  /**
   * Some VoxImplant.Messaging.MessengerEvents are better to use by passing a callback to the event listener function.
   * This way you're able update the current user's store and interface if the event is triggered by another user or by the current one on a different device.
   * The other VoxImplant.Messaging.MessengerEvents are more handy to deal with in a .then() function as they all return a Promise.
   * These are methods that affect only this user and this application instance, like subscribing to or unsubscribing from other users, retransmitting events or getting other data.
   */

  addMessengerEventListeners = () => {
    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.SetStatus, this.listenerStatus)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.CreateConversation, this.listenerCreateConversation)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.EditConversation, this.listenerEditConversation)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.SendMessage, this.listenerSendMessage)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.EditMessage, this.listenerEditMessage)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.RemoveMessage, this.listenerRemoveMessage)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.Read, this.listenerMarkAsRead)

    MessengerService.messenger.on(VoxImplant.Messaging.MessengerEvents.Typing, this.listenerTyping)
  }

  removeMessengerEventListeners = () => {
    if (MessengerService.messenger && MessengerService.messenger.off) {
      return Promise.all(
        [
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.SetStatus, this.listenerStatus),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.CreateConversation, this.listenerCreateConversation),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.EditConversation, this.listenerEditConversation),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.SendMessage, this.listenerSendMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.EditMessage, this.listenerEditMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.RemoveMessage, this.listenerRemoveMessage),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.Read, this.listenerMarkAsRead),
          MessengerService.messenger.off(VoxImplant.Messaging.MessengerEvents.Typing, this.listenerTyping),
          MessengerService.messenger.unsubscribe([], true)
        ]
      )
    }
  }

  /** *********************************************************************************************
   * MESSAGES
   ********************************************************************************************* */

  /**
   * The maximum number of events you can retransmit at once is 100.
   * retransmitEvents method resolves to the event containing an array of VoxImplant.Messaging.MessengerEvents
   * params => eventsFrom: number, eventsTo: number, count?: number
   */

  retransmitMessageEvents = async (currentConversation, eventFrom, eventTo) => {
    const data = await currentConversation.retransmitEvents(Number(eventFrom), Number(eventTo)).catch((error) => logError(error))
    const { events } = data
    return events
    /* const sendAction = VoxImplant.Messaging.MessengerAction.sendMessage
    const editAction = VoxImplant.Messaging.MessengerAction.editMessage
    const deleteAction = VoxImplant.Messaging.MessengerAction.removeMessage
    const messageEvents = []
    console.log(data)
    console.log(events)
    const filteredEvents = events
      .filter((e) => (
        e.messengerAction === sendAction
            || e.messengerAction === editAction
            || e.messengerAction === deleteAction
      ))

    if (!filteredEvents.length) return []

    // Group by message.uuid
    const groupByUuidEvents = filteredEvents.reduce((res, evt) => {
      res[evt.message.uuid] = res[evt.message.uuid] || []
      res[evt.message.uuid].push(evt)
      return res
    }, Object.create(null))

    // Get only relevant events for message.uuid
    for (const messageUuid in groupByUuidEvents) {
      const arrEvtsMessage = groupByUuidEvents[messageUuid]
      const isDeleted = arrEvtsMessage.find((m) => m.messengerAction === deleteAction)
      const isEdited = arrEvtsMessage.find((m) => m.messengerAction === editAction)
      if (isDeleted) {
        continue
      }
      else if (isEdited) {
        if (!arrEvtsMessage.find((m) => m.messengerAction === sendAction)) continue

        const sorted = arrEvtsMessage.sort((m) => m.timestamp)
        const initialMessage = sorted[0]
        const lastUpdated = sorted[sorted.length - 1]
        lastUpdated.message.editedAt = lastUpdated.timestamp
        lastUpdated.timestamp = initialMessage.timestamp
        lastUpdated.message.editedBy = lastUpdated.initiator
        lastUpdated.initiator = initialMessage.initiator
        logHelp('retransmit edited', initialMessage, lastUpdated)
        messageEvents.push(lastUpdated)
      }
      else {
        messageEvents.push(...arrEvtsMessage)
      }
    }

    logHelp(`All events in conversation ${currentConversation.title}`, this.conversationEvents[currentConversation.uuid])

    return messageEvents */
  }

  sendMessage = async (currentConversation, text = '', payload = [{}]) => {
    const event = await currentConversation.sendMessage(text, payload)
    console.log({
      ...currentConversation.customData,
      lastMessage: event.message,
    })
    const { message } = event
    const lastMessage = {
      seq: message.seq,
      timestamp: message.timestamp,
      text: message._text,
      conversation: message.conversation,
      uuid: message._uuid,
      payload: [{
        attach: [ ...message.payload[0].attach ],
        uploadId: message.payload[0].uploadId
      }],
      sender: message._sender,
    }

    if (message.payload[0].call) {
      lastMessage.payload[0].call = message.payload[0].call
    }

    console.log(lastMessage)

    await currentConversation.setCustomData({
      ...currentConversation.customData,
      lastMessage
    }).catch(logError)

    return event
  }

  removeMessage = (message) => message
    .remove()
    .catch(logError)

  updateMessage = (message) => message
    .update()
    .catch(logError)

  markAsRead = async (conversation, lastSeq) => {
    const event = await conversation.markAsRead(lastSeq)
    return event
  }

  /** *********************************************************************************************
   * EDIT USERS INFO
   ********************************************************************************************* */

  editUserCustomData = (customData = {}) => MessengerService.messenger.editUser(customData)
}
