import CardBlockWithIcon from 'components/card/CardBlockWithIcon'
import Text from 'components/typo/Text'
import { forwardRef } from 'react'
import FormikField from './FormikField'

const FormBlockField = forwardRef(({
  placeholder = 'Выберите...',
  onChange = () => {},
  onBlur = null,
  name = 'date',
  title = '',
  Icon = null,
  numbers = false,
  noMessage = true,
  isEdit = true,
  value = '',
  addString = null,
  minNumber = null,
  maxNumber = null,
}, ref) => (
  <CardBlockWithIcon title={title} Icon={Icon}>
    {isEdit ? (
      <FormikField
        placeholder={placeholder}
        defaultStyle="transparent"
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        numbers={numbers}
        noMessage={noMessage}
        size="small"
        addString={addString}
        minNumber={minNumber}
        maxNumber={maxNumber}
        ref={ref}
      />
    ) : <Text color="grey500">{value}</Text>}
  </CardBlockWithIcon>
))

export default FormBlockField
