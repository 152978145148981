import { AuthActionTypes } from 'redux/Auth/AuthTypes'

const INITIAL_STATE = {
  auth: false,
  isFetchingMe: false,
  isFetchingCode: false,
  isFetchingAuth: false,
  isNewUser: false,
  is401: false,
}

const authReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGIN_SUCCESS:
    return {
      ...state,
      auth: true,
    }

  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return {
      ...state,
      auth: false,
    }

  case AuthActionTypes.SET_FETCHING_GET_USER:
    return {
      ...state,
      isFetchingMe: action.payload,
    }

  case AuthActionTypes.SET_FETCHING_CODE_VERIFICATION:
    return {
      ...state,
      isFetchingCode: action.payload,
    }

  case AuthActionTypes.SET_IS_NEW_USER:
    return {
      ...state,
      isNewUser: action.payload,
    }

  case AuthActionTypes.SET_IS_404:
    return {
      ...state,
      is401: action.payload,
    }

  case AuthActionTypes.SET_FETCHING_REGISTRATION:
  case AuthActionTypes.SET_FETCHING_GET_LOGIN_CODE:
    return {
      ...state,
      isFetchingAuth: action.payload,
    }

  default:
    return state
  }
}

export default authReducer
