/* eslint-disable no-promise-executor-return */
import axios from 'axios'

import { MediaActionTypes } from 'redux/Media/MediaTypes'
import { getFileType } from 'redux/Media/MediaHelpers'

import ApiService from 'services/ApiService'

export const setMediaUploadProgress = (progressId, progress) => ({
  type: MediaActionTypes.SET_MEDIA_UPLOAD_PROGRESS,
  payload: {
    progressId,
    progress
  },
})

export const setMediaLoadingStart = () => ({ type: MediaActionTypes.SET_MEDIA_LOADING_START })

export const setMediaLoadingSuccess = () => ({ type: MediaActionTypes.SET_MEDIA_LOADING_SUCCESS })

export const setMediaLoadingError = (error) => ({
  type: MediaActionTypes.SET_MEDIA_LOADING_ERROR,
  payload: error,
})

export const setMediaLoaded = () => ({ type: MediaActionTypes.SET_MEDIA_LOADED })

export const setFetchingMediaStart = () => ({ type: MediaActionTypes.SET_FETCHING_MEDIA_START })

export const fetchMediaError = () => ({ type: MediaActionTypes.FETCH_MEDIA_ERROR })

export const fetchMediaSuccess = (media) => ({
  type: MediaActionTypes.FETCH_MEDIA_SUCCESS,
  payload: media,
})

export const fetchMedia = (query = '') => (dispatch) => {
  dispatch(setFetchingMediaStart())

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.media.ROOT}${query}`,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchMediaSuccess(response))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchMediaError(false))
      return rej(error)
    }))
}

export const setFetchingDeleteMediaStart = () => ({ type: MediaActionTypes.SET_FETCHING_DELETE_MEDIA_START })

export const fetchDeleteMediaError = () => ({ type: MediaActionTypes.FETCH_DELETE_MEDIA_ERROR })

export const fetchDeleteMediaSuccess = (media) => ({
  type: MediaActionTypes.FETCH_DELETE_MEDIA_SUCCESS,
  payload: media,
})

export const fetchDeleteMedia = (id) => (dispatch) => {
  dispatch(setFetchingDeleteMediaStart())

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'DELETE',
    url: `${ApiService.paths.media.ROOT}${id}/`,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchDeleteMediaSuccess(id))
      return res(response)
    })
    .catch(({ data }) => {
      dispatch(fetchDeleteMediaError(data))
      return rej(data)
    }))
}

export const setFetchingUploadMediaStart = () => ({ type: MediaActionTypes.SET_FETCHING_UPLOAD_MEDIA_START })

export const fetchUploadMediaError = () => ({ type: MediaActionTypes.FETCH_UPLOAD_MEDIA_ERROR })

export const fetchUploadMediaSuccess = (media) => ({
  type: MediaActionTypes.FETCH_UPLOAD_MEDIA_SUCCESS,
  payload: media,
})

export const fetchUploadMedia = (data) => (dispatch) => {
  dispatch(setFetchingUploadMediaStart())

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data,
    url: ApiService.paths.media.ROOT,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchUploadMediaSuccess(response))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchUploadMediaError(error.data))
      return rej(error.data)
    }))
}

export const setFetchingUpdateMediaStart = () => ({ type: MediaActionTypes.SET_FETCHING_UPLOAD_MEDIA_START })

export const fetchUpdateMediaError = () => ({ type: MediaActionTypes.FETCH_UPLOAD_MEDIA_ERROR })

export const fetchUpdateMediaSuccess = (media) => ({
  type: MediaActionTypes.FETCH_UPLOAD_MEDIA_SUCCESS,
  payload: media,
})

export const fetchUpdateMedia = (id, data) => (dispatch) => {
  dispatch(setFetchingUpdateMediaStart())

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    data,
    url: `${ApiService.paths.media.ROOT}${id}/`,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchUpdateMediaSuccess(response))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchUpdateMediaError(error.data))
      return rej(error.data)
    }))
}

export const setFetchingS3CreatePresignUrl = () => ({ type: MediaActionTypes.SET_FETCHING_S3_CREATE_PRESIGN_URL })

export const fetchS3CreatePresignUrlError = () => ({ type: MediaActionTypes.FETCH_S3_CREATE_PRESIGN_URL_ERROR })

export const fetchS3CreatePresignUrlSuccess = (media) => ({
  type: MediaActionTypes.FETCH_S3_CREATE_PRESIGN_URL_SUCCESS,
  payload: media,
})

export const fetchS3CreatePresignUrl = ({
  filename,
  type,
}) => (dispatch) => {
  dispatch(setFetchingS3CreatePresignUrl(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: `${ApiService.paths.media.S3_PRESIGN_URL}`,
    isToken: true,
    data: {
      filename,
      file_type: type,
    }
  })
    .then((response) => {
      dispatch(fetchS3CreatePresignUrlSuccess(response))
      dispatch(setFetchingS3CreatePresignUrl(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchS3CreatePresignUrlError(error.data))
      dispatch(setFetchingS3CreatePresignUrl(false))
      return rej(error.data)
    }))
}

export const setFetchingUploadImageS3 = (value) => ({
  type: MediaActionTypes.SET_FETCHING_UPLOAD_MEDIA_S3,
  payload: value
})

export const handleUploadProgress = (progressId, dispatch) => (progressId
  ? (progressEvent) => {
    if (progressId && progressEvent.lengthComputable) {
      dispatch(setMediaUploadProgress(progressId, (progressEvent.loaded / progressEvent.total * 100).toFixed(0)))
    }
  }
  : null
)

export const fetchUploadImageS3Success = () => ({ type: MediaActionTypes.FETCH_UPLOAD_MEDIA_S3_SUCCESS })

export const fetchUploadImageS3Error = () => ({ type: MediaActionTypes.FETCH_UPLOAD_MEDIA_S3_ERROR })

export const fetchUploadFileS3 = (url, formData, progressId) => (dispatch) => {
  dispatch(setFetchingUploadImageS3(true))

  return new Promise((res, rej) => axios({
    method: 'POST',
    url,
    data: formData,
    headers: { 'Content-Type': 'text/plain' },
    onUploadProgress: handleUploadProgress(progressId, dispatch)
  })
    .then((response) => {
      dispatch(fetchUploadImageS3Success(response))
      dispatch(setFetchingUploadImageS3(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchUploadImageS3Error(error))
      dispatch(setFetchingUploadImageS3(false))
      return rej(error.data)
    }))
}

export const addFileCancelUploading = (cancelTokenSource, uploadId) => ({
  type: MediaActionTypes.ADD_FILE_CANCEL_UPLOADING,
  payload: {
    cancelTokenSource,
    uploadId
  },
})

// TODO REMOVE CONSTANT FILE TYPE
export const uploadS3File = (file = {}, additionProps = {}, progressId = null, title = null) => (dispatch) => {
  const fileType = getFileType(file.type)

  return dispatch(fetchS3CreatePresignUrl({
    filename: title || file.name,
    type: fileType,
    ...additionProps
  }))
    .then((presignedPostUrl) => {
      const formData = new FormData()
      Object.entries(presignedPostUrl.source_fields).forEach(([ k, v ]) => {
        formData.append(k, v)
      })
      formData.append('file', file)

      const cancelTokenSource = axios.CancelToken.source()
      dispatch(addFileCancelUploading(cancelTokenSource, file.id || progressId))

      return dispatch(fetchUploadFileS3(presignedPostUrl.source_url, formData, file.id || progressId))
        .then((() => dispatch(fetchUpdateMedia(presignedPostUrl.id, { uploaded: true }))
          .then((updateFilesRes) => ({
            fileUploadId: file.id || updateFilesRes.id,
            file: updateFilesRes,
          }))))
    })
}

export const uploadS3Files = (files = [], additionProps = {}) => (dispatch) => Promise.all(
  [ ...files.map((file) => dispatch(uploadS3File(file, additionProps, file.id))) ]
)

export const uploadFile = ({
  file = {},
  // additionProps = {},
  // progressId = null,
  title = null
}) => (dispatch) => {
  const fileType = getFileType(file.type)

  const formData = new FormData()

  formData.append('file', file)
  formData.append('file_type', fileType)
  formData.append('filename', title || file.name)
  formData.append('uploaded', true)

  return dispatch(fetchUploadMedia(formData))
}
