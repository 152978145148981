import { MediaFileTypes } from 'constants/MediaTypes'

export const getMediaExt = (fileName) => {
  if (!fileName) return fileName
  return fileName.substr(fileName.lastIndexOf('.') + 1)
}
export const getMediaName = (fileName) => {
  if (!fileName) return fileName
  const lastIdx = fileName.lastIndexOf('.')
  return fileName.substr(0, lastIdx >= 0 ? lastIdx : fileName.length)
}

export const getFileType = (fileType) => {
  let type = MediaFileTypes.FILE

  if (fileType) {
    if (fileType.indexOf(MediaFileTypes.IMAGE) === 0) {
      type = MediaFileTypes.IMAGE
    }
    else if (fileType.indexOf(MediaFileTypes.VIDEO) === 0) {
      type = MediaFileTypes.VIDEO
    }
    else if (fileType.indexOf(MediaFileTypes.AUDIO) === 0) {
      type = MediaFileTypes.AUDIO
    }
  }

  return type
}
