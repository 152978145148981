import Text from 'components/typo/Text'
import cn from 'classnames'

const FormTitle = ({
  className = '',
  mb = '2',
  title = '',
  isSmall = false
}) => (
  <Text
    className={cn('form-title', { [`mb-${mb}`]: mb }, className)}
    fw="m"
    size={isSmall ? 'mediumSm' : 'normalBig'}
  >
    {title}
  </Text>
)

export default FormTitle
