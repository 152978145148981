import { useEffect, useState } from 'react'

import Video from 'components/base/Video'
// import cn from 'classnames'

const VideoLazy = ({
  className = '',
  poster = '',
  src = '',
  width = '',
  height = '',
  controls = true,
  isActive = true,
  ...otherProps
}) => {
  const [ videoSrc, setVideoSrc ] = useState(null)
  const [ videoRef, setVideoRef ] = useState(null)

  useEffect(() => {
    let observer
    let didCancel = false

    if (videoRef && videoSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel
                && (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                if (src && isActive) {
                  setVideoSrc(src)
                }

                if (isActive) {
                  observer.unobserve(videoRef)
                }
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '90%',
          }
        )
        observer.observe(videoRef)
      }
      else {
        // Old browsers fallback
        setVideoSrc(src)
      }
    }
    return () => {
      didCancel = true
      if (observer && observer.unobserve) {
        observer.unobserve(videoRef)
      }
    }
  }, [ src, videoSrc, videoRef, isActive ])

  return (
    <div
      style={{
        width,
        height
      }}
      ref={setVideoRef}
    >
      <Video
        className={className}
        poster={poster}
        src={videoSrc}
        width={width}
        height={height}
        controls={controls}
        {...otherProps}
      />
    </div>
  )
}

export default VideoLazy
