/* eslint-disable default-param-last */
import { RatesActionTypes } from 'redux/Rates/RatesTypes'

const INITIAL_STATE = {
  list: null,
  isFetching: false,
  error: null,
}

const ratesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case RatesActionTypes.SET_FETCHING_RATES:
    return {
      ...state,
      isFetching: action.payload,
    }

  case RatesActionTypes.FETCH_RATES_SUCCESS:
    return {
      ...state,
      list: action.payload,
    }

  case RatesActionTypes.FETCH_RATES_ERROR:
    return {
      ...state,
      error: action.payload
    }

  default:
    return state
  }
}

export default ratesReducer
