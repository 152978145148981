export const PatientActionTypes = {
  SET_PATIENT: 'SET_PATIENT',
  FETCH_PATIENT_ERROR: 'FETCH_PATIENT_ERROR',
  FETCH_PATIENT_SUCCESS: 'FETCH_PATIENT_SUCCESS',
  SET_FETCHING_PATIENT: 'SET_FETCHING_PATIENT',
  CLEAR_PATIENT_STATE: 'CLEAR_PATIENT_STATE',
  FETCH_SPECIALIZATIONS_CLINIC: 'FETCH_SPECIALIZATIONS_CLINIC',

  SET_FETCHING_PATIENT_DIARY: 'SET_FETCHING_PATIENT_DIARY',
  FETCH_PATIENT_DIARY_SUCCESS: 'FETCH_PATIENT_DIARY_SUCCESS',
  FETCH_PATIENT_DIARY_ERROR: 'FETCH_PATIENT_DIARY_ERROR',

  SET_FETCHING_PATIENT_TASKS: 'SET_FETCHING_PATIENT_TASKS',
  FETCH_PATIENT_TASKS_SUCCESS: 'FETCH_PATIENT_TASKS_SUCCESS',
  FETCH_PATIENT_TASKS_ERROR: 'FETCH_PATIENT_TASKS_ERROR',

  SET_FETCHING_PATIENT_COURSES_TASKS: 'SET_FETCHING_PATIENT_COURSES_TASKS',
  FETCH_PATIENT_COURSES_TASKS_SUCCESS: 'FETCH_PATIENT_COURSES_TASKS_SUCCESS',
  FETCH_PATIENT_COURSES_TASKS_ERROR: 'FETCH_PATIENT_COURSES_TASKS_ERROR',

  SET_FETCHING_GET_PATIENT_DIARY_RECORDS_STAT: 'SET_FETCHING_GET_PATIENT_DIARY_RECORDS_STAT',
  FETCH_GET_PATIENT_DIARY_RECORDS_STAT_SUCCESS: 'FETCH_GET_PATIENT_DIARY_RECORDS_STAT_SUCCESS',
  FETCH_GET_PATIENT_DIARY_RECORDS_STAT_ERROR: 'FETCH_GET_PATIENT_DIARY_RECORDS_STAT_ERROR',

  SET_FETCHING_PATIENT_CONSULTATIONS: 'SET_FETCHING_PATIENT_CONSULTATIONS',
  FETCH_PATIENT_CONSULTATIONS_SUCCESS: 'FETCH_PATIENT_CONSULTATIONS_SUCCESS',
  FETCH_PATIENT_CONSULTATIONS_ERROR: 'FETCH_PATIENT_CONSULTATIONS_ERROR',

  SET_FETCHING_PATIENT_INSURANCES: 'SET_FETCHING_PATIENT_INSURANCES',
  FETCH_PATIENT_INSURANCES_SUCCESS: 'FETCH_PATIENT_INSURANCES_SUCCESS',
  FETCH_PATIENT_INSURANCES_ERROR: 'FETCH_PATIENT_INSURANCES_ERROR',

  ADD_PATIENT_TASK: 'ADD_PATIENT_TASK',
  ADD_PATIENT_TASKS: 'ADD_PATIENT_TASKS',
  UPDATE_PATIENT_TASK: 'UPDATE_PATIENT_TASK',
  DELETE_PATIENT_TASK: 'DELETE_PATIENT_TASK',

  SET_PATIENT_TASK_COMMENTS: 'SET_PATIENT_TASK_COMMENTS',
  ADD_PATIENT_TASK_COMMENT: 'ADD_PATIENT_TASK_COMMENT',
  DELETE_PATIENT_TASK_COMMENT: 'DELETE_PATIENT_TASK_COMMENT',
  UPDATE_PATIENT_TASK_COMMENT: 'UPDATE_PATIENT_TASK_COMMENT',

  SET_FETCHING_PATIENT_EXPIRED_TASKS_COUNT: 'SET_FETCHING_PATIENT_EXPIRED_TASKS_COUNT',
  FETCH_PATIENT_EXPIRED_TASKS_COUNT_SUCCESS: 'FETCH_PATIENT_EXPIRED_TASKS_COUNT_SUCCESS',
  FETCH_PATIENT_EXPIRED_TASKS_COUNT_ERROR: 'FETCH_PATIENT_EXPIRED_TASKS_COUNT_ERROR',
}

export const PatientStructureActionTypes = {
  SET_FETCHING_PATIENT_STRUCTURE_BY_TYPE: 'SET_FETCHING_PATIENT_STRUCTURE_BY_TYPE',
  FETCH_PATIENT_STRUCTURE_BY_TYPE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_BY_TYPE_SUCCESS',
  FETCH_PATIENT_STRUCTURE_BY_TYPE_ERROR: 'FETCH_PATIENT_STRUCTURE_BY_TYPE_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_MOVE: 'SET_FETCHING_PATIENT_STRUCTURE_MOVE',
  FETCH_PATIENT_STRUCTURE_MOVE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_MOVE_SUCCESS',
  FETCH_PATIENT_STRUCTURE_MOVE_ERROR: 'FETCH_PATIENT_STRUCTURE_MOVE_ERROR',
  SET_PATIENT_STRUCTURE_MOVE_OPEN_FOLDER_SUCCESS: 'SET_PATIENT_STRUCTURE_MOVE_OPEN_FOLDER_SUCCESS',
  CLEAR_PATIENT_STRUCTURE_MOVE: 'CLEAR_PATIENT_STRUCTURE_MOVE',

  // SET_FETCHING_PATIENT_STRUCTURE_UPDATE_FILE: 'SET_FETCHING_PATIENT_STRUCTURE_UPDATE_FILE',
  // FETCH_PATIENT_STRUCTURE_UPDATE_FILE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_UPDATE_FILE_SUCCESS',
  // FETCH_PATIENT_STRUCTURE_UPDATE_FILE_ERROR: 'FETCH_PATIENT_STRUCTURE_UPDATE_FILE_ERROR',
  PATIENT_STRUCTURE_UPDATE_FILE: 'PATIENT_STRUCTURE_UPDATE_FILE',

  SET_FETCHING_PATIENT_STRUCTURE_CREATE_FILE: 'SET_FETCHING_PATIENT_STRUCTURE_CREATE_FILE',
  FETCH_PATIENT_STRUCTURE_CREATE_FILE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_CREATE_FILE_SUCCESS',
  FETCH_PATIENT_STRUCTURE_CREATE_FILE_ERROR: 'FETCH_PATIENT_STRUCTURE_CREATE_FILE_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_DELETE_FILE: 'SET_FETCHING_PATIENT_STRUCTURE_DELETE_FILE',
  FETCH_PATIENT_STRUCTURE_DELETE_FILE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_DELETE_FILE_SUCCESS',
  FETCH_PATIENT_STRUCTURE_DELETE_FILE_ERROR: 'FETCH_PATIENT_STRUCTURE_DELETE_FILE_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_MOVE_FILE: 'SET_FETCHING_PATIENT_STRUCTURE_MOVE_FILE',
  FETCH_PATIENT_STRUCTURE_MOVE_FILE_SUCCESS: 'FETCH_PATIENT_STRUCTURE_MOVE_FILE_SUCCESS',
  FETCH_PATIENT_STRUCTURE_MOVE_FILE_ERROR: 'FETCH_PATIENT_STRUCTURE_MOVE_FILE_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_UPDATE_FOLDER: 'SET_FETCHING_PATIENT_STRUCTURE_UPDATE_FOLDER',
  FETCH_PATIENT_STRUCTURE_UPDATE_FOLDER_SUCCESS: 'FETCH_PATIENT_STRUCTURE_UPDATE_FOLDER_SUCCESS',
  FETCH_PATIENT_STRUCTURE_UPDATE_FOLDER_ERROR: 'FETCH_PATIENT_STRUCTURE_UPDATE_FOLDER_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_CREATE_FOLDER: 'SET_FETCHING_PATIENT_STRUCTURE_CREATE_FOLDER',
  FETCH_PATIENT_STRUCTURE_CREATE_FOLDER_SUCCESS: 'FETCH_PATIENT_STRUCTURE_CREATE_FOLDER_SUCCESS',
  FETCH_PATIENT_STRUCTURE_CREATE_FOLDER_ERROR: 'FETCH_PATIENT_STRUCTURE_CREATE_FOLDER_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_DELETE_FOLDER: 'SET_FETCHING_PATIENT_STRUCTURE_DELETE_FOLDER',
  FETCH_PATIENT_STRUCTURE_DELETE_FOLDER_SUCCESS: 'FETCH_PATIENT_STRUCTURE_DELETE_FOLDER_SUCCESS',
  FETCH_PATIENT_STRUCTURE_DELETE_FOLDER_ERROR: 'FETCH_PATIENT_STRUCTURE_DELETE_FOLDER_ERROR',

  SET_FETCHING_PATIENT_STRUCTURE_MOVE_FOLDER: 'SET_FETCHING_PATIENT_STRUCTURE_MOVE_FOLDER',
  FETCH_PATIENT_STRUCTURE_MOVE_FOLDER_SUCCESS: 'FETCH_PATIENT_STRUCTURE_MOVE_FOLDER_SUCCESS',
  FETCH_PATIENT_STRUCTURE_MOVE_FOLDER_ERROR: 'FETCH_PATIENT_STRUCTURE_MOVE_FOLDER_ERROR',
}

export const PatientBindsActionTypes = {
  SET_FETCHING_PATIENT_BINDS: 'SET_FETCHING_PATIENT_BINDS',
  FETCH_PATIENT_BINDS_SUCCESS: 'FETCH_PATIENT_BINDS_SUCCESS',
  FETCH_PATIENT_BINDS_ERROR: 'FETCH_PATIENT_BINDS_ERROR',

  SET_FETCHING_PATIENT_BIND: 'SET_FETCHING_PATIENT_BIND',
  FETCH_PATIENT_BIND_SUCCESS: 'FETCH_PATIENT_BIND_SUCCESS',
  FETCH_PATIENT_BIND_ERROR: 'FETCH_PATIENT_BIND_ERROR',

  SET_FETCHING_PATIENT_BIND_DELETE: 'SET_FETCHING_PATIENT_BIND_DELETE',
  FETCH_PATIENT_BIND_DELETE_SUCCESS: 'FETCH_PATIENT_BIND_DELETE_SUCCESS',
  FETCH_PATIENT_BIND_DELETE_ERROR: 'FETCH_PATIENT_BIND_DELETE_ERROR',

  SET_FETCHING_PATIENT_BIND_ACCEPT: 'SET_FETCHING_PATIENT_BIND_ACCEPT',
  FETCH_PATIENT_BIND_ACCEPT_SUCCESS: 'FETCH_PATIENT_BIND_ACCEPT_SUCCESS',
  FETCH_PATIENT_BIND_ACCEPT_ERROR: 'FETCH_PATIENT_BIND_ACCEPT_ERROR',

  SET_FETCHING_PATIENT_BIND_REJECT: 'SET_FETCHING_PATIENT_BIND_REJECT',
  FETCH_PATIENT_BIND_REJECT_SUCCESS: 'FETCH_PATIENT_BIND_REJECT_SUCCESS',
  FETCH_PATIENT_BIND_REJECT_ERROR: 'FETCH_PATIENT_BIND_REJECT_ERROR',

  SET_FETCHING_PATIENT_BIND_CREATE: 'SET_FETCHING_PATIENT_BIND_CREATE',
  FETCH_PATIENT_BIND_CREATE_SUCCESS: 'FETCH_PATIENT_BIND_CREATE_SUCCESS',
  FETCH_PATIENT_BIND_CREATE_ERROR: 'FETCH_PATIENT_BIND_CREATE_ERROR',

}
