import { MyCoursesActionTypes } from 'redux/dashboard/MyCourses/MyCoursesTypes'

const INITIAL_STATE = { course_template: null }

const MyCoursesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case MyCoursesActionTypes.SET_COURSE_TEMPLATE:
    return {
      ...state,
      course_template: action.payload,
    }

  default:
    return state
  }
}

export default MyCoursesReducer
