import { useMemo } from 'react'

import Box from 'layout/Box'
import Button from 'components/base/Button'
import TagIcon from 'components/base/TagIcon'
import Card from 'components/card/Card'
// import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar-small.svg'
import { ReactComponent as IconClock } from 'assets/icons/clock-sm.svg'
import { ReactComponent as IconCross } from 'assets/icons/plus-stroke-sm.svg'
import { ReactComponent as IconGeo } from 'assets/icons/geo-sm.svg'

// import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import { ConsultationsSelectModel } from 'constants/ConsultationsTypes'
import { DATE_FORMAT_MAIN_SHORT, formatDate } from 'helpers/Date'
import {
  getUserFullName, getUserGenderAndAgeTitle, getUserSurnameWithAbr
} from 'helpers/User'
import { getClinicAddress } from 'helpers/Clinic'

const NotificationCardRemindConsultation = ({
  onTask,
  created,
  data
}) => {
  const {
    clinic = {},
    patient = {},
    start_scheduled_at: startScheduledAt = '',
    scheduled_at: scheduledAt = '',
    consultation_type: consultationType,
    time_before_task: timeBefore
  } = data
  const patientName = getUserSurnameWithAbr(patient)

  const titleDate = useMemo(() => formatDate(scheduledAt, DATE_FORMAT_MAIN_SHORT), [ scheduledAt ])

  const titleFromTo = useMemo(
    () => `${formatDate(startScheduledAt, 'HH:mm')} - ${formatDate(scheduledAt, 'HH:mm')}`,
    [ startScheduledAt, scheduledAt ]
  )

  const consultationTypeObj = useMemo(
    () => ConsultationsSelectModel.find((c) => c.id === consultationType),
    [ consultationType ]
  )

  const clinicAddressTitle = useMemo(
    () => getClinicAddress(clinic.address) || 'Адрес не указан',
    [ clinic.address ]
  )

  return (
    <div>
      <Box className="mb-2" ai="center" gap="8px">
        <UserAvatar
          size="medium"
          avatar={clinic.avatar}
          bgColor="grey150"
        />
        <div>
          <Text>
            Через
            {' '}
            <Text fw="m" tag="span">{timeBefore}</Text>
            {' '}
            у вас
            {' '}
            {String(consultationTypeObj.title).toLowerCase()}
            {' '}
            c
            {' '}
            {/* <LinkRoute
              fw="m"
              to={getPatientUrl(patient.id)}
              defaultStyle="blue"
            >
              {patientName}
            </LinkRoute> */}
            <Text fw="m" tag="span">{patientName}</Text>
            {' '}
            в клинике
            {' '}
            <Text fw="m" tag="span">
              «
              {clinic.name}
              »
            </Text>
          </Text>
          <Text className="mt-1" size="small" color="grey500">
            {created}
          </Text>
        </div>
      </Box>
      <Card
        isBordered
        brColor="grey150"
        bgColor="white"
      >
        <Box display="block" p="16px">
          <Box gap="24px" className="mb-4">
            <Box gap="8px">
              <UserAvatar
                size="normalSmall"
                avatar={patient.avatar}
                bgColor="grey150"
                user={patient}
                className="mt-1"
              />
              <div>
                <Text
                  size="normalSm"
                  className="mb-1"
                  fw="m"
                >
                  {getUserFullName(patient, true)}
                </Text>
                <Text color="grey500" size="xs">{getUserGenderAndAgeTitle(patient.gender, patient.date_birth)}</Text>
              </div>
            </Box>
            <Button
              onClick={() => onTask(patient.id, startScheduledAt)}
              isGradient
              title="Перейти к записи"
              size="small"
              rounded
              className="ml-a"
            />
          </Box>
          <Box className="mb-2" gap="8px">
            <TagIcon icon={IconCalendar} title={titleDate} />
            <TagIcon icon={IconClock} title={titleFromTo} />
          </Box>
          <Box isWrap gap="8px">
            {consultationTypeObj && <TagIcon icon={IconCross} title={consultationTypeObj.title} />}
            <TagIcon icon={IconGeo} title={clinicAddressTitle} />
          </Box>
        </Box>
      </Card>
    </div>
  )
}

export default NotificationCardRemindConsultation
