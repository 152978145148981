import s from 'styled-components'
import cn from 'classnames'

const renderStyles = ({
  display,
  flex,
  width,
  maxWidth,
  $minWidth,
  $minHeight,
  onClick = null,
  noPointers = false,
  ai,
  jc,
  $position,
  padding,
}) => `
  position: ${$position};
  width: ${width || 'auto'};
  padding: ${padding || ''};
  max-width: ${maxWidth ? !+maxWidth ? maxWidth : `${maxWidth}px` : ''};
  min-height: ${$minHeight};
  min-width: ${$minWidth ? !+$minWidth ? $minWidth : `${$minWidth}px` : ''};
  display: ${display || ''};
  flex: ${flex || ''};
  align-items: ${ai || 'center'};
  justify-content: ${jc || ''};
  cursor: ${onClick ? 'pointer' : ''};
  pointer-events: ${noPointers ? 'none' : 'initial'};
`

const GridItemContainerDiv = s.div`
  ${(props) => renderStyles(props)}
`

const GridItem = ({
  children,
  className = '',
  minHeight = '',
  minWidth = '',
  position = '',
  ...otherProps
}) => {
  const classes = cn('w-grid-item', className)

  const props = {
    className: classes,
    $minHeight: minHeight,
    $minWidth: minWidth,
    $position: position,
    ...otherProps,
  }

  return (
    <GridItemContainerDiv {...props}>
      {children}
    </GridItemContainerDiv>
  )
}

export default GridItem
