import { useState, useCallback } from 'react'

import Box from 'layout/Box'
import { ModalContent } from 'components/modal'
import Positioner from 'components/Positioner'
import ButtonRound from 'components/base/ButtonRound'

import { ReactComponent as IconChevronLeft } from 'assets/icons/chevron-left.svg'
import { ReactComponent as IconChevronRight } from 'assets/icons/chevron-right.svg'

import CardImageLazy from 'components/card/CardImageLazy'
import { MediaFileTypes } from 'constants/MediaTypes'
import VideoLazy from 'components/base/VideoLazy'
import ModalCenterLayoutFull from './ModalCenterLayoutFull'

const ModalImagePreview = ({
  closeModal = () => {},
  modal: { images = [], activeDefault = null },
}) => {
  const [ activeIdx, setActiveIdx ] = useState(activeDefault || 0)

  const onToggleActive = useCallback((value) => {
    if (value > 0) {
      if (activeIdx < images.length - 1) {
        setActiveIdx(activeIdx + value)
      }
      else {
        setActiveIdx(0)
      }
    }
    else if (value < 0) {
      if (activeIdx > 0) {
        setActiveIdx(activeIdx + value)
      }
      else {
        setActiveIdx(images.length - 1)
      }
    }
  }, [ activeIdx, setActiveIdx, images ])

  const isMulti = Array.isArray(images) && images.length > 1

  return (
    <ModalCenterLayoutFull
      onClose={closeModal}
      noPointers
      headPosition="absolute"
    >
      {isMulti && (
        <Positioner i={10} type="center-y" left="10px">
          <ButtonRound
            onClick={() => onToggleActive(-1)}
            defaultStyle="light"
            br="50%"
          >
            <IconChevronLeft />
          </ButtonRound>
        </Positioner>
      )}
      <ModalContent p="0px 75px" height="100%">
        {images.map((img, idx) => (
          <Positioner
            key={idx}
            type="cover"
            i={activeIdx === idx ? 2 : -1}
            opacity={activeIdx === idx ? 1 : 0}
          >
            <Box
              height="100%"
              width="100%"
              ai="center"
              jc="center"
              minHeight="200px"
              minWidth="200px"
            >
              {img.type === MediaFileTypes.IMAGE && (
                <CardImageLazy
                  className="d-flex h-100 w-100"
                  variant="full"
                  background={false}
                  src={img.src}
                  minHeight="auto"
                  bgColor="transparent"
                  defaultPadding="0px"
                  defaultStyle="auto"
                  br="0px"
                  objectFit="contain"
                />
              )}
              {img.type === MediaFileTypes.VIDEO && (
                <VideoLazy
                  className="d-flex h-100 w-100"
                  src={img.src}
                  width="100%"
                  height="100%"
                />
              )}
            </Box>
          </Positioner>
        ))}
      </ModalContent>
      {isMulti && (
        <Positioner i={10} type="center-y" right="10px">
          <ButtonRound
            onClick={() => onToggleActive(1)}
            defaultStyle="light"
            br="50%"
          >
            <IconChevronRight />
          </ButtonRound>
        </Positioner>
      )}
    </ModalCenterLayoutFull>
  )
}

export default ModalImagePreview
