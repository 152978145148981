import styled from 'styled-components'

const FormContainer = styled.form`
  display: ${({ $isFlex }) => ($isFlex ? 'flex' : '')};
  flex-direction: ${({ $isFlex }) => ($isFlex ? 'column' : '')};
  flex: ${({ $isFlex }) => ($isFlex ? 1 : '')};
  overflow-y: ${({ $overflowY }) => ($overflowY || '')};
`

const Form = ({
  children,
  isFlex = false,
  overflowY = '',
  ...otherProps
}) => (
  <FormContainer
    $isFlex={isFlex}
    $overflowY={overflowY}
    {...otherProps}
  >
    {children}
  </FormContainer>
)

export default Form
