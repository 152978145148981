// currentConversationId: (state) => state.currentConversationId,
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-continue */
/* eslint-disable guard-for-in */
// @ts-ignore
import { MessengerActionTypes } from 'services/Voximplant/messenger.config'
import { PAGE_DASHBOARD_CHAT } from 'constants/PathsTypes'
import { getCallStatusTitle } from 'helpers/Call'
import { numeralizeFiles } from 'helpers/Numeralize'
import { strToLowerCase } from 'utils/string'

import * as VoxImplant from 'voximplant-websdk'
import { chatUnreadType } from 'redux/dashboard/Chat/ChatReducer'
import { getFileType } from '../../Media/MediaHelpers'
import { logHelp } from '../../../utils/utils'

const appName = process.env.REACT_APP_VOXIMPLANT_APP_NAME
const account = process.env.REACT_APP_VOXIMPLANT_ACCOUNT_ID

export const updateMessagesAsRead = (messages, lastReadSeq) => (messages
  ? messages.map((m) => {
    if (Number(m.seq) <= Number(lastReadSeq)) {
    // eslint-disable-next-line prefer-object-spread
      return Object.assign(m, { markAsRead: true })
    }

    return m
  })
  : messages)

export const getUserUri = (username) => `${username}@${appName}.${account}`

export const getCurrentConversation = (conversations, conversationId) => conversations.find((c) => c.uuid === conversationId)

export const addOnline = (onlineUsers, userId) => {
  if (onlineUsers.some((id) => +id === +userId)) {
    return onlineUsers
  }

  return [ ...onlineUsers, userId ]
}

export const deleteOnline = (onlineUsers, userId) => onlineUsers.filter((u) => +u !== +userId)

export const findChatUser = (users, user) => {
  if (Array.isArray(users) && typeof user === 'object') {
    return users.find((u) => u.userName.indexOf(user.voximplant_username) === 0)
  }

  return null
}
// // @ts-ignore
// currentConversationUsers: (state, getters) => {
//   if (getters.currentConversation) {
//     return state.users.filter((u: User) => !!getters.currentConversation.participants.find((p: Participant) => p.userId === u.userId));
//   }
// },

// currentConversationHistory: (state) => {
//   return state.conversationsHistory[state.currentConversationId];
// },
// // @ts-ignore
// currentConversationMyPermissions: (state) => {
//   if (state.currentConversationId) {
//     return state.conversations.find((c: Conversation) => c.uuid === state.currentConversationId).participants.find((user: Participant) => user.userId === state.currentUser.userId);
//   }
// },

// // Return permissions first not admin user
// currentConversationPermissions: (state, getters) => {
//   if (getters.currentConversation) {
//     return getters.currentConversation.customData.permissions ?
//       getters.currentConversation.customData.permissions
//       : getters.currentConversation.participants.find((user: Participant) => user.userId !== state.currentUser.userId && !user.isOwner);
//   }
// },
// // @ts-ignore
// currentConversationAdmins: (state, getters) => {
//   if (getters.currentConversation) {
//     return state.users.filter((u: User) => getters.currentConversation.participants.find((p: Participant) => p.isOwner && (u.userId === p.userId)));
//   }
// },

// possibleChatAdmins: (state, getters) => {
//   if (getters.currentConversation) {
//     return getters.currentConversationUsers.filter((u: User) => !getters.currentConversationAdmins.find((a: any) => a.userId === u.userId));
//   }
// },
// // @ts-ignore
// currentDirectUser: (state, getters) => {
//   if (getters.currentConversation && getters.currentConversation.direct) {
//     return state.users.find((u: User) => u.userId === getters.currentConversation.directUserId);
//   }
// },

// currentDirectConversations: (state) => state.conversations.filter((c: Conversation) => c.direct),

// currentDirectUsersId: (state, getters) => getters.currentDirectConversations.reduce(
//   (res: number[], d: Conversation) => {
//     d.participants.forEach((u) => {
//       if (u.userId !== state.currentUser.userId) {
//         res.push(u.userId);
//       }});

//     return res;
//   }, []),

// possibleDirectUsers: (state, getters) => {
//   return state.users.filter((u: User) => !getters.currentDirectUsersId.find((userId: number) => userId === u.userId));
// },
// // @ts-ignore
// possibleChatUsers: (state, getters) => {
//   if (getters.currentConversation) {
//     return state.users.filter((u: User) => !getters.currentConversationUsers.find((p: any) => p.userId === u.userId));
//   }
// },

// currentTypingUsers: (state) => state.typingUsers,
// currentOnlineUsers: (state) => state.onlineUsers,

export const updateConversationLastEvent = (state, lastEvent) => state.conversations.map((c) => (c.uuid === state.currentConversationId
  ? Object.assign(c, { lastEvent })
  : c))

export const updateConversationSeq = (state, payload) => {
  const conversation = state.conversations.find((c) => {
    if (payload.conversation) {
      return c.uuid === payload.conversation.uuid
    }
    return c.uuid === payload.message.conversation
  }) || state.currentConversation

  if (conversation) {
    return state.conversations.map((c) => (c.uuid === conversation.uuid
      ? Object.assign(c, { _lastSeq: payload.seq })
      : c))
  }

  return state.conversations
}

export const updateConversationMessage = (messages, message) => (messages
  ? [ ...messages, message ]
  : [ message ])

export const addNewConversationMessage = (messages, message) => (messages
  ? [ ...messages, message ]
  : [ message ])

export const updateConversationLastMessage = (state, lastEvent) => {
  if (lastEvent.messengerAction === MessengerActionTypes.SEND_MESSAGE) {
    let lastMessage = lastEvent.message.text

    if (!lastMessage) {
      const isCall = lastEvent.message.payload && lastEvent.message.payload[0] && lastEvent.message.payload[0].call
      const isMe = state.user && state.user.userId === lastEvent.message.sender

      if (isCall) {
        const { status, duration } = isCall
        lastMessage = isMe ? 'Исходящий вызов' : 'Входящий вызов'

        lastMessage += ` (${getCallStatusTitle(status, duration, isMe)})`
      }
      else {
        const isAttach = lastEvent.message.payload && lastEvent.message.payload[0] && lastEvent.message.payload[0].attach

        if (isAttach) {
          lastMessage = `${isMe ? `Отправлен${isAttach.length === 1 ? '' : 'о'}` : `Получен${isAttach.length === 1 ? '' : 'о'}`} ${isAttach.length} ${numeralizeFiles(isAttach.length)}`
        }
      }
    }

    return state.conversations.map((conversation) => (conversation.uuid === lastEvent.message.conversation
      ? Object.assign(conversation, {
        lastMessage,
        timestamp: lastEvent.timestamp,
        _lastSeq: Number(lastEvent.seq)
      })
      : conversation))
  }

  if (lastEvent.messengerAction === MessengerActionTypes.CREATE_CONVERSATION) {
    return state.conversations.map((c) => (c.uuid === lastEvent.conversation.uuid
      ? Object.assign(c, {
        lastMessage: 'Нет сообщений',
        timestamp: lastEvent.timestamp,
        _lastSeq: Number(lastEvent.seq)
      })
      : c))
  }

  return state.conversations
}

export const unreadUpdateAction = (state, data) => {
  const {
    event,
    type,
  } = data
  if (type === chatUnreadType.ADD) {
    let curUnreadIndex = -1
    const curUnread = state.unreaded.find((unread, index) => {
      if (unread.uuid === event.message.conversation) {
        curUnreadIndex = index
        return true
      }
      return false
    })
    if (curUnread) {
      const newUnread = [ ...state.unreaded ]
      newUnread[curUnreadIndex] = {
        ...newUnread[curUnreadIndex],
        count: newUnread[curUnreadIndex].count + 1,
        list: [ ...newUnread[curUnreadIndex].list, event ]
      }
      return newUnread
    }
    return [ ...state.unreaded, {
      count: 1,
      list: [ event ],
      uuid: event.message.conversation,
    }]
  }

  let unreadIndex = null
  const deleteUnread = state.unreaded.find((unread, index) => {
    if (unread.uuid === event.message.conversation) {
      unreadIndex = index
      return true
    }
  })
  if (deleteUnread) {
    const newUnread = [ ...state.unreaded ]
    if (deleteUnread.count === 1) {
      return newUnread.filter((i, index) => index !== unreadIndex)
    }
    newUnread[unreadIndex] = {
      ...newUnread[unreadIndex],
      count: newUnread[unreadIndex].count - 1,
      list: newUnread[unreadIndex].list.filter((item) => item.message.uuid !== event.message.uuid)
    }
    return newUnread
  }

  return state.unreaded
}

export const getLastMessage = (lastEvent, user) => {
  if (lastEvent) {
    let lastMessage = lastEvent.text
    const isMe = user && (user.userId === lastEvent._sender)

    if (!lastMessage) {
      const isCall = lastEvent.payload && lastEvent.payload[0] && lastEvent.payload[0].call

      if (isCall) {
        const { status, duration } = isCall
        lastMessage = isMe ? 'Исходящий вызов' : 'Входящий вызов'

        lastMessage += ` (${getCallStatusTitle(status, duration, isMe)})`
      }
      else {
        const attach = lastEvent.payload && lastEvent.payload[0] && lastEvent.payload[0].attach

        if (attach) {
          lastMessage = `${isMe ? `Отправлен${attach.length === 1 ? '' : 'о'}` : `Получен${attach.length === 1 ? '' : 'о'}`} ${attach.length} ${numeralizeFiles(attach.length)}`
        }
      }
    }

    return lastMessage
  }

  return 'Нет сообщений'
}

export const updateConversationUnreaded = (state, payload) => {
  const conversationId = state.currentConversationId || payload.conversation

  if (conversationId && state.conversations) {
    return state.conversations.map((c) => {
      if (c.uuid === conversationId) {
        const participants = c.participants.map((p) => (p.userId === state.user.userId
          ? {
            ...p,
            lastRead: String(payload.lastSeq || payload.seq || c.lastSeq)
          }
          : p))

        return Object.assign(c, { _participants: participants })
      }

      return c
    })
  }

  return state.conversations
}

export const updateConversationEditMessagePreloaded = (state, payload) => state.conversationMessages.map((m) => {
  if (m.message) {
    if (m.message && m.message.payload && payload.message && payload.message.uuid && m.message.uuid === payload.message.uuid) {
      payload.message.uploaded = true
      payload.message.uploading = false
      payload.message.timestamp = m.message.timestamp
      payload.timestamp = m.timestamp
      payload.message.changed = true
      payload.message.payload = [{
        ...m.message.payload[0],
        attach: [
          ...payload.message.payload[0].attach
        ]
      }]
      return payload
    }
    return m
  }

  if (m && m.payload && payload.message && payload.message.uuid && m.uuid === payload.message.uuid) {
    payload.message.uploaded = true
    payload.message.uploading = false
    payload.message.timestamp = m.timestamp
    payload.message.changed = true
    payload.message.payload = [{
      ...m.payload[0],
      attach: [
        ...payload.message.payload[0].attach
      ]
    }]

    return payload
  }
  return m
})

export const updateConversationMessagePreloaded = (state, payload) => (state.conversationMessages
  ? state.conversationMessages.map((m) => {
    if (m.payload && payload.payload[0] && payload.payload[0].uploadId && m.uuid === payload.payload[0].uploadId) {
      payload.uploaded = true
      payload.uploading = false
      payload.payload = [{
        ...m.payload[0],
        attach: [ ...m.payload[0].attach.map((a, idx) => ({
          ...payload.payload[0].attach[idx],
          url: a.url,
        })) ]
      }]

      return payload
    }
    return m
  })
  : [ payload ])

export const getAttachUploadModel = (attach) => ({
  id: attach.id,
  filename: attach.file.name,
  size: attach.file.size,
  type: getFileType(attach.file.type),
  url: attach.preview,
  uploading: true,
  file: Object.assign(attach.file, { id: attach.id })
})

export const getAttachPayloadModel = (attach, id, url) => ({
  id,
  filename: attach.filename,
  type: attach.type,
  size: attach.size,
  url,
  uploaded: true,
})

export const updateUnreaded = (unreaded = [], payload = {}) => {
  const isExist = unreaded.find((u) => u.conversationId === payload.conversationId)
  return isExist
    ? payload.count === 0
      ? unreaded.filter((u) => (u.conversationId !== payload.conversationId))
      : unreaded.map((u) => (u.conversationId === payload.conversationId ? payload : u))
    : payload.count === 0
      ? unreaded
      : [ ...unreaded, payload ]
}

export const getChatUserNameFromUri = (userUri) => {
  const [ userName ] = userUri.split('@')
  return userName
}

export function filterListByName(list, search) {
  if (typeof search !== 'string' || !Array.isArray(list)) {
    return list
  }

  const str = strToLowerCase(search)

  if (Array.isArray(list)) {
    return list.filter((user) => (user.userInfo && user.userInfo.first_name && strToLowerCase(user.userInfo.first_name).indexOf(str)) >= 0
    || (user.userInfo.middle_name && strToLowerCase(user.userInfo.middle_name).indexOf(str) >= 0)
    || (user.userInfo.last_name && strToLowerCase(user.userInfo.last_name).indexOf(str) >= 0
    ))
  }

  return list
}

export function filterListByDate(list) {
  return list.sort((itemA, itemB) => {
    if (!itemB.conversation || !itemB.conversation.timestamp) {
      if (!itemA.conversation || !itemA.conversation.timestamp) {
        return (itemB.conversation?._createdAt || 0) - (itemA.conversation?._createdAt || 0)
      }
      return -1
    }
    if (!itemA.conversation || !itemA.conversation.timestamp) {
      return 1
    }
    return itemB.conversation.timestamp - itemA.conversation.timestamp
  })
}

// chatId = voximplant_username
export const getChatUserUrl = (chatId) => `${PAGE_DASHBOARD_CHAT}/${chatId}`

export const chatEventFilter = (events) => {
  const sendAction = VoxImplant.Messaging.MessengerAction.sendMessage
  const editAction = VoxImplant.Messaging.MessengerAction.editMessage
  const deleteAction = VoxImplant.Messaging.MessengerAction.removeMessage
  const messageEvents = []
  const filteredEvents = events
    .filter((e) => (
      e.messengerAction === sendAction
      || e.messengerAction === editAction
      || e.messengerAction === deleteAction
    ))

  if (!filteredEvents.length) return []

  // Group by message.uuid
  const groupByUuidEvents = filteredEvents.reduce((res, evt) => {
    res[evt.message.uuid] = res[evt.message.uuid] || []
    res[evt.message.uuid].push(evt)
    return res
  }, Object.create(null))
  // Get only relevant events for message.uuid
  for (const messageUuid in groupByUuidEvents) {
    const arrEvtsMessage = groupByUuidEvents[messageUuid]
    const isDeleted = arrEvtsMessage.find((m) => m.messengerAction === deleteAction)
    const isEdited = arrEvtsMessage.find((m) => m.messengerAction === editAction)
    if (isDeleted) {
      continue
    }
    else if (isEdited) {
      if (!arrEvtsMessage.find((m) => m.messengerAction === sendAction)) continue

      const sorted = arrEvtsMessage.sort((m) => m.timestamp)
      const initialMessage = sorted[0]
      const lastUpdated = sorted[sorted.length - 1]
      lastUpdated.message.editedAt = lastUpdated.timestamp
      lastUpdated.timestamp = initialMessage.timestamp
      lastUpdated.message.editedBy = lastUpdated.initiator
      lastUpdated.message.changed = true
      lastUpdated.initiator = initialMessage.initiator
      logHelp('retransmit edited', initialMessage, lastUpdated)
      messageEvents.push(lastUpdated)
    }
    else {
      messageEvents.push(...arrEvtsMessage)
    }
  }

  return messageEvents
}
