export const ConsultantsActionTypes = {
  SET_FETCHING_DOCTOR_CONSULTANTS_LIST: 'SET_FETCHING_DOCTOR_CONSULTANTS_LIST',
  FETCH_DOCTOR_CONSULTANTS_LIST_SUCCESS: 'FETCH_DOCTOR_CONSULTANTS_LIST_SUCCESS',
  FETCH_DOCTOR_CONSULTANTS_LIST_ERROR: 'FETCH_DOCTOR_CONSULTANTS_LIST_ERROR',
  CLEAR_DOCTOR_CONSULTANTS_LIST: 'CLEAR_DOCTOR_CONSULTANTS_LIST',
  SET_DOCTOR_CONSULTANTS_LIST_COUNT: 'SET_DOCTOR_CONSULTANTS_LIST_COUNT',

  SET_FETCHING_UPLOAD_DOCTOR_CONSULTANTS_LIST: 'SET_FETCHING_UPLOAD_DOCTOR_CONSULTANTS_LIST',
  FETCH_UPLOAD_DOCTOR_CONSULTANTS_LIST_SUCCESS: 'FETCH_UPLOAD_DOCTOR_CONSULTANTS_LIST_SUCCESS',
  FETCH_UPLOAD_DOCTOR_CONSULTANTS_LIST_ERROR: 'FETCH_UPLOAD_DOCTOR_CONSULTANTS_LIST_ERROR',
}
