import Text from 'components/typo/Text'
import Box from 'layout/Box'
import { colors } from 'theme/index'

const TagIcon = ({
  className = '',
  title = 'tag',
  icon: Icon = null
}) => (
  <Box
    className={className}
    p="4px 6px"
    bg="grey100"
    ai="center"
    br="small"
  >
    {Icon && (
      <Icon
        width={16}
        height={16}
        fill={colors.grey500}
        className="mr-1"
      />
    )}
    <Text
      size="xs"
      color="black"
      lh="1"
    >
      {title}
    </Text>
  </Box>
)

export default TagIcon
