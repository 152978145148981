import cn from 'classnames'
import Box from 'layout/Box'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'

import { getUserFullName } from 'helpers/User'

const CardUserBlock = ({
  user = null,
  email,
  isActive,
  isStatic = false,
  size = 'normal',
  descrSize = 'normalSm',
  descrColor = 'grey700',
  defaultTitle = '',
  defaultDescription = '',
  ai = 'center',
  fw = '',
  renderIcon = () => {},
}) => {
  const userData = user || {
    first_name: '',
    last_name: '',
    avatar: null,
  }

  const fullName = user ? getUserFullName(user, true) : defaultTitle

  return (
    <Box ai={ai}>
      <Box
        isRelative
        className={cn({
          'mr-2': size !== 'small',
          'mr-10': size === 'small'
        })}
      >
        <UserAvatar
          size={size}
          placeholderName={fullName || email}
          avatar={userData.avatar}
          user={user}
        />
        {renderIcon()}
      </Box>
      <div>
        {fullName && (
          <Text noSelect color={isActive || isStatic ? '' : descrColor} isElipsed fw={fw}>
            {fullName}
          </Text>
        )}
        <Text
          noSelect
          size={descrSize}
          color={!isActive || isStatic ? descrColor : ''}
          isElipsed
        >
          {defaultDescription || email || ''}
        </Text>
      </div>
    </Box>
  )
}

export default CardUserBlock
