import Button from 'components/base/Button'
import { getTaskNamePrefix } from 'components/notifications/cards/NotificationCardTask'
import LinkRoute from 'components/typo/Link'
import Text from 'components/typo/Text'
import UserAvatar from 'components/User/UserAvatar'
import { messageTypesColors } from 'constants/NotifierTypes'
import { TaskNotificationTypes } from 'constants/TasksTypes'
import { formatDateOrTodayDynamic } from 'helpers/Date'
import { getUserFullName } from 'helpers/User'
import Box from 'layout/Box'
import { useMemo } from 'react'
import { getPatientUrl } from 'redux/helpers/PatientHelpers'
import { getTaskUrl } from 'redux/Tasks/TasksHelpers'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardPatientTaskDone = ({
  patient = {},
  task = {},
  type,
  titleColor = messageTypesColors.DEFAULT,
  onChat,
  onTask,
}) => {
  const taskName = useMemo(() => {
    const propName = getTaskNamePrefix(type)

    if ((task || {}).hasOwnProperty(propName)) {
      return (task[propName] || {}).name || ''
    }

    return ''
  }, [ task, type ])

  const doneAtTitle = useMemo(() => formatDateOrTodayDynamic(task.done_at, ' в '), [ task ])

  return (
    <div className="notifier-message-content">
      <NotifierMessageText
        className="mb-12"
        color={titleColor}
        text="Пациент выполнил задание"
        fw="medium"
        size="normalSm"
      />
      <Box className="my-3">
        <div className="mb-2 mr-2">
          <UserAvatar
            size="normalSmall"
            avatar={patient.avatar}
          />
        </div>
        <div>
          <Text>
            Пациент
            {' '}
            <LinkRoute
              to={getPatientUrl(patient.id)}
              defaultStyle="blue"
            >
              {getUserFullName(patient, true)}
            </LinkRoute>
            {' '}
            выполнил задание:
            {' '}
            <LinkRoute defaultStyle="blue" to={getTaskUrl(patient.id, task.created_at)}>
              {type === TaskNotificationTypes.UserCourseConsultation ? 'Консультация' : taskName}
            </LinkRoute>
          </Text>
          <Text className="mt-1" size="small" color="grey500">
            {doneAtTitle}
          </Text>
        </div>
      </Box>
      <Box gap="12px">
        <Button
          flex="1"
          full
          defaultStyle=""
          title="Смотреть задание"
          size="small"
          rounded
          onClick={() => onTask(patient.id, task.created_at)}
        />
        <Button
          flex="1"
          onClick={() => onChat(patient.voximplant_username)}
          full
          isGradient
          title="Чат с пациентом"
          size="small"
          rounded
        />
      </Box>

    </div>
  )
}

export default NotifierCardPatientTaskDone
