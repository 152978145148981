import Text from 'components/typo/Text'
import Box from 'layout/Box'

const ButtonWrapper = ({ children, title = '' }) => (
  <Box
    width="100px"
    direction="column"
    jc="center"
    ai="center"
    textAlign="center"
  >
    {children}
    <Text
      className="mt-1"
      color="white"
      size="xs"
      fw="m"
    >
      {title}
    </Text>
  </Box>
)

export default ButtonWrapper
