export const MY_APP = process.env.REACT_APP_VOXIMPLANT_APP_NAME
export const URL_NEW_USERS = 'https://users.example.com/'
export const TIME_ONLINE_NOTIFICATION = 10000
export const TIME_TYPING_NOTIFICATION = 10000

export const MessengerActionTypes = {
  SEND_MESSAGE: 'sendMessage',
  CREATE_CONVERSATION: 'createConversation',
}

export default {
  MY_APP,
  TIME_ONLINE_NOTIFICATION,
  TIME_TYPING_NOTIFICATION,
  URL_NEW_USERS,
}
