import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

const VIsibleTrigger = ({
  rootMargin = '0%',
  triggerOnce = true,
  threshold = 0.5,
  onTrigger = () => {}
}) => {
  const {
    ref,
    inView,
  } = useInView({
    triggerOnce,
    rootMargin,
    threshold,
  })

  useEffect(() => {
    if (inView) {
      onTrigger()
    }
    // eslint-disable-next-line
  }, [ inView ])

  return <div style={{ height: '5px' }} ref={ref} />
}

export default VIsibleTrigger
