import { Component } from 'react'

import { Button } from 'components/base'
import { Text } from 'components/typo'
import Box from 'layout/Box'
import { fetchLogout } from 'redux/Auth/AuthActions'
import { connect } from 'react-redux'
import LinkRoute from 'components/typo/Link'
import { SUPPORT_EMAIL } from '../constants/PathsTypes'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.warn(error, 'error derived')
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    console.warn(error, ' :catch error')
    console.warn(info, ' :catch error info')
    // You can also log the error to an error reporting service
  }

  handleLogout = () => {
    const { history, fetchLogout } = this.props
    fetchLogout(history)
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props
    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Box
          direction="column"
          ai="center"
          jc="space-between"
          defaultStyle=""
          height="100vh"
          className="p-40"
        >
          <div />
          <Box
            direction="column"
            ai="center"
          >
            <Text
              color="black"
              fw="n"
              size="mediumSm"
              caps
              className="mb-40 pb-1"
            >
              Ошибка! Что-то пошло не так...
            </Text>
            <Box
              direction="column"
              width="228px"
            >
              <Button
                size="big"
                title="Перезагрузить страницу"
                isGradient
                className="mb-4"
                width="228px"
                onClick={() => window.location.reload()}
              />
              <Button
                defaultStyle="secondaryGray"
                size="big"
                title="Войти заново"
                width="228px"
                onClick={() => {
                  this.handleLogout()
                  window.location.reload()
                }}
              />
            </Box>
          </Box>
          <Box>
            <Text
              size="normal"
              fw="n"
              className="mr-1"
            >
              Проблемы?
            </Text>
            <Text
              size="normal"
              fw="n"
              className="mr-1"
            >
              Написать на почту
            </Text>
            <LinkRoute
              href={`mailto:${SUPPORT_EMAIL}`}
              defaultStyle="blue"
              td="underline"
              isBrowser
            >
              {SUPPORT_EMAIL}
            </LinkRoute>
          </Box>
        </Box>
      )
    }

    return children
  }
}

const mapDispatchToProps = (dispatch) => ({ fetchLogout: (history) => dispatch(fetchLogout(history)) })

export default connect(null, mapDispatchToProps)(ErrorBoundary)
