export const ClinicsActionTypes = {
  SET_FETCHING_CLINICS: 'SET_FETCHING_CLINICS',
  FETCH_CLINICS_SUCCESS: 'FETCH_CLINICS_SUCCESS',
  FETCH_CLINICS_ERROR: 'FETCH_CLINICS_ERROR',

  SET_FETCHING_CLINIC: 'SET_FETCHING_CLINIC',
  FETCH_CLINIC_SUCCESS: 'FETCH_CLINIC_SUCCESS',
  FETCH_CLINIC_ERROR: 'FETCH_CLINIC_ERROR',

  SET_FETCHING_CLINICS_PROFILES: 'SET_FETCHING_CLINICS_PROFILES',
  FETCH_CLINICS_PROFILES_SUCCESS: 'FETCH_CLINICS_PROFILES_SUCCESS',
  FETCH_CLINICS_PROFILES_ERROR: 'FETCH_CLINICS_PROFILES_ERROR',

  SET_CLINICS: 'SET_CLINICS',
  SET_CLINICS_COUNT: 'SET_CLINICS_COUNT',
  SET_CLINICS_PAGE: 'SET_CLINICS_PAGE',
}
