import { NewsActionTypes } from 'redux/dashboard/News/NewsTypes'

const INITIAL_STATE = {
  news: null,
  isFetchingNews: false,
}

const newsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case NewsActionTypes.SET_NEWS:
    return {
      ...state,
      news: action.payload,
      isFetchingNews: false,
    }
  case NewsActionTypes.SET_FETCHING_NEWS:
    return {
      ...state,
      isFetchingNews: action.payload,
    }
  default:
    return state
  }
}

export default newsReducer
