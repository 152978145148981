import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { PatientsActionTypes } from 'redux/dashboard/Patients/PatientsTypes'
import { PatientActionTypes } from 'redux/dashboard/Patient/PatientTypes'
import StorageService from 'services/StorageService'
import { ListLayoutTypes } from 'constants/ListTypes'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,
  patientsCountExpired: 0,
  patientsCountIsAccepted: 0,
  layout: StorageService.getStructureLayout('patients') || ListLayoutTypes.TABLE,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getPatientFilters() || {},

  error: null,
}

const patientsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case PatientsActionTypes.FETCH_PATIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    }

  case PatientsActionTypes.CLEAR_PATIENTS_LIST:
    return {
      ...state,
      list: null
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_LAYOUT:
    return {
      ...state,
      layout: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_COUNT_ALL:
    return {
      ...state,
      patientsCountExpired: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_COUNT_IS_ACCEPTED:
    return {
      ...state,
      patientsCountIsAccepted: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case PatientsActionTypes.FETCH_UPLOAD_PATIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: state.list ? [ ...state.list, ...action.payload ] : action.payload,
    }

  case PatientsActionTypes.SET_PATIENTS_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case PatientsActionTypes.ADD_PATIENTS_LIST:
    return {
      ...state,
      list: state.list.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case PatientsActionTypes.ADD_PATIENT_TO_LIST:
    return {
      ...state,
      list: state.list ? [ ...state.list, action.payload ] : state.list,
      listCount: state.list ? state.list.length + 1 : state.listCount,
      patientsCountExpired: state.patientsCountExpired + 1
    }

  case PatientsActionTypes.SET_PATIENTS_FILTERS:
    return {
      ...state,
      filters: action.payload
    }

  case PatientsActionTypes.SET_PATIENTS_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value
      }
    }

  case PatientsActionTypes.SET_FETCHING_PATIENTS_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case PatientsActionTypes.SET_FETCHING_PATIENTS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case PatientsActionTypes.FETCH_PATIENTS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case PatientsActionTypes.CLEAR_PATIENTS_SEARCH:
    return {
      ...state,
      searchList: []
    }

  case PatientsActionTypes.SET_PATIENTS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  case PatientActionTypes.FETCH_PATIENT_EXPIRED_TASKS_COUNT_SUCCESS: {
    if (state.list) {
      const {
        patientId,
        count
      } = action.payload

      return {
        ...state,
        list: state.list.map((patient) => {
          if (patient.id === patientId) {
            return {
              ...patient,
              has_expired_tasks: count > 0
            }
          }

          return patient
        })
      }
    }

    return state
  }

  default:
    return state
  }
}

export default patientsReducer
