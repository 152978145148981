import {
  addStructureFolder,
  addStructureItem,
  deleteStructureFolder,
  deleteStructureItem,
  moveStructureItem,
  updateStructureFolder,
  updateStructureItem
} from 'redux/Structure/StructureHelpers'
import { DoctorActionTypes, DoctorStructureActionTypes } from 'redux/dashboard/Doctor/DoctorTypes'

const INITIAL_STATE = {
  doctor: null,
  isFetchingDoctor: false,

  workExperience: null,
  isFetchingWorkExperience: false,

  internships: null,
  isFetchingInternships: false,

  scientificActivity: null,
  isFetchingScientificActivity: false,

  reviews: null,
  isFetchingReviews: false,

  consultations: null,
  isFetchingConsultations: false,

  certificates: null,
  isFetchingCertificates: false,

  patients: null,
  patientsLastSearch: null,
  patientsCount: 0,
  isFetchingPatients: false,

  documentsStructure: null,
  isFetchingStructure: false,

  error: null,
}

const doctorReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case DoctorActionTypes.CLEAR_DOCTOR_STATE:
    return { ...INITIAL_STATE }

  case DoctorActionTypes.SET_FETCHING_DOCTOR:
    return {
      ...state,
      isFetchingDoctor: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTOR_SUCCESS:
  case DoctorActionTypes.SET_DOCTOR:
    return {
      ...state,
      doctor: action.payload
    }

  case DoctorActionTypes.SET_DOCTORS_PATIENTS_COUNT:
    return {
      ...state,
      patientsCount: action.payload
    }

  case DoctorActionTypes.SET_DOCTORS_PATIENTS_LAST_SEARCH:
    return {
      ...state,
      patientsLastSearch: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_PATIENTS:
    return {
      ...state,
      isFetchingPatients: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_PATIENTS_SUCCESS:
    return {
      ...state,
      patients: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_WORK_EXPERIENCE:
    return {
      ...state,
      isFetchingWorkExperience: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_WORK_EXPERIENCE_SUCCESS:
    return {
      ...state,
      workExperience: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_INTERNSHIPS:
    return {
      ...state,
      isFetchingInternships: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_INTERNSHIPS_SUCCESS:
    return {
      ...state,
      internships: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_SCIENTIFIC_ACTIVITY:
    return {
      ...state,
      isFetchingScientificActivity: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_SCIENTIFIC_ACTIVITY_SUCCESS:
    return {
      ...state,
      scientificActivity: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_REVIEWS:
    return {
      ...state,
      isFetchingReviews: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_REVIEWS_SUCCESS:
    return {
      ...state,
      reviews: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTORS_CONSULTATIONS:
    return {
      ...state,
      isFetchingConsultations: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTORS_CONSULTATIONS_SUCCESS:
    return {
      ...state,
      consultations: action.payload
    }

  case DoctorActionTypes.SET_FETCHING_DOCTOR_CERTIFICATES:
    return {
      ...state,
      isFetchingCertificates: action.payload
    }

  case DoctorActionTypes.FETCH_DOCTOR_CERTIFICATES_SUCCESS:
    return {
      ...state,
      certificates: action.payload
    }

  case DoctorStructureActionTypes.SET_FETCHING_DOCTOR_STRUCTURE_BY_TYPE:
    return {
      ...state,
      isFetchingStructure: action.payload
    }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_BY_TYPE_SUCCESS:
    return {
      ...state,
      documentsStructure: action.payload.response
    }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_CREATE_FOLDER_SUCCESS: {
    const { response, isSearch } = action.payload

    return {
      ...state,
      documentsStructure: addStructureFolder(state.documentsStructure, response, isSearch)
    }
  }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_DELETE_FOLDER_SUCCESS:
    return {
      ...state,
      documentsStructure: deleteStructureFolder(state.documentsStructure, action.payload),
    }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_UPDATE_FOLDER_SUCCESS:
    return {
      ...state,
      documentsStructure: updateStructureFolder(state.documentsStructure, action.payload),
    }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_MOVE_FOLDER_SUCCESS:
    return {
      ...state,
      documentsStructure: moveStructureItem(state.documentsStructure, action.payload, 'children')
    }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_CREATE_FILE_SUCCESS: {
    const { response } = action.payload

    return {
      ...state,
      documentsStructure: addStructureItem(state.documentsStructure, response, 'files')
    }
  }

  case DoctorStructureActionTypes.DOCTOR_STRUCTURE_UPDATE_FILE: {
    const {
      parentId,
      fileId,
      response,
      isSerach
    } = action.payload

    return {
      ...state,
      documentsStructure: updateStructureItem(state.documentsStructure, parentId, fileId, response, isSerach),
    }
  }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_DELETE_FILE_SUCCESS: {
    const {
      isSearch,
      file
    } = action.payload

    return {
      ...state,
      documentsStructure: deleteStructureItem(state.documentsStructure, file, isSearch, 'files'),
    }
  }

  case DoctorStructureActionTypes.FETCH_DOCTOR_STRUCTURE_MOVE_FILE_SUCCESS:
    return {
      ...state,
      documentsStructure: moveStructureItem(state.documentsStructure, action.payload, 'files')
    }

  default:
    return state
  }
}

export default doctorReducer
