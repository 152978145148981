export const DoctorActionTypes = {
  SET_FETCHING_DOCTOR: 'SET_FETCHING_DOCTOR',
  FETCH_DOCTOR_ERROR: 'FETCH_DOCTOR_ERROR',
  FETCH_DOCTOR_SUCCESS: 'FETCH_DOCTOR_SUCCESS',
  SET_DOCTOR: 'SET_DOCTOR',

  SET_FETCHING_DOCTORS_PATIENTS: 'SET_FETCHING_DOCTORS_PATIENTS',
  FETCH_DOCTORS_PATIENTS_SUCCESS: 'FETCH_DOCTORS_PATIENTS_SUCCESS',
  FETCH_DOCTORS_PATIENTS_ERROR: 'FETCH_DOCTORS_PATIENTS_ERROR',
  SET_DOCTORS_PATIENTS_COUNT: 'SET_DOCTORS_PATIENTS_COUNT',
  SET_DOCTORS_PATIENTS_LAST_SEARCH: 'SET_DOCTORS_PATIENTS_LAST_SEARCH',

  SET_FETCHING_DOCTORS_WORK_EXPERIENCE: 'SET_FETCHING_DOCTORS_WORK_EXPERIENCE',
  FETCH_DOCTORS_WORK_EXPERIENCE_SUCCESS: 'FETCH_DOCTORS_WORK_EXPERIENCE_SUCCESS',
  FETCH_DOCTORS_WORK_EXPERIENCE_ERROR: 'FETCH_DOCTORS_WORK_EXPERIENCE_ERROR',

  SET_FETCHING_DOCTORS_INTERNSHIPS: 'SET_FETCHING_DOCTORS_INTERNSHIPS',
  FETCH_DOCTORS_INTERNSHIPS_SUCCESS: 'FETCH_DOCTORS_INTERNSHIPS_SUCCESS',
  FETCH_DOCTORS_INTERNSHIPS_ERROR: 'FETCH_DOCTORS_INTERNSHIPS_ERROR',

  SET_FETCHING_DOCTORS_SCIENTIFIC_ACTIVITY: 'SET_FETCHING_DOCTORS_SCIENTIFIC_ACTIVITY',
  FETCH_DOCTORS_SCIENTIFIC_ACTIVITY_SUCCESS: 'FETCH_DOCTORS_SCIENTIFIC_ACTIVITY_SUCCESS',
  FETCH_DOCTORS_SCIENTIFIC_ACTIVITY_ERROR: 'FETCH_DOCTORS_SCIENTIFIC_ACTIVITY_ERROR',

  SET_FETCHING_DOCTORS_REVIEWS: 'SET_FETCHING_DOCTORS_REVIEWS',
  FETCH_DOCTORS_REVIEWS_SUCCESS: 'FETCH_DOCTORS_REVIEWS_SUCCESS',
  FETCH_DOCTORS_REVIEWS_ERROR: 'FETCH_DOCTORS_REVIEWS_ERROR',

  SET_FETCHING_DOCTORS_CONSULTATIONS: 'SET_FETCHING_DOCTORS_CONSULTATIONS',
  FETCH_DOCTORS_CONSULTATIONS_SUCCESS: 'FETCH_DOCTORS_CONSULTATIONS_SUCCESS',
  FETCH_DOCTORS_CONSULTATIONS_ERROR: 'FETCH_DOCTORS_CONSULTATIONS_ERROR',

  SET_FETCHING_DOCTOR_CERTIFICATES: 'SET_FETCHING_DOCTOR_CERTIFICATES',
  FETCH_DOCTOR_CERTIFICATES_SUCCESS: 'FETCH_DOCTOR_CERTIFICATES_SUCCESS',
  FETCH_DOCTOR_CERTIFICATES_ERROR: 'FETCH_DOCTOR_CERTIFICATES_ERROR',

  CLEAR_DOCTOR_STATE: 'CLEAR_DOCTOR_STATE',
}

export const DoctorStructureActionTypes = {
  SET_FETCHING_DOCTOR_STRUCTURE_BY_TYPE: 'SET_FETCHING_DOCTOR_STRUCTURE_BY_TYPE',
  FETCH_DOCTOR_STRUCTURE_BY_TYPE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_BY_TYPE_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_BY_TYPE_ERROR: 'FETCH_DOCTOR_STRUCTURE_BY_TYPE_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_MOVE: 'SET_FETCHING_DOCTOR_STRUCTURE_MOVE',
  FETCH_DOCTOR_STRUCTURE_MOVE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_MOVE_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_MOVE_ERROR: 'FETCH_DOCTOR_STRUCTURE_MOVE_ERROR',
  SET_DOCTOR_STRUCTURE_MOVE_OPEN_FOLDER_SUCCESS: 'SET_DOCTOR_STRUCTURE_MOVE_OPEN_FOLDER_SUCCESS',
  CLEAR_DOCTOR_STRUCTURE_MOVE: 'CLEAR_DOCTOR_STRUCTURE_MOVE',

  // SET_FETCHING_DOCTOR_STRUCTURE_UPDATE_FILE: 'SET_FETCHING_DOCTOR_STRUCTURE_UPDATE_FILE',
  // FETCH_DOCTOR_STRUCTURE_UPDATE_FILE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_UPDATE_FILE_SUCCESS',
  // FETCH_DOCTOR_STRUCTURE_UPDATE_FILE_ERROR: 'FETCH_DOCTOR_STRUCTURE_UPDATE_FILE_ERROR',
  DOCTOR_STRUCTURE_UPDATE_FILE: 'DOCTOR_STRUCTURE_UPDATE_FILE',

  SET_FETCHING_DOCTOR_STRUCTURE_CREATE_FILE: 'SET_FETCHING_DOCTOR_STRUCTURE_CREATE_FILE',
  FETCH_DOCTOR_STRUCTURE_CREATE_FILE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_CREATE_FILE_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_CREATE_FILE_ERROR: 'FETCH_DOCTOR_STRUCTURE_CREATE_FILE_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_DELETE_FILE: 'SET_FETCHING_DOCTOR_STRUCTURE_DELETE_FILE',
  FETCH_DOCTOR_STRUCTURE_DELETE_FILE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_DELETE_FILE_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_DELETE_FILE_ERROR: 'FETCH_DOCTOR_STRUCTURE_DELETE_FILE_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FILE: 'SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FILE',
  FETCH_DOCTOR_STRUCTURE_MOVE_FILE_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_MOVE_FILE_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_MOVE_FILE_ERROR: 'FETCH_DOCTOR_STRUCTURE_MOVE_FILE_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_UPDATE_FOLDER: 'SET_FETCHING_DOCTOR_STRUCTURE_UPDATE_FOLDER',
  FETCH_DOCTOR_STRUCTURE_UPDATE_FOLDER_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_UPDATE_FOLDER_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_UPDATE_FOLDER_ERROR: 'FETCH_DOCTOR_STRUCTURE_UPDATE_FOLDER_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_CREATE_FOLDER: 'SET_FETCHING_DOCTOR_STRUCTURE_CREATE_FOLDER',
  FETCH_DOCTOR_STRUCTURE_CREATE_FOLDER_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_CREATE_FOLDER_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_CREATE_FOLDER_ERROR: 'FETCH_DOCTOR_STRUCTURE_CREATE_FOLDER_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_DELETE_FOLDER: 'SET_FETCHING_DOCTOR_STRUCTURE_DELETE_FOLDER',
  FETCH_DOCTOR_STRUCTURE_DELETE_FOLDER_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_DELETE_FOLDER_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_DELETE_FOLDER_ERROR: 'FETCH_DOCTOR_STRUCTURE_DELETE_FOLDER_ERROR',

  SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FOLDER: 'SET_FETCHING_DOCTOR_STRUCTURE_MOVE_FOLDER',
  FETCH_DOCTOR_STRUCTURE_MOVE_FOLDER_SUCCESS: 'FETCH_DOCTOR_STRUCTURE_MOVE_FOLDER_SUCCESS',
  FETCH_DOCTOR_STRUCTURE_MOVE_FOLDER_ERROR: 'FETCH_DOCTOR_STRUCTURE_MOVE_FOLDER_ERROR',
}
