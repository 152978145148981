export const ClientsActionTypes = {
  SET_FETCHING_CLIENTS_LIST: 'SET_FETCHING_CLIENTS_LIST',
  FETCH_CLIENTS_LIST_SUCCESS: 'FETCH_CLIENTS_LIST_SUCCESS',
  FETCH_CLIENTS_LIST_ERROR: 'FETCH_CLIENTS_LIST_ERROR',
  CLEAR_CLIENTS_LIST: 'CLEAR_CLIENTS_LIST',
  SET_CLIENTS_LIST_LAST_SEARCH: 'SET_CLIENTS_LIST_LAST_SEARCH',
  SET_CLIENTS_LIST_COUNT: 'SET_CLIENTS_LIST_COUNT',

  SET_FETCHING_CLIENTS_SEARCH: 'SET_FETCHING_CLIENTS_SEARCH',
  FETCH_CLIENTS_SEARCH_SUCCESS: 'FETCH_CLIENTS_SEARCH_SUCCESS',
  FETCH_CLIENTS_SEARCH_ERROR: 'FETCH_CLIENTS_SEARCH_ERROR',
  CLEAR_CLIENTS_SEARCH: 'CLEAR_CLIENTS_SEARCH',

  SET_FETCHING_UPLOAD_CLIENTS_LIST: 'SET_FETCHING_UPLOAD_CLIENTS_LIST',
  FETCH_UPLOAD_CLIENTS_LIST_SUCCESS: 'FETCH_UPLOAD_CLIENTS_LIST_SUCCESS',
  FETCH_UPLOAD_CLIENTS_LIST_ERROR: 'FETCH_UPLOAD_CLIENTS_LIST_ERROR',

  SET_CLIENTS_FILTERS: 'SET_CLIENTS_FILTERS',
  SET_CLIENTS_FILTER: 'SET_CLIENTS_FILTER',
  SET_CLIENTS_SEARCH_VALUE: 'SET_CLIENTS_SEARCH_VALUE',
}
