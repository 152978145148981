export const CallActionTypes = {
  SET_CURRENT_CALL: 'SET_CURRENT_CALL',
  SET_CURRENT_CALL_CONNECTED: 'SET_CURRENT_CALL_CONNECTED',
  CLEAR_CURRENT_CALL: 'CLEAR_CURRENT_CALL',
  SET_CURRENT_CALL_USER: 'SET_CURRENT_CALL_USER',
  UPDATE_CALL_DURATION: 'UPDATE_CALL_DURATION',
  TOGGLE_CALL_FULLSCREEN: 'TOGGLE_CALL_FULLSCREEN',
  SET_CALL_CONTAINER_POSITION: 'SET_CALL_CONTAINER_POSITION',
  TOGGLE_CALL_AUDIO_MUTE: 'TOGGLE_CALL_AUDIO_MUTE',
  TOGGLE_CALL_VIDEO_MUTE: 'TOGGLE_CALL_VIDEO_MUTE',
  SET_LOCAL_VIDEO: 'SET_LOCAL_VIDEO',
  SET_REMOTE_VIDEO: 'SET_REMOTE_VIDEO',
}
