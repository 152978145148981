import { Text } from 'components/typo'

const FormLabel = ({
  title = '',
  isRequired = false,
  color = 'grey500',
  size = 'small'
}) => (
  <Text
    color={color}
    size={size}
  >
    {title}
    {isRequired && (
      <Text
        lh="inherit"
        color="red"
        tag="span"
      >
        *
      </Text>
    )}
  </Text>
)

export default FormLabel
