import { combineReducers } from 'redux'

import dashboard from './AnalyticDashboard/AnalyticDashboardReducer'
import clinicalIndicators from './AnalyticClinicalIndicators/AnalyticClinicalIndicatorsReducer'
import clinic from './AnalyticClinic/AnalyticClinicReducer'
import root from './AnalyticReducer'

const rootAnalyticReducer = combineReducers({
  root,
  dashboard,
  clinicalIndicators,
  clinic
})

export default rootAnalyticReducer
