export const getClinicAddress = (address = {}) => {
  if (!address) {
    return ''
  }

  const arr = []

  if (address.city) {
    arr.push(`г. ${address.city}`)
  }

  if (address.street) {
    arr.push(`ул. ${address.street}`)
  }

  if (address.building) {
    arr.push(`д. ${address.building}`)
  }

  return arr.join(', ')
}
