import { ReferenceActionTypes } from 'redux/dashboard/Reference/ReferenceTypes'
import { PatientsActionTypes } from 'redux/dashboard/Patients/PatientsTypes'

const INITIAL_STATE = {
  manipulation: null,
  isEdit: false,
}

const referenceReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case ReferenceActionTypes.SET_REFERENCE_IS_EDIT:
    return {
      ...state,
      isEdit: action.payload,
    }
  case ReferenceActionTypes.SET_REFERENCE_EDIT_MANIPULATION:
    return {
      ...state,
      manipulation: action.payload,
    }
  case ReferenceActionTypes.SET_REFERENCE_EDIT_INFO: {
    return {
      ...state,
      isEdit: action.payload.isEdit,
      manipulation: action.payload.manipulation,
    }
  }
  case ReferenceActionTypes.RESET_REFERENCE_EDIT_INFO: {
    return {
      ...state,
      isEdit: null,
      manipulation: null,
    }
  }

  default:
    return state
  }
}

export default referenceReducer
