import { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import styled from 'styled-components'

import { ReactComponent as IconWarning } from 'assets/icons/messages/warning.svg'
import { ReactComponent as IconMessage } from 'assets/icons/messages/message.svg'
import { ReactComponent as IconCheck } from 'assets/icons/messages/check.svg'
import { ReactComponent as IconStar } from 'assets/icons/messages/star.svg'
import { ReactComponent as IconTrash } from 'assets/icons/messages/trash.svg'
import { ReactComponent as IconStop } from 'assets/icons/messages/stop.svg'

import { messageTypes, messageTypesColors } from 'constants/NotifierTypes'

import { setChatActiveUsersType } from 'redux/dashboard/Chat/ChatActions'
import { findChatUser, getChatUserUrl } from 'redux/dashboard/Chat/ChatHelpers'

import { formatDateOrTodayDynamic } from 'helpers/Date'
import { getPatientFolderUrl, getPatientUrl } from 'redux/helpers/PatientHelpers'
import { getTaskUrl } from 'redux/Tasks/TasksHelpers'
import { getRole } from 'helpers/User'
import NotifierCardPatientFile from './cards/NotifierCardPatientFile'
import NotifierChatMessage from './cards/NotifierChatMessage'
import NotifierCardDefault from './cards/NotifierCardDefault'
import NotifierCardPatientInvited from './cards/NotifierCardPatientInvited'
import NotifierCardPatientTaskDone from './cards/NotifierCardPatientTaskDone'
import NotifierCardConsultationRemind from './cards/NotifierCardConsultationRemind'

const NotifierMessageContainer = styled.div`
  display: flex;
  width: 100%;

  .notifier-message {
    width: 100%;
    display: flex;


    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      margin-left: 14px;
      margin-top: 9px;
    }

    &-content {
      width: 100%;
      padding: 12px;
      padding-right: 24px;
      align-self: flex-start;
    }

    &-descr {
      margin-top: 6px;
    }
  }
`
const NotifierMessage = (props) => {
  const {
    message,
    description,
    item = null,
    toastProps,
  } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const { users } = useSelector((state) => state.dashboard.chat)
  const { profile } = useSelector((state) => state.user)
  const { isAdmin } = getRole(profile && profile.role)
  const { type } = toastProps

  const getChatUserFromConversations = useCallback((userVoxId) => !!findChatUser(users, { voximplant_username: userVoxId }), [ users ])

  const handleChatOpen = useCallback((voxId) => {
    const isChatUser = getChatUserFromConversations(voxId)
    history.push(`${getChatUserUrl(voxId)}${!isChatUser ? `?new=true` : ''}`)
  }, [ history, getChatUserFromConversations ])

  const handlePatientOpen = useCallback((userId) => {
    history.push(getPatientUrl(userId))
  }, [ history ])

  const handlePatientTaskOpen = useCallback((userId, createdAt) => {
    if (userId && createdAt) history.push(getTaskUrl(userId, createdAt))
  }, [ history ])

  const handleDocumentOpen = useCallback((userId, partition) => {
    history.push(getPatientFolderUrl(userId, partition))
  }, [ history ])

  const Icon = useMemo(() => {
    switch (type) {
    case messageTypes.ERROR:
      return IconWarning
    case messageTypes.DEFAULT:
    case messageTypes.MESSAGE:
      return IconMessage
    case messageTypes.CHECK:
      return IconCheck
    case messageTypes.TRASH:
      return IconTrash
    case messageTypes.STAR:
      return IconStar
    case messageTypes.STOP:
      return IconStop

    default:
      return null
    }
  }, [ type ])

  const titleColor = useMemo(() => {
    switch (type) {
    case messageTypes.ERROR:
      return messageTypesColors.ERROR

    default:
      return messageTypesColors.DEFAULT
    }
  }, [ type ])

  const body = useMemo(() => {
    const createdTitle = item ? formatDateOrTodayDynamic(item.created_at, ' в ') : ''

    switch (type) {
    case messageTypes.CHAT_MESSAGE: {
      return (
        <NotifierChatMessage
          users={users}
          message={item.message}
          titleColor={titleColor}
        />
      )
    }

    case messageTypes.USER_ADD_FILE: {
      const { user } = item || {}

      return (
        <NotifierCardPatientFile
          user={user}
          author={item.file_author_upload}
          folder={item.folder}
          created={createdTitle}
          onChat={handleChatOpen}
          onDocument={handleDocumentOpen}
          titleColor={titleColor}
        />
      )
    }

    case messageTypes.RESULT_BIND:
    case messageTypes.PATIENT_DOCTOR_BIND: {
      const {
        patient, consultant = {}, bind
      } = item || {}

      return (
        <NotifierCardPatientInvited
          patient={patient}
          consultant={consultant}
          bind={bind}
          created={createdTitle}
          onChat={handleChatOpen}
          onPatient={handlePatientOpen}
          titleColor={titleColor}
          isAdmin={isAdmin}
        />
      )
    }

    case messageTypes.USER_COURSE_EXERCISE:
    case messageTypes.USER_COURSE_MEDICINE:
    case messageTypes.USER_COURSE_ANALYSIS:
    case messageTypes.USER_COURSE_PROCEDURE:
    case messageTypes.USER_COURSE_MANIPULATION:
    case messageTypes.USER_COURSE_CONSULTATION: {
      const {
        patient,
        task,
        remind_task: isRemind,
        time_before_task: timeBefore,
      } = item || {}

      if (isRemind) {
        return (
          <NotifierCardConsultationRemind
            patient={patient}
            createdAt={createdTitle}
            timeBefore={timeBefore}
            onChat={handleChatOpen}
            titleColor={titleColor}
          />
        )
      }

      return (
        <NotifierCardPatientTaskDone
          patient={patient}
          task={task}
          type={item.type}
          titleColor={titleColor}
          onChat={handleChatOpen}
          onTask={handlePatientTaskOpen}
        />
      )
    }

    default:
      return (
        <NotifierCardDefault
          titleColor={titleColor}
          message={message}
          description={description}
        />
      )
    }
  }, [
    users,
    isAdmin,
    titleColor,
    message,
    description,
    type,
    item,
    handleChatOpen,
    handlePatientOpen,
    handlePatientTaskOpen,
    handleDocumentOpen,
  ])

  const handleCardClick = useCallback(() => {
    switch (type) {
    case messageTypes.CHAT_MESSAGE: {
      const { voximplant_username: userVoxName, role } = (item
      && item.message
      && item.message.user
      && item.message.user.userInfo
      && item.message.user.userInfo) || {}

      dispatch(setChatActiveUsersType(role))
      handleChatOpen(item.message.conversation)
      break
    }

    default:
      break
    }
  }, [ type, item, dispatch, handleChatOpen ])

  return (
    <NotifierMessageContainer
      onClick={handleCardClick}
      className={cn('notifier-message', { [`--${toastProps.type}`]: toastProps && toastProps.type })}
    >
      {Icon && (
        <div className="notifier-message-icon">
          <Icon fill={titleColor} />
        </div>
      )}
      {body}
    </NotifierMessageContainer>
  )
}

export const setMessage = (props) => <NotifierMessage {...props} />

export default NotifierMessage
