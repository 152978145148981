import FormGroup from './FormGroup'
import FormTitle from './FormTitle'

const FormGroupBlock = ({
  title = '',
  children,
  mb = 'mb-8'
}) => (
  <FormGroup className={mb}>
    <FormTitle title={title} />
    {children}
  </FormGroup>
)

export default FormGroupBlock
