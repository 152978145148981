import {
  useState,
  useEffect,
  // forwardRef
} from 'react'
import CardImage from 'components/base/CardImage'

// import placeholder from 'assets/icons/round-placeholder.svg'

export const CardImageLazy = ({
  src,
  alt,
  isActive = true,
  className = '',
  contentWidth,
  contentHeight,
  onError,
  ...props
}) => {
  const [ imageSrc, setImageSrc ] = useState()
  const [ imageRef, setImageRef ] = useState()

  useEffect(() => {
    let observer
    let didCancel = false

    if (imageRef && src && imageSrc !== src) {
      if (IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel
                && (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                if (src && isActive) {
                  setImageSrc(src)
                }

                if (isActive) {
                  observer.unobserve(imageRef)
                }
              }
            })
          },
          {
            threshold: 0.01,
            rootMargin: '90%',
          }
        )
        observer.observe(imageRef)
      }
      else {
        // Old browsers fallback
        setImageSrc(src)
      }
    }
    else if (!src && imageSrc) {
      setImageSrc(null)
    }

    return () => {
      didCancel = true
      if (observer && observer.unobserve) {
        observer.unobserve(imageRef)
      }
    }
  }, [ src, imageSrc, imageRef, isActive ])

  return (
    <div
      style={{
        width: contentWidth || '',
        height: contentHeight || ''
      }}
      className={className}
      ref={setImageRef}
    >
      <CardImage
        src={imageSrc}
        background={!!imageSrc}
        alt={alt}
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        onError={onError}
        {...props}
      />
    </div>
  )
}

export default CardImageLazy
