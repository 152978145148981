import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { PatientsActionTypes } from 'redux/dashboard/Patients/PatientsTypes'
import { PatientActionTypes } from 'redux/dashboard/Patient/PatientTypes'
import StorageService from 'services/StorageService'
import { ListLayoutTypes } from 'constants/ListTypes'
import { ReferenceManipulationsTypes } from 'redux/dashboard/Reference/Manipulations/ReferenceManipulationsTypes'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,
  patientsCountExpired: 0,
  patientsCountIsAccepted: 0,
  layout: StorageService.getStructureLayout('patients') || ListLayoutTypes.TABLE,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getPatientFilters() || {},

  error: null,
}

const referenceManipulationsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case ReferenceManipulationsTypes.FETCH_REFERENCE_MANIPULATIONS_LIST_SUCCESS: {
    return {
      ...state,
      list: action.payload
    }
  }

  case ReferenceManipulationsTypes.CLEAR_REFERENCE_MANIPULATIONS_LIST:
    return {
      ...state,
      list: null
    }

  case ReferenceManipulationsTypes.SET_REFERENCE_MANIPULATIONS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case ReferenceManipulationsTypes.SET_REFERENCE_MANIPULATIONS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case ReferenceManipulationsTypes.SET_REFERENCE_MANIPULATIONS_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }

  case ReferenceManipulationsTypes.ADD_REFERENCE_MANIPULATIONS_LIST:
    return {
      ...state,
      list: state.list.concat(action.payload.results),
      offset: state.offset + 40,
    }

  case ReferenceManipulationsTypes.SET_FETCHING_REFERENCE_MANIPULATIONS_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case ReferenceManipulationsTypes.DELETE_REFERENCE_MANIPULATIONS_LIST_ITEM:
    return {
      ...state,
      list: state.list.filter((item) => item.id !== action.payload)
    }

  case ReferenceManipulationsTypes.SET_FETCHING_REFERENCE_MANIPULATIONS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case ReferenceManipulationsTypes.FETCH_REFERENCE_MANIPULATIONS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case ReferenceManipulationsTypes.CLEAR_REFERENCE_MANIPULATIONS_SEARCH:
    return {
      ...state,
      searchList: []
    }

  case ReferenceManipulationsTypes.SET_REFERENCE_MANIPULATIONS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  default:
    return state
  }
}

export default referenceManipulationsReducer
