import {
  useCallback, useEffect, useRef
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Websocket from 'react-websocket'
import RoutesMain from 'RoutesMain'
import Notifier from 'components/Notifier'
import ModalWrapper from 'components/base/ModalWrapper'

import { setBrowserScrollWidth, setNotification } from 'redux/System/SystemActions'
import { addNotificationsCount, setNewNotificationsCount } from 'redux/Notifications/NotificationsActions'

import getScrollbarWidth from 'utils/getScrollbarWidth'
import {
  chatLogin, fetchGetChatUserAuth, fetchGetChatUsersByNames
} from 'redux/dashboard/Chat/ChatActions'
import { updateChatUsers } from 'redux/dashboard/Messanger/MesengerConversationsActions'
import { getChatUserNameFromUri } from 'redux/dashboard/Chat/ChatHelpers'
import { UserRolesTypes } from 'constants/UserTypes'
import { fetchClinicAsync } from 'redux/Clinics/ClinicsActions'
import { setUserPlace } from 'redux/User/UserActions'
import CallContainer from 'components/Call/CallContainer'
import StorageService from 'services/StorageService'
import { getInitialData, getInitialVox } from 'redux/dashboard/Messanger'
// import { messageTypes } from 'constants/NotifierTypes'

const changeTitle = function() {
  const { title } = document
  document.title = (title === 'Новое Сообщение' ? 'Urban Medic' : 'Новое Сообщение')
}

const App = ({ user }) => {
  const dispatch = useDispatch()
  const timerId = useRef(null)

  const {
    profile = null,
    chat = null,
    place = null,
  } = useSelector((state) => state.user)
  const {
    chatServiceInit = false,
    conversations = null,
    user: chatUser = null,
    users: chatUsers = [],
    chatUserLogin = false,
    unreaded = [],
    support = null
  } = useSelector((state) => state.dashboard.chat)

  const isAuth = !!(user || profile)

  useEffect(() => {
    dispatch(setBrowserScrollWidth(getScrollbarWidth()))

    window.addEventListener('beforeunload', () => {
      StorageService.removePatientFilters()
      StorageService.removeClinicRecordFilterSearch()
      StorageService.removeClinicRecordFilters()
    })
  }, [ dispatch ])

  useEffect(() => {
    if (profile && !chat) {
      if (profile.role !== UserRolesTypes.SUPERVISOR) dispatch(fetchGetChatUserAuth(profile.id))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ profile, chat ])

  useEffect(() => {
    if (profile && profile.role === UserRolesTypes.CONSULTANT && !place) {
      const clinicId = profile && profile.consultant_info && profile.consultant_info.clinic.id

      if (clinicId) {
        dispatch(fetchClinicAsync(clinicId))
          .then((clinic) => {
            dispatch(setUserPlace(clinic.place))
          })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ profile ])

  useEffect(() => {
    if (isAuth && !chatServiceInit) getInitialVox()
  }, [ isAuth, chatServiceInit ])

  useEffect(() => {
    if (isAuth && chat && chatServiceInit && profile) {
      dispatch(chatLogin(chat, profile.role))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isAuth, chat, profile && profile.role, chatServiceInit ])

  // @init Messenger
  useEffect(() => {
    if (isAuth && chatUserLogin && profile.role) {
      getInitialData(dispatch, profile.role)
    }
  }, [ chatUserLogin, profile && profile.role, isAuth ])

  useEffect(() => {
    if (
      ((support || (profile && profile.role === UserRolesTypes.SUPPORT)))
      && chatUsers
      && chatUsers.length
      && conversations
      && conversations.length
    ) {
      const usersNames = chatUsers.map((user) => getChatUserNameFromUri(user.userName))

      dispatch(fetchGetChatUsersByNames(usersNames))
        .then((appUsers) => {
          const mappedUsers = chatUsers.map((user) => ({
            ...user,
            userInfo: appUsers.find((u) => u.voximplant_username === getChatUserNameFromUri(user.userName)),
            conversation: conversations.find((c) => {
              if (c.participants) {
                return c.participants.some((p) => p.userId === user.userId)
              }

              return false
            }) || null,
          }))
          dispatch(updateChatUsers(mappedUsers))
        })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ chatUsers.length, conversations.length, support ])

  const renderWS = useCallback(() => {
    const wsProtocol = window.location.protocol.includes('https') ? 'wss' : 'wss'

    const handleNotificationsMessages = (message) => {
      const { event_type: messageType = '', message: messageBody = {} } = JSON.parse(message)
      const { data } = messageBody
      if (process.env.NODE_ENV === 'development') { // DEBUG websocket notifications
        console.info('ws notification', {
          messageType,
          messageBody
        })
      }

      switch (messageType || messageBody.type) {
      case 'amount_unread_messages':
        dispatch(setNewNotificationsCount(messageBody))
        break
      case '':
      case 'user_message':
        dispatch(addNotificationsCount())
        dispatch(setNotification({
          type: data.type,
          item: data
        }))
        break

      default:
        break
      }
    }

    return (
      <Websocket
        url={`${wsProtocol}://${process.env.REACT_APP_HOST}/ws/notifications/?token=${StorageService.getToken()}`}
        onMessage={handleNotificationsMessages}
      />
    )
  }, [ dispatch ])

  return (
    <div className="app">
      <RoutesMain isAuth={isAuth} />
      <Notifier />
      <ModalWrapper />
      {isAuth && (
        <>
          <CallContainer />
          {renderWS()}
        </>
      )}
    </div>
  )
}

export default App
