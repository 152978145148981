import { ButtonRound } from 'components/base'
import { ReactComponent as IconValue } from 'assets/icons/info.svg'
import { useState, useMemo } from 'react'
import ModalSidebarWrapper from 'components/modals/ModalSidebarWrapper'
import ModalInfoKnowledgeBase from 'components/modals/ModalInfoKnowledgeBase'

const ButtonInfoKnowledgeBase = ({
  size = 'xs',
  defaultStyle = 'settings',
  isHovered = false,
  isFetching = false,
  br = 'round',
  fillHover = 'grey500',
  typeInfo = null,
  itemInfo = null,
}) => {
  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const data = useMemo(() => ({
    item: itemInfo,
    type: typeInfo,
  }), [ itemInfo, typeInfo ])

  return (
    <>
      <ButtonRound
        defaultStyle={defaultStyle}
        size={size}
        renderIcon={() => <IconValue />}
        onClick={() => setIsOpenModal(true)}
        isHovered={isHovered}
        isFetching={isFetching}
        br={br}
        fillHover={fillHover}
      />

      <ModalSidebarWrapper
        show={isOpenModal}
        closeModal={() => setIsOpenModal(false)}
        component={ModalInfoKnowledgeBase}
        direction="y"
        data={data}
      />
    </>

  )
}

export default ButtonInfoKnowledgeBase
