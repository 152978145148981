/* eslint-disable no-promise-executor-return */
import { getQueryStringFromObject } from 'helpers/Query'
import ApiService from 'services/ApiService'
import StorageService from 'services/StorageService'

import { NotificationsActionsTypes } from './NotificationsTypes'

export const setNewNotificationsCount = (payload) => ({
  type: NotificationsActionsTypes.SET_NEW_NOTIFICATIONS_COUNTS,
  payload
})

export const addNotificationsCount = () => ({ type: NotificationsActionsTypes.ADD_NEW_NOTIFICATION_COUNT })

export const setFetchingNotifications = (value) => ({
  type: NotificationsActionsTypes.SET_FETCHING_NOTIFICATIONS,
  payload: value,
})

export const fetchNotificationError = (value) => ({
  type: NotificationsActionsTypes.FETCH_NOTIFICATIONS_ERROR,
  payload: value,
})

export const fetchNotificationSuccess = (value) => ({
  type: NotificationsActionsTypes.FETCH_NOTIFICATIONS_SUCCESS,
  payload: value,
})

export const AddNewNotification = (data) => ({
  type: NotificationsActionsTypes.ADD_NEW_NOTIFICATION,
  payload: data,
})

export const RemoveNewNotification = (id) => ({
  type: NotificationsActionsTypes.REMOVE_NEW_NOTIFICATIONS,
  payload: id,
})

export const setNotificationCount = (payload) => ({
  type: NotificationsActionsTypes.SET_NOTIFICATIONS_COUNT,
  payload
})

export const clearNotifications = () => ({ type: NotificationsActionsTypes.CLEAR_NOTIFICATIONS })

export const setNotificationSettings = (payload) => {
  StorageService.setNotificationsSettings(payload)

  return {
    type: NotificationsActionsTypes.SET_NOTIFICATIONS_SETTINGS,
    payload
  }
}

export const fetchNotifications = ({ cancelTokenSource = null, ...queryProps }) => (dispatch) => {
  dispatch(setFetchingNotifications(true))

  const queryString = `?${getQueryStringFromObject(queryProps)}`

  return new Promise((res, rej) => ApiService.apiCall({
    url: `${ApiService.paths.notifications.ROOT}${queryString}`,
    isToken: true,
    cancelTokenSource
  })
    .then((response) => {
      dispatch(fetchNotificationSuccess(response.results))
      dispatch(setNotificationCount(response.count))
      dispatch(setFetchingNotifications(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingNotifications(false))
      dispatch(fetchNotificationError(false))
      return rej(error)
    }))
}

export const setFetchingReadNotification = (value) => ({
  type: NotificationsActionsTypes.SET_FETCHING_READ_NOTIFICATION,
  payload: value,
})

export const fetchReadNotificationError = (value) => ({
  type: NotificationsActionsTypes.FETCH_READ_NOTIFICATION_ERROR,
  payload: value,
})

export const fetchReadNotificationSuccess = (value) => ({
  type: NotificationsActionsTypes.FETCH_READ_NOTIFICATION_SUCCESS,
  payload: value,
})

export const fetchReadNotification = (notificationId) => (dispatch) => {
  dispatch(setFetchingReadNotification(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data: { ids: [ notificationId ] },
    url: ApiService.paths.notifications.READ,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchReadNotificationSuccess(notificationId))
      dispatch(setFetchingReadNotification(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingReadNotification(false))
      dispatch(fetchReadNotificationError(false))
      return rej(error)
    }))
}

export const setFetchingReadAllNotification = (value) => ({
  type: NotificationsActionsTypes.SET_FETCHING_READ_ALL_NOTIFICATION,
  payload: value,
})

export const fetchReadAllNotificationError = (value) => ({
  type: NotificationsActionsTypes.FETCH_READ_ALL_NOTIFICATION_ERROR,
  payload: value,
})

export const fetchReadAllNotificationSuccess = () => ({ type: NotificationsActionsTypes.FETCH_READ_ALL_NOTIFICATION_SUCCESS })

export const fetchReadAllNotifications = () => (dispatch) => {
  dispatch(setFetchingReadAllNotification(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data: {},
    url: ApiService.paths.notifications.READ_ALL,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchReadAllNotificationSuccess())
      dispatch(setFetchingReadAllNotification(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingReadAllNotification(false))
      dispatch(fetchReadAllNotificationError())
      return rej(error)
    }))
}
