/* eslint-disable no-promise-executor-return */

import ApiService from 'services/ApiService'
import VoxService from 'services/Voximplant/vox.service'

import { ChatActionTypes } from 'redux/dashboard/Chat/ChatTypes'
import store from 'redux/store/configureStore'

import MessengerService from 'services/Voximplant/messenger.service'
import {
  createConversation,
  TYPE_CONVERSATION,
  updateChatConversation,
  updateCurrentConversationId
} from 'redux/dashboard/Messanger/MesengerConversationsActions'
import { sendNewMessage } from 'redux/dashboard/Messanger/MessengerMessagesActions'
import { getUserFullName } from 'helpers/User'

const ERROR_MESSAGES = {
  404: 'Invalid username or password',
  401: 'Invalid token',
  500: 'Internal sever error',
  701: 'Invalid token',
}

export const setChatMessage = (value) => ({
  type: ChatActionTypes.SET_CHAT_MESSAGE,
  payload: value,
})

export const setChatActiveUsersType = (value) => ({
  type: ChatActionTypes.SET_ACTIVE_USERS_TYPE,
  payload: value,
})

export const addChatMessage = (message) => ({
  type: ChatActionTypes.ADD_CHAT_MESSAGE,
  payload: message,
})

export const updateChatMessageUploaded = ({ uploadId, updateData }) => ({
  type: ChatActionTypes.UPDATE_CHAT_MESSAGE_UPLOADED,
  payload: {
    uploadId,
    data: updateData
  }
})

export const chatDeleteMessageEvent = (e) => ({
  type: ChatActionTypes.DELETE_MESSAGE_EVENT_UPDATE,
  payload: e
})

export const setActiveChatId = (chatId) => ({
  type: ChatActionTypes.SET_ACTIVE_CHAT_ID,
  payload: chatId,
})

export const setChatUsersSearch = (value) => ({
  type: ChatActionTypes.SET_USERS_SEARCH,
  payload: value,
})

export const setChatOppositeUser = (value) => ({
  type: ChatActionTypes.SET_OPPOSITE_USER,
  payload: value,
})

const setFetchingCreateChatUser = (value) => ({
  type: ChatActionTypes.SET_FETCHING_CREATE_CHAT_USER,
  payload: value,
})

const fetchCreateChatUserSuccess = (payload) => ({
  type: ChatActionTypes.FETCH_CREATE_CHAT_USER_SUCCESS,
  payload
})

export const updateConversationsUnreaded = (payload) => ({
  type: ChatActionTypes.UPDATE_CONVERSATIONS_UNREADED,
  payload
})

export const setChatPageActive = (payload) => ({
  type: ChatActionTypes.SET_CHAT_PAGE_ACTIVE,
  payload
})

const fetchCreateChatUserError = () => ({ type: ChatActionTypes.FETCH_CREATE_CHAT_USER_ERROR })

export const setChatTotalLastSeq = (payload) => ({
  type: ChatActionTypes.UPDATE_CHAT_TOTAL_LAST_SEQ,
  payload
})

export const setChatLastSeq = (payload) => ({
  type: ChatActionTypes.UPDATE_CHAT_LAST_SEQ,
  payload
})

export const fetchCreateChatUser = (user) => (dispatch) => {
  dispatch(setFetchingCreateChatUser(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.chat.ROOT,
    data: { user_display_name: getUserFullName(user) },
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchCreateChatUserSuccess(response))
      dispatch(setFetchingCreateChatUser(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchCreateChatUserError())
      dispatch(setFetchingCreateChatUser(false))
      return rej(error)
    }))
}

const setFetchingGetChatUserAuth = (value) => ({
  type: ChatActionTypes.SET_FETCHING_GET_CHAT_USER_AUTH,
  payload: value,
})

const fetchGetChatUserAuthSuccess = (payload) => ({
  type: ChatActionTypes.FETCH_GET_CHAT_USER_AUTH_SUCCESS,
  payload
})

const fetchGetChatUserAuthError = () => ({ type: ChatActionTypes.FETCH_GET_CHAT_USER_AUTH_ERROR })

export const fetchGetChatUserAuth = (userId) => (dispatch) => {
  dispatch(setFetchingGetChatUserAuth(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.chat.GET_AUTH(userId),
    isToken: true,
    noErrorMessage: true
  })
    .then((response) => {
      dispatch(fetchGetChatUserAuthSuccess(response))
      dispatch(setFetchingGetChatUserAuth(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchGetChatUserAuthError())
      dispatch(setFetchingGetChatUserAuth(false))
      return rej(error)
    }))
}

const setFetchingGetChatUsersByNames = (value) => ({
  type: ChatActionTypes.SET_FETCHING_GET_CHAT_USERS_BY_NAMES,
  payload: value,
})

const fetchGetChatUsersByNamesSuccess = (payload) => ({
  type: ChatActionTypes.FETCH_GET_CHAT_USERS_BY_NAMES_SUCCESS,
  payload
})

const fetchGetChatUsersByNamesError = () => ({ type: ChatActionTypes.FETCH_GET_CHAT_USERS_BY_NAMES_ERROR })

export const fetchGetChatUsersByNames = (names = []) => (dispatch) => {
  dispatch(setFetchingGetChatUsersByNames(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.chat.MY_CHAT_USERS,
    isToken: true,
    data: { voximplant_usernames: names }
  })
    .then((response) => {
      dispatch(fetchGetChatUsersByNamesSuccess(response))
      dispatch(setFetchingGetChatUsersByNames(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchGetChatUsersByNamesError())
      dispatch(setFetchingGetChatUsersByNames(false))
      return rej(error)
    }))
}

const setFetchingGetChatSupport = (value) => ({
  type: ChatActionTypes.SET_FETCHING_GET_CHAT_SUPPORT,
  payload: value,
})

const fetchGetChatSupportSuccess = (payload) => ({
  type: ChatActionTypes.FETCH_GET_CHAT_SUPPORT_SUCCESS,
  payload
})

const fetchGetChatSupportError = () => ({ type: ChatActionTypes.FETCH_GET_CHAT_SUPPORT_ERROR })

export const fetchGetChatSupport = () => (dispatch) => {
  dispatch(setFetchingGetChatSupport(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'GET',
    url: ApiService.paths.chat.SUPPORT_USER,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetChatSupportSuccess(response.results && response.results[0]))
      dispatch(setFetchingGetChatSupport(false))
      return res(response)
    })
    .catch((error) => {
      dispatch(fetchGetChatSupportError(error))
      dispatch(setFetchingGetChatSupport(false))
      return rej(error)
    }))
}

export const setChatServiceInit = (value) => ({
  type: ChatActionTypes.CHAT_SERVICE_INIT,
  payload: value,
})

export const setChatUserLoginStatus = (newStatus) => ({
  type: ChatActionTypes.CHAT_USER_LOGIN_SUCCESS,
  payload: newStatus
})

export const chatLoginSuccess = (data) => (dispatch) => {
  dispatch(setChatUserLoginStatus(true))
  VoxService.get().addInboundCallListener()
}

export const tryRefreshTokens = (loginData) => (dispatch) => {
  VoxService.refreshTokens(loginData.password, store.dashboard.chat.refreshToken)
    .then((response) => {
      dispatch(chatLoginSuccess(response.tokens))
    })
    .catch((error) => {
      dispatch('onError', {
        error,
        loginData
      })
    })
}

export const chatLoginError = ({ error, loginData }) => (dispatch) => {
  // @ts-ignore
  dispatch({
    type: 'updateAuthError',
    payload: ERROR_MESSAGES[error.code]
  })

  if (error.code === 701) {
    return dispatch(tryRefreshTokens(loginData))
  }

  // voxService.disconnect()
}

export const chatLogin = (loginData, role) => (dispatch) => {
  VoxService.get().onLogin(loginData)
    .then((data) => {
      // console.log('login chat user success', data) // DEBUG
      dispatch(chatLoginSuccess(data.tokens))
    })
    .catch((error) => {
      // console.log('login chat user error', error) // DEBUG
      dispatch(chatLoginError({
        error,
        loginData
      }))
    })
}

export const relogin = async () => (dispatch) => {
  const loginData = store.getState().user.chat
  dispatch(chatLogin(loginData))
}

export const clearStore = () => (dispatch) => {
  dispatch({ type: 'reset' })
  dispatch({
    type: 'conversations/reset',
    payload: { root: true }
  })
  dispatch({
    type: 'reset',
    payload: { root: true }
  })
}

export const createConversationAsync = (userName) => (dispatch) => MessengerService
  .get()
  .getUserByName(userName)
  .then(({ user }) => dispatch(createConversation({
    type: TYPE_CONVERSATION.direct,
    usersId: Number(user.userId),
  })))

export const createConversationAsyncTest = (userName) => (dispatch) => MessengerService
  .get()
  .getUserByName(userName)
  .then((responce) => {
    console.log(responce)
    dispatch(createConversation({
      type: TYPE_CONVERSATION.direct,
      usersId: Number(responce.user.userId),
    }))
  })

export const deleteMessageAction = (message) => (dispatch) => {
  const { conversationMessages, currentConversation } = store.getState().dashboard.chat
  const last = conversationMessages.slice(-1)[0]
  const isNew = !last.message
  if (isNew) {
    if (last.uuid === message.uuid) {
      if (conversationMessages.length === 1) {
        const newCustomData = { ...currentConversation.customData }
        delete newCustomData.lastMessage
        currentConversation.setCustomData({ ...newCustomData })
      }
      else {
        currentConversation.setCustomData({
          ...currentConversation.customData,
          lastMessage: conversationMessages[conversationMessages.length - 2].message ? conversationMessages[conversationMessages.length - 2].message : conversationMessages[conversationMessages.length - 2]
        })
      }
    }
  }
  else if (last.message.uuid === message.uuid) {
    if (conversationMessages.length === 1) {
      const newCustomData = { ...currentConversation.customData }
      delete newCustomData.lastMessage
      currentConversation.setCustomData({ ...newCustomData })
    }
    else {
      currentConversation.setCustomData({
        ...currentConversation.customData,
        lastMessage: conversationMessages[conversationMessages.length - 2].message ? conversationMessages[conversationMessages.length - 2].message : conversationMessages[conversationMessages.length - 2]
      })
    }
  }
}

export const sendChatMessage = (props) => (dispatch) => {
  const {
    data,
    currentConversation,
    userName,
    history,
    setFetching,
  } = props

  if (!currentConversation) {
    if (setFetching) {
      setFetching(true)
    }

    return dispatch(createConversationAsync(userName))
      .then(({ conversation }) => {
        if (setFetching) {
          setFetching(false)
        }

        if (!currentConversation && history) {
          history.replace({ search: '' })
        }

        dispatch(updateChatConversation(conversation))
        dispatch(updateCurrentConversationId(conversation.uuid))
        dispatch(sendNewMessage(conversation, data))
      })
      .catch(() => {
        if (setFetching) {
          setFetching(false)
        }
      })
  }

  return dispatch(sendNewMessage(currentConversation, data))
}

export const setChatHistory = (history) => ({
  type: ChatActionTypes.CHAT_HISTORY_SET,
  payload: history
})

export const setChatUnread = (unreaded) => ({
  type: ChatActionTypes.CHAT_UNREAD_SET,
  payload: unreaded
})

export const updateChatUnread = (unreaded) => ({
  type: ChatActionTypes.CHAT_UNREAD_UPDATE,
  payload: unreaded
})

export const readChatUnread = (seq) => ({
  type: ChatActionTypes.CHAT_UNREAD_READ,
  payload: seq
})

export const setChatMessageEdit = (message) => ({
  type: ChatActionTypes.CHAT_SET_MESSAGE_EDIT,
  payload: message
})
