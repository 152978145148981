import {
  useCallback,
  useState,
  useMemo,
  forwardRef
} from 'react'
import styled from 'styled-components'
import cn from 'classnames'

// components
import { Text } from 'components/typo'
import FormInputDropdown from 'components/form/FormInputDropdown'

import OutsideAlerter from 'utils/OutsideAlerter'
// import FormError from './FormError'

const DropdownContainer = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);

  & > * {
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  .dropdown-menu {
    max-width: 100%;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    z-index: 12;
    margin-top: 2px;
    box-shadow: 0px 6px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    &_fixed_width {
      max-width: fit-content;
    }
    &_off {
      display: none;
    }
  }

  &.--p-left .dropdown-menu {
    right: unset;
    left: 0;
  }

  &.--p-right .dropdown-menu {
    left: unset;
    right: 0;
  }

  &.--s-field {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.transparent};
    transition: background-color 0.3s ease-in-out;

    input {
      padding: 0 ${({ theme }) => theme.spacers[4]}px;
    }

    .toggler {
      right: ${({ theme }) => theme.spacers[4]}px;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.white10};
    }
  }

  &.--s-transparent {
    width: 100%;
    // transition: background-color 0.3s ease-in-out;

    input {
      box-shadow: unset !important;
      background-color: ${({ theme }) => theme.colors.transparent} !important;
      padding: 0px;
      height: ${({ $fieldHeight }) => $fieldHeight};
      line-height: 1;
    }

    .toggler {
      right: 0px;
    }

    // &:hover {
    //   background-color: ${({ theme }) => theme.colors.white10};
    // }
  }
`

const FormDropdown = forwardRef((props, ref) => {
  const {
    children,
    name = '',
    value = '',
    renderDropdown = () => {},
    // isError = false,
    // isTouched = false,
    isPadded = false,
    defaultStyle = '',
    label = '',
    isDisabled = false,
    onClear = () => {},
    readOnly = false,
    positionLeft = false,
    positionRight = false,
    onClose = () => {},
    onChange = () => {},
    onBlur = () => {},
    fieldHeight = '',
    placeholderColor = '',
    color = '',
    bgColor = '',
    fs = '',
    small = false,
    ...otherProps
  } = props

  const [ show, setShow ] = useState(false)

  const handleDropdown = useCallback((value) => {
    setShow(value)
  }, [ setShow ])

  const handleDropdownOpen = useCallback(() => {
    handleDropdown(true)
  }, [ handleDropdown ])

  const handleDropdownClose = useCallback(() => {
    handleDropdown(false)
    onClose()
  }, [ onClose, handleDropdown ])

  const inputHandler = useMemo(
    () => (show
      ? handleDropdownClose
      : handleDropdownOpen),
    [ show,
      handleDropdownClose,
      handleDropdownOpen
    ]
  )

  return (
    <DropdownContainer
      $fieldHeight={fieldHeight}
      className={cn('w-dropdown', {
        '--padded': isPadded,
        [`--s-${defaultStyle}`]: defaultStyle,
        '--p-left': positionLeft,
        '--p-right': positionRight,
      })}
    >
      <OutsideAlerter onClickOutside={handleDropdownClose}>
        {label && (
          <Text
            color="grey500"
            size="small"
            className="mb-1"
          >
            {label}
          </Text>
        )}
        <FormInputDropdown
          {...otherProps}
          ref={ref}
          placeholderColor={placeholderColor}
          color={color}
          fs={fs}
          isActive={show}
          name={name}
          value={value}
          onClick={!isDisabled ? inputHandler : () => {}}
          readOnly={isDisabled || readOnly}
          onChange={onChange}
          onBlur={onBlur}
          onClear={onClear}
          isPadded={isPadded}
          autoComplete="off"
          bgColor={bgColor}
        />
        {/* {isError && isTouched && <FormError>{isError}</FormError>} */}
        <div className={cn('dropdown-menu', { 'dropdown-menu_off': !show || isDisabled }, 'dropdown-menu_fixed_width')}>
          {renderDropdown({
            closeDropdown: handleDropdownClose,
            isShow: show
          })}
        </div>
      </OutsideAlerter>
    </DropdownContainer>
  )
})

FormDropdown.displayName = 'FormDropdown'

export default FormDropdown
