import { ReactComponent as IconCam } from 'assets/icons/video-cam-fill.svg'
import { ReactComponent as IconCamMute } from 'assets/icons/video-cam-mute.svg'

import ButtonRound from 'components/base/ButtonRound'

const ButtonVideo = ({
  defaultStyle = 'acsent',
  br = '8px',
  isMute = false,
  ...props
}) => (
  <ButtonRound
    br={br}
    defaultStyle={defaultStyle}
    renderIcon={() => (isMute ? <IconCamMute /> : <IconCam />)}
    {...props}
  />
)

export default ButtonVideo
