import { CallActionTypes } from 'redux/Call/CallTypes'
import VoxService from 'services/Voximplant/vox.service'

export const setCurrentCall = (call) => ({
  type: CallActionTypes.SET_CURRENT_CALL,
  payload: call
})

export const clearCurrentCall = () => ({ type: CallActionTypes.CLEAR_CURRENT_CALL })

export const setCurrentCallUser = (user) => ({
  type: CallActionTypes.SET_CURRENT_CALL_USER,
  payload: user
})

export const setCurrentCallConnected = () => ({ type: CallActionTypes.SET_CURRENT_CALL_CONNECTED })

export const updateCallDuration = (duration) => ({
  type: CallActionTypes.UPDATE_CALL_DURATION,
  payload: duration
})

export const startCallingDirect = (props) => () => {
  VoxService.get().startCall(props)
}

export const toggleCallFullscreen = () => ({ type: CallActionTypes.TOGGLE_CALL_FULLSCREEN })

export const toggleCallMicMute = (payload) => ({
  type: CallActionTypes.TOGGLE_CALL_AUDIO_MUTE,
  payload
})

export const toggleCallVideoMute = (payload) => ({
  type: CallActionTypes.SET_LOCAL_VIDEO,
  payload
})

export const setLocalVideo = (value) => ({
  type: CallActionTypes.SET_LOCAL_VIDEO,
  payload: value
})

export const setRemoteVideo = (value) => ({
  type: CallActionTypes.SET_REMOTE_VIDEO,
  payload: value
})

export const setCallContainerPosition = (payload) => ({
  type: CallActionTypes.SET_CALL_CONTAINER_POSITION,
  payload,
})
