export const ReferenceAnalysesTypes = {
  SET_FETCHING_REFERENCE_ANALYSES_LIST: 'SET_FETCHING_REFERENCE_ANALYSES_LIST',
  FETCH_REFERENCE_ANALYSES_LIST_SUCCESS: 'FETCH_REFERENCE_ANALYSES_LIST_SUCCESS',
  FETCH_REFERENCE_ANALYSES_LIST_ERROR: 'FETCH_REFERENCE_ANALYSES_LIST_ERROR',
  CLEAR_REFERENCE_ANALYSES_LIST: 'CLEAR_REFERENCE_ANALYSES_LIST',
  SET_REFERENCE_ANALYSES_LIST_LAST_SEARCH: 'SET_REFERENCE_ANALYSES_LIST_LAST_SEARCH',
  SET_REFERENCE_ANALYSES_LIST_COUNT: 'SET_REFERENCE_ANALYSES_LIST_COUNT',
  ADD_REFERENCE_ANALYSES_LIST: 'ADD_REFERENCE_ANALYSES_LIST',

  SET_FETCHING_REFERENCE_ANALYSES_SEARCH: 'SET_FETCHING_REFERENCE_ANALYSES_SEARCH',
  FETCH_REFERENCE_ANALYSES_SEARCH_SUCCESS: 'FETCH_REFERENCE_ANALYSES_SEARCH_SUCCESS',
  FETCH_REFERENCE_ANALYSES_SEARCH_ERROR: 'FETCH_REFERENCE_ANALYSES_SEARCH_ERROR',
  CLEAR_REFERENCE_ANALYSES_SEARCH: 'CLEAR_REFERENCE_ANALYSES_SEARCH',

  SET_ANY_REFERENCE_ANALYSES: 'SET_ANY_REFERENCE_ANALYSES',
  SET_REFERENCE_ANALYSES_LIST: 'SET_REFERENCE_ANALYSES_LIST',

  SET_REFERENCE_ANALYSES_SEARCH_VALUE: 'SET_REFERENCE_ANALYSES_SEARCH_VALUE',
}
