import * as PathsTypes from 'constants/PathsTypes'

export const checkCorrectUrl = (string) => {
  if (!string) return string
  if (string.indexOf('http') === 0 || string.indexOf('static') >= 0 || string.indexOf(';base64') >= 0 || string.indexOf('blob:') >= 0) return string
  return `${PathsTypes.APP_API_MEDIA}${string}`
}

export const createAndDownloadLink = (href, filename, isBlank = true) => {
  const link = document.createElement('a')
  link.href = href
  link.download = filename

  if (isBlank) {
    link.setAttribute(
      'target',
      `_blank`,
    )
  }

  document.body.appendChild(link)
  link.click()
  link.parentNode.removeChild(link)
}

export function downloadXlsxFile(response, fileName = 'без названия') {
  const file = new Blob([ response ], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(file)
  link.target = '_blank'
  link.download = `${fileName}.xlsx`
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
