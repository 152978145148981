import PropTypes from 'prop-types'
import { useCallback, forwardRef } from 'react'
import { useField } from 'formik'

import FormField from './FormField'
import FormFieldPassword from './FormFieldPassword'
import FormFieldSearch from './FormFieldSearch'

const FormikField = forwardRef(({
  className = '',
  tag = '',
  name = '',
  label = '',
  value = '',
  type = 'text',
  placeholder = '',
  icon,
  defaultStyle = '',
  minHeight = '',
  minWidth = '',
  maxWidth = '',
  inputPadding = '',
  size = 'normal',
  numbers = false,
  letters = false,
  readOnly = false,
  addString = null,
  max = null,
  min = null,
  minLength = null,
  maxLength = null,
  minNumber = null,
  maxNumber = null,
  autoFocus = false,
  iconBefore = false,
  noMessage = false,
  autoComplete = '',
  customComponent = '',
  mask = '',
  isRequired = false,
  onClear = null,
  noBorders,
  onBlur = null,
  onFocus = null,
  isPadded = true,
  isAutoResize = null,
  isAutoFocus = null,
  align = '',
  bgColor = '',
  onKeyDown = () => {},
  onClick = () => {},
  onChange = () => {},
  onPaste = () => {},
  labelFluid = false,
  fieldType = null,
  fs = 'normal',
  maskChar,
  isFirstUppercase = true,
  isError: isErrorImmediate = null,
}, ref) => {
  const [ field, meta ] = useField({
    name,
    type,
    value
  })
  const { error, touched } = meta
  const isError = isErrorImmediate === null ? !!(error && touched) : isErrorImmediate

  const handleFocus = useCallback((e) => {
    if (!readOnly) {
      if (onFocus) {
        onFocus(e.target.value, e.target.name)
      }

      return e
    }
  }, [ onFocus, readOnly ])

  const handleBlur = useCallback((e) => {
    if (!readOnly) {
      if (onBlur) {
        onBlur(e.target.value, e.target.name)
      }

      field.onBlur(e)
    }

    return e
  }, [ readOnly, field, onBlur ])

  const getFieldProps = () => {
    let props = {
      name,
      value: value || field.value,
      readOnly,
      type,
      placeholder,
      onChange: (e, value) => {
        let newValue = value
        if (isFirstUppercase) newValue = value ? value[0].toUpperCase() + value.slice(1) : ''

        e.target.value = newValue

        onChange(...[ e, newValue ])
        field.onChange(...[ e, newValue ])
      },
      onClick,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onPaste,
      onKeyDown,
      onClear,
      autoComplete: autoComplete || label || placeholder,
      isError,
      isPadded,
      minHeight,
      minWidth,
      maxWidth,
      inputPadding,
      minNumber,
      maxNumber,
      size,
      defaultStyle,
      fs,
      className,
      ref,
    }

    if (!customComponent) {
      props = {
        ...props,
        tag,
        bgColor,
        label,
        isAutoFocus,
        isAutoResize,
        align,
        isRequired,
        noBorders,
        noMessage,
        iconBefore,
        inputPadding,
        maxLength,
        minLength,
        min,
        max,
        letters,
        numbers,
        addString,
        icon,
        autoFocus,
        labelFluid,
      }
    }

    if (customComponent && mask) {
      props.mask = mask
      props.customComponent = customComponent
      props.maskChar = maskChar
    }

    return props
  }
  if (fieldType === 'password') {
    return <FormFieldPassword {...getFieldProps()} />
  }

  if (fieldType === 'search') {
    return <FormFieldSearch {...getFieldProps()} />
  }

  return <FormField {...getFieldProps()} />
})

FormikField.propTypes = ({ size: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]) })

FormikField.displayName = 'FormikField'

export default FormikField
