import styled from 'styled-components'
import { Modal } from 'components/modal'
import ButtonClose from 'components/buttons/ButtonClose'
import KnowledgeBaseAnalysis from 'components/KnowledgeBase/KnowledgeBaseAnalysis'
import KnowledgeBaseExercise from 'components/KnowledgeBase/KnowledgeBaseExercise'
import KnowledgeBaseProcedure from 'components/KnowledgeBase/KnowledgeBaseProcedure'
import KnowledgeBaseMedicine from 'components/KnowledgeBase/KnowledgeBaseMedicine'
import KnowledgeBaseResearch from 'components/KnowledgeBase/KnowledgeBaseResearch'

const ContentContainer = styled.div`
    width: 600px;
    background-color: #fff;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    height: 100%;

    .closebutton {
      position: absolute;
      top: 16px;
      right: 16px;
    }

    .contentBlock { 
      padding: 24px 42px;
      height: 100%;
      overflow: auto;
    }
  `

const ModalInfoKnowledgeBase = (props) => {
  const { closeModal, data } = props

  const { item, type } = data

  return (
    <Modal height="90%" position="absolute">
      <ContentContainer>
        <ButtonClose
          className="closebutton"
          size="xs"
          onClick={(e) => {
            e.preventDefault()
            closeModal()
          }}
        />
        {item && item.id ? (
          <div className="contentBlock">
            {
              type === 'analysis' && <KnowledgeBaseAnalysis id={item.id} />
            }
            {
              type === 'exercise' && <KnowledgeBaseExercise id={item.id} />
            }
            {
              type === 'procedure' && <KnowledgeBaseProcedure id={item.id} />
            }
            {
              type === 'medicine' && <KnowledgeBaseMedicine id={item.id} />
            }
            {
              type === 'research' && <KnowledgeBaseResearch id={item.id} />
            }
          </div>
        ) : 'Страница не найдена'}
      </ContentContainer>

    </Modal>
  )
}

export default ModalInfoKnowledgeBase
