import cn from 'classnames'
import s from 'styled-components'

const GUTTERS = [ '0px', '8px', '12px', '20px', '24px', '30px' ]

const styles = ({
  $gutter,
  $gutterY,
  ai,
  jc
}) => {
  const offset = GUTTERS[$gutter]
  const offsetY = GUTTERS[$gutterY]

  return `
    display: flex;
    flex-wrap: wrap;
    margin: ${!offsetY ? GUTTERS[0] : `-${offsetY}`} ${!offset ? GUTTERS[0] : `-${offset}`};
    align-items: ${ai || ''};
    align-content: ${jc || ''};

    & > * {
      padding: ${offsetY || 0} ${offset};
    }
  `
}

const SRow = s.div`
  ${styles}
`

const Row = ({
  className = '',
  children = null,
  gutter = '1',
  gutterY = '0',
  ...otherProps
}) => (
  <SRow
    $gutter={gutter}
    $gutterY={gutterY}
    className={cn('row', className)}
    {...otherProps}
  >
    {children}
  </SRow>
)

export default Row
