import { useState, useEffect } from 'react'
import s from 'styled-components'
import cn from 'classnames'

import { Spinner } from 'components/base'
import { checkCorrectUrl } from 'helpers/System'

// import loaderImg from 'assets/images/gif/loader.gif'

const defaultStyles = ({
  $imgSrc,
  theme,
  variant,
  $fit,
  $bgColor = 'grey200',
  $brColor,
  $objectFit,
  isDisabled = false,
  $background,
  $br,
  $bs,
  $border,
  $defaultPadding,
  $height,
  $width,
  $contentWidth,
  $contentHeight,
}) => {
  const sizes = theme.components.cardImage[variant] || theme.components.cardImage.normal
  const borderRadius = $br || (sizes.br
    ? typeof sizes.br === 'number'
      ? `${sizes.br}px`
      : sizes.br
    : '16px')

  const imgBg = $bgColor ? theme.colors[$bgColor] : theme.colors.grey150

  return `
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${$defaultPadding || (sizes.padding ? sizes.padding : $background ? '100% 0 0' : '')}px;
    height: ${$contentHeight || sizes.height};
    width: ${$contentWidth || sizes.width};
    border-radius: ${borderRadius};
    border: ${$border || $brColor ? `1px solid ${theme.colors[$brColor]}` : ''};
    opacity: ${isDisabled ? 0.5 : 1};
    overflow: hidden;
    transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    background-color: ${imgBg};
    box-shadow: ${$bs};
    flex-shrink: 0;

    & > .i-icon & > .background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.--responsive {
      height: auto !important;
      padding-top: 56.2% !important;
    }

    .image {
      position: relative;
      height: ${$height};
      width: ${$width};
      max-width: 100%;
      max-height: 100%;
      display: block;
      object-fit: ${$objectFit};
    }

    &.--loading {

    }

    &.--absolute {
      position: relative;
      min-width: 100px;
      width: 100%;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
      }
    }

    &.--overflow-visible {
      height: calc(${$contentHeight || sizes.height} - 2px);
      width: calc(${$contentWidth || sizes.width} - 2px);
      overflow: visible;
      & > .background {
        position: absolute;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
      }
    }

    & > .background {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: ${imgBg};
      background-image: url(${$imgSrc});
      background-repeat: no-repeat;
      background-size: ${$fit};
      background-position: center;
      border-radius: ${borderRadius};
    }

    .label {
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      z-index: 10;
    }

    &.--no-select {
      user-select: none;
    }
  `
}

const CardImageContainer = s.div`
  ${(props) => defaultStyles(props)}
`

const CardImage = ({
  alt = '',
  background = false,
  children = null,
  fit = 'cover',
  src = null,
  placeholder = null,
  icon = null,
  isResponsive = false,
  loaderSmall = false,
  isAbsolute = false,
  br = '4px',
  className = '',
  isLoader = true,
  noLoading = false,
  noSelect = false,
  border = '',
  bs = '',
  bgColor = '',
  height = '',
  width = '',
  contentHeight = '',
  contentWidth = '',
  brColor = '',
  defaultPadding = '',
  onError,
  objectFit = '',
  srcSet = null,
  overflow = 'hidden',
  ...otherProps
}) => {
  const [ isLoading, setLoading ] = useState(noLoading ? false : !!src)
  const [ img, setImg ] = useState(noLoading ? src || null : null)

  const props = {
    ...otherProps,
    $fit: fit,
    $imgSrc: img,
    $background: background,
    $br: br,
    $border: border,
    $bs: bs,
    $bgColor: bgColor,
    $brColor: brColor,
    $height: height,
    $width: width,
    $defaultPadding: defaultPadding,
    $objectFit: objectFit,
    $contentHeight: contentHeight,
    $contentWidth: contentWidth,
  }

  useEffect(() => {
    if (src && img !== src) {
      setLoading(true)
      const bgImg = new Image()

      bgImg.onload = function() {
        setLoading(false)
        setImg(bgImg.src)
      }

      bgImg.onerror = function() {
        setLoading(false)
        setImg(null)

        if (onError) {
          onError(true)
        }
        // setImg(placeholder)
      }

      bgImg.src = checkCorrectUrl(src)

      return () => {
        bgImg.onload = null
        bgImg.onerror = null
      }
    } if (!src && img) {
      setImg(null)
    }

    // eslint-disable-next-line
  }, [ src ])

  const onImgUrlError = () => {
    setImg(null)

    if (onError) {
      onError(true)
    }
  }

  const spinnerSize = loaderSmall ? 'small' : 'normal'

  return (
    <CardImageContainer
      className={cn('card-image', {
        '--responsive': isResponsive,
        '--loading': isLoading,
        '--absolute': isAbsolute,
        '--no-select': noSelect,
        '--overflow-visible': overflow === 'visible',
      }, className)}
      {...props}
    >
      {children}
      {isLoading ? (isLoader && (
        <div className="loader">
          <Spinner size={spinnerSize} />
        </div>
      )) : (
        <>
          {background && <div className="background" />}
          {!background && img && (
            <img
              className="image"
              onError={onImgUrlError}
              src={img}
              srcSet={srcSet}
              alt={alt}
            />
          )}
          {!img && !background && placeholder && (
            <img
              className="image --placeholder"
              onError={onImgUrlError}
              src={placeholder}
              alt={alt}
            />
          )}
          {!img && !background && icon && <span className="i-icon">{icon}</span>}
        </>
      )}
    </CardImageContainer>
  )
}

export default CardImage
