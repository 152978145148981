import { ReferenceAnalysesTypes } from 'redux/dashboard/Reference/Analyses/ReferenceAnalysesTypes'
import StorageService from 'services/StorageService'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getAdministratorsFilters() || {},

  error: null,
}

const referenceAnalysesReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case ReferenceAnalysesTypes.SET_FETCHING_REFERENCE_ANALYSES_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }
  case ReferenceAnalysesTypes.SET_FETCHING_REFERENCE_ANALYSES_LIST:
    return {
      ...state,
      isFetching: action.payload
    }
  case ReferenceAnalysesTypes.SET_REFERENCE_ANALYSES_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }
  case ReferenceAnalysesTypes.SET_REFERENCE_ANALYSES_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }
  case ReferenceAnalysesTypes.SET_REFERENCE_ANALYSES_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }
  case ReferenceAnalysesTypes.FETCH_REFERENCE_ANALYSES_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }
  case ReferenceAnalysesTypes.SET_REFERENCE_ANALYSES_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }
  case ReferenceAnalysesTypes.CLEAR_REFERENCE_ANALYSES_SEARCH:
    return {
      ...state,
      searchList: []
    }
  case ReferenceAnalysesTypes.CLEAR_REFERENCE_ANALYSES_LIST:
    return {
      ...state,
      list: null
    }
  default:
    return state
  }
}

export default referenceAnalysesReducer
