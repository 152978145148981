/* eslint-disable no-param-reassign */
import { FilesStructureItemsTypes } from 'constants/StructureTypes'

export const checkIsFolder = (item = {}) => !!(typeof item === 'object' && !Array.isArray(item) && (item.children || item.hasOwnProperty('parent_folder')))

export function getObject(array, key, value) {
  let o = null

  array.some(function iter(a) {
    if (a[key] === value) {
      o = a
      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return o
}

export function getParentFolderByFolderId(array, id) {
  let prevId = null

  if (id !== null) {
    array.some(function iter(a) {
      if (a.children.some((i) => i.id === id)) {
        prevId = a.id
        return true
      }

      return Array.isArray(a.children) && a.children.some(iter)
    })
  }

  return prevId
}

export const ObjectByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  s = s.replace(/^\./, '') // strip a leading dot
  const a = s.split('.')
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i]
    if (k in o) {
      o = o[k]
    }
    else {
      return
    }
  }
  return o
}

export const findPathByValue = (ob, key, value) => {
  const path = []

  const keyExists = (obj) => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false
    }
    if (obj.hasOwnProperty(key) && obj[key] === value) {
      return true
    }
    if (Array.isArray(obj)) {
      const parentKey = path.length ? path.pop() : '[0].children'

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`)
        const result = keyExists(obj[i])
        if (result) {
          return result
        }
        path.pop()
      }
    }
    else {
      path.push('.children')
      const result = keyExists(obj.children)
      if (result) {
        return result
      }
      path.pop()
    }

    return false
  }

  keyExists(ob)

  return path.join('')
}

function removeObject(array, key, value) {
  array.some(function iter(a, idx, arr) {
    if (a[key] === value) {
      arr.splice(idx, 1)
      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return false
}

function updateObject(array, key, value, data) {
  array.some(function iter(a, idx, arr) {
    if (a[key] === value) {
      arr[idx] = {
        ...arr[idx],
        ...data,
      }
      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return false
}

function getNewItemMove (data, value, type) {
  return type === FilesStructureItemsTypes.FILES ? {
    ...data,
    folder: value
  } : data
}

function moveObjectItem(array, key, value, data, fromId, type) {
  array.some(function iter(a, idx, arr) {
    if (a[key] === value && a[type]) {
      arr[idx][type].push(getNewItemMove(data, value, type))
      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  array.some(function iter(a, idx, arr) {
    if (a[key] === fromId && a[type]) {
      const itemToRemoveIdx = arr[idx][type].findIndex((p) => p.id === data.id)

      if (itemToRemoveIdx >= 0) {
        arr[idx][type].splice(itemToRemoveIdx, 1)
      }

      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return false
}

function updateObjectItem(array, key, value, fileId, payload, isSearch, type = 'files') {
  if (isSearch) {
    const itemToUpdateIdx = array.findIndex((p) => p.id === fileId)
    array[itemToUpdateIdx] = {
      ...array[itemToUpdateIdx],
      file: {
        ...array[itemToUpdateIdx].file,
        ...payload
      }
    }
  }

  array.some(function iter(a, idx, arr) {
    if (a[key] === value) {
      const itemToUpdateIdx = arr[idx][type].findIndex((p) => p.id === fileId)

      if (itemToUpdateIdx >= 0) {
        arr[idx][type][itemToUpdateIdx] = {
          ...arr[idx][type][itemToUpdateIdx],
          file: {
            ...arr[idx][type][itemToUpdateIdx].file,
            ...payload,
          }
        }
        return true
      }
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return false
}

function removeObjectItem(array, key, value, payload, isSearch, type) {
  if (isSearch) {
    const planDeleteIdx = array.findIndex((p) => p.id === payload.id)
    array.splice(planDeleteIdx, 1)
    return true
  }

  array.some(function iter(a, idx, arr) {
    if (a[key] === value) {
      const itemDeleteIdx = arr[idx][type].findIndex((p) => p.id === payload.id)

      if (itemDeleteIdx >= 0) {
        arr[idx][type].splice(itemDeleteIdx, 1)
      }
      return true
    }

    return Array.isArray(a.children) && a.children.some(iter)
  })

  return false
}

export function addStructureItem(structure, payload, type) {
  if (Array.isArray(structure)) {
    const newStructure = [ ...structure ]
    const folderToAdd = getObject(newStructure, 'id', payload.folder)
    folderToAdd[type].push(payload)

    return newStructure
  }

  return structure
}

export function updateStructureItem(structure, parentId, fileId, file, isSearch, type) {
  const newStructure = [ ...structure ]
  updateObjectItem(newStructure, 'id', parentId, fileId, file, isSearch, type)
  return newStructure
}

export function deleteStructureItem(structure, payload, isSearch, type) {
  const newStructure = [ ...structure ]
  removeObjectItem(newStructure, 'id', payload.folder, payload, isSearch, type)
  return newStructure
}

export function addStructureFolder(structure, payload, isSearch, type = 'files') {
  const newStructure = [ ...structure ]
  const newFolder = {
    id: payload.id,
    title: payload.title,
    user: payload.user,
    [type]: [],
    children: [],
  }

  if (payload.hasOwnProperty('parent_folder') && payload.parent_folder !== null) {
    const folderToAdd = getObject(newStructure, 'id', payload.parent_folder)
    folderToAdd.children.push(newFolder)
  }
  else {
    newStructure.push(newFolder)
  }

  return newStructure
}

export function deleteStructureFolder(structure, payload) {
  if (Array.isArray(structure)) {
    const newStructure = [ ...structure ]
    removeObject(newStructure, 'id', payload.folderId)
    return newStructure
  }

  return structure
}

export function updateStructureFolder(structure, payload, type) {
  if (Array.isArray(structure)) {
    const newStructure = [ ...structure ]
    updateObject(newStructure, 'id', payload.folderId, payload.data, type)
    return newStructure
  }

  return structure
}

export function moveStructureItem(structure, payload, type) {
  if (Array.isArray(structure)) {
    const newStructure = [ ...structure ]
    moveObjectItem(newStructure, 'id', payload.moveToItem.id, payload.item, payload.fromId, type)
    return newStructure
  }

  return structure
}
