import { useCallback, useRef } from 'react'
import { Formik } from 'formik'

// components
import { Button } from 'components/base'
import { Form, FormGroup } from 'components/form'

// constants
import { SchemaDiaryStatChart } from 'constants/ValidationSchemas'

import Text from 'components/typo/Text'
import FormTitle from 'components/form/FormTitle'
import FormBlockDatepicker from 'components/form/FormBlockDatepicker'
import FormBlockField from 'components/form/FormBlockField'
import FormBlockSelect from 'components/form/FormBlockSelect'

import { ReactComponent as IconArrowUp } from 'assets/icons/arrow-up-small.svg'
import { ReactComponent as IconArrowDown } from 'assets/icons/arrow-down-small.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as IconFrequency } from 'assets/icons/repeat-arrows.svg'
import StorageService from 'services/StorageService'

export const PERIODS_TYPES = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUART: 'quart',
}

const PERIODS_OPTIONS = [
  {
    id: PERIODS_TYPES.DAY,
    title: 'День',
  },
  {
    id: PERIODS_TYPES.WEEK,
    title: 'Неделя',
  },
  {
    id: PERIODS_TYPES.MONTH,
    title: 'Месяц',
  },
  {
    id: PERIODS_TYPES.QUART,
    title: 'Квартал',
  },
]

export const FREQUENCY_OPTIONS = {
  [PERIODS_TYPES.DAY]: [
    {
      id: 24 * 4,
      title: '15 минут',
    },
    {
      id: 24 * 2,
      title: '30 минут',
    },
    {
      id: 24 / 1,
      title: '1 час',
    },
    {
      id: 24 / 4,
      title: '4 часа',
    },
  ],
  [PERIODS_TYPES.WEEK]: [
    {
      id: 24 * 7 / 8,
      title: '8 часов',
    },
    {
      id: 24 * 7 / 12,
      title: '12 часов',
    },
    {
      id: Number((24 * 7 / 16).toFixed(0)),
      title: '16 часов',
    },
    {
      id: 24 * 7 / 24,
      title: '24 часа',
    },
  ],
  [PERIODS_TYPES.MONTH]: [
    {
      id: 30,
      title: 'Один день',
    },
    {
      id: 16,
      title: 'Два дня',
    },
    {
      id: 11,
      title: 'Три дня',
    },
    {
      id: 7,
      title: 'Пять дней',
    },
  ],
  [PERIODS_TYPES.QUART]: [
    {
      id: 13,
      title: 'Неделя',
    },
    {
      id: 6,
      title: '2 недели',
    },
    {
      id: 4,
      title: '3 недели',
    },
    {
      id: 3,
      title: 'Месяц',
    },
  ],
}

const BlockTitle = ({ text }) => <Text color="grey500" className="mb-1 mt-4">{text}</Text>

const ChartSettingsPressureForm = ({
  settings = {},
  setSettings,
  type,
  closeModal
}) => {
  const formRef = useRef(null)
  const currentDate = new Date()

  const handleSubmit = useCallback((values) => {
    setSettings(values)
    StorageService.setDiaryAnalyticSettings(type, values)
    closeModal()
  }, [ type, setSettings, closeModal ])

  const handleChangePeriod = useCallback((period, { name }) => {
    if (formRef.current) {
      const { setFieldValue, values } = formRef.current

      if (period !== values[name]) {
        setFieldValue('frequency', FREQUENCY_OPTIONS[period][0].id)
      }
    }
  }, [ ])

  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        period: settings.period || null,
        frequency: settings.frequency || null,
        date_start: settings.date_start || '',
        y: {
          top: settings.y ? settings.y.top : '',
          bottom: settings.y ? settings.y.bottom : ''
        },
        // bottomPressure: {
        //   top: '',
        //   bottom: ''
        // },
      }}
      onSubmit={handleSubmit}
      validationSchema={SchemaDiaryStatChart}
    >
      {(props) => {
        const {
          dirty,
          values,
          isSubmitting,
          handleSubmit,
          isValid,
        } = props

        const formIsValid = dirty && isValid && !isSubmitting

        const frequencyPeriods = values.period ? FREQUENCY_OPTIONS[values.period] : []

        return (
          <Form onSubmit={handleSubmit}>
            <FormTitle mb="0" isSmall title="Настройки графика" />
            <FormGroup>
              <BlockTitle text="Шкала времени" />
              <FormBlockSelect
                title="Отображать"
                name="period"
                Icon={IconCalendar}
                options={PERIODS_OPTIONS}
                onChange={handleChangePeriod}
                placeholder="Выберите значение"
              />
              <FormBlockSelect
                title="Частота"
                name="frequency"
                Icon={IconFrequency}
                options={frequencyPeriods}
                isDisabled={!frequencyPeriods || !frequencyPeriods.length}
                placeholder="Выберите значение"
              />

              <FormBlockDatepicker title="Дата начала" name="date_start" maxDate={currentDate} />
              <BlockTitle text="Значения индикации" />
              <FormBlockField
                title="Верхняя граница"
                Icon={IconArrowUp}
                name="y.top"
                value={values.y.top}
                placeholder="Введите значение"
                numbers
              />
              <FormBlockField
                title="Нижняя граница"
                Icon={IconArrowDown}
                name="y.bottom"
                value={values.y.bottom}
                placeholder="Введите значение"
                numbers
              />
              {/* <BlockTitle text="Значения индикации для верхнего давления" />
              <FormBlockField
                title="Верхняя граница"
                Icon={IconArrowUp}
                name="bottomPressure.top"
                placeholder="Введите значение"
                numbers
              />
              <FormBlockField
                title="Нижняя граница"
                Icon={IconArrowDown}
                name="bottomPressure.bottom"
                placeholder="Введите значение"
                numbers
              />
              */}
            </FormGroup>
            <Button
              className="mt-5"
              type="submit"
              size="big"
              full
              title="Сохранить"
              isDisabled={!formIsValid || isSubmitting}
              isFetching={isSubmitting}
              isGradient
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default ChartSettingsPressureForm
