import { MediaActionTypes } from './MediaTypes'

const MEDIA_INIT_STATE = {
  isLoading: false,
  isError: false,
  isLoaded: null,
  progresses: {},
  cancels: {},
}

const mediaReducer = (state = MEDIA_INIT_STATE, action = {}) => {
  switch (action.type) {
  case MediaActionTypes.SET_FETCHING_UPLOAD_MEDIA_START:
  case MediaActionTypes.SET_MEDIA_LOADING_START:
    return {
      ...state,
      isError: false,
      isLoading: true,
    }

  case MediaActionTypes.FETCH_UPLOAD_MEDIA_SUCCESS:
  case MediaActionTypes.FETCH_MEDIA_ERROR:
  case MediaActionTypes.SET_MEDIA_LOADING_SUCCESS:
    return {
      ...state,
      isLoading: false,
    }

  case MediaActionTypes.SET_FETCHING_UPLOAD_MEDIA_S3:
    return {
      ...state,
      isLoading: action.payload,
    }

  case MediaActionTypes.SET_MEDIA_LOADING_ERROR:
    return {
      ...state,
      isLoading: false,
      isError: true,
    }

  case MediaActionTypes.SET_MEDIA_LOADED:
    return {
      ...state,
      isLoaded: true,
    }

  case MediaActionTypes.SET_MEDIA_UPLOAD_PROGRESS:
    return {
      ...state,
      progresses: {
        ...state.progresses,
        [action.payload.progressId]: {
          ...action.payload,
          uploading: action.payload.progress < 100,
        }
      },
    }

  case MediaActionTypes.ADD_FILE_CANCEL_UPLOADING:
    return {
      ...state,
      cancels: {
        ...state.cancels,
        [action.payload.uploadId]: action.payload.cancelTokenSource
      },
    }

  default:
    return state
  }
}

export default mediaReducer
