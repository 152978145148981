import AddStructureFileForm from 'components/forms/AddStructureFileForm'
import AddStructureFolderForm from 'components/forms/AddStructureFolderForm'
import ModalContent from 'components/modal/ModalContent'

import { FilesStructureTypes } from 'constants/StructureTypes'
import { useSelector } from 'react-redux'
import ModalCenterLayout from './ModalCenterLayout'

const ModalAddStructure = ({ closeModal, modal: { data = {} } }) => {
  const { structureType = '', ...otherData } = data || {}
  const { profile } = useSelector((state) => state.user)

  // const modalTitle = `Загрузить ${structureType === FilesStructureTypes.FOLDER ? 'папку' : 'файл'}`

  return (
    <>
      {FilesStructureTypes.FOLDER === structureType && (
        <ModalCenterLayout
          modalTitle="Создать папку"
          modalHeadAlign="flex-start"
          modalSize="mediumBig"
          onClose={closeModal}
          noPointers
          headPosition="absolute"
          modalNoPadding
          br="huge"
        >
          <ModalContent p="32px 24px 24px">
            <AddStructureFolderForm
              {...otherData}
              closeModal={closeModal}
            />
          </ModalContent>
        </ModalCenterLayout>
      )}
      {FilesStructureTypes.FILE === structureType && (
        <ModalCenterLayout
          modalTitle="Загрузить файл"
          modalTitleSize="mediumSm"
          modalHeadAlign="flex-start"
          modalSize="mediumBig"
          onClose={closeModal}
          noPointers
          headPosition="absolute"
          modalNoPadding
          br="huge"
        >
          <ModalContent p="32px 24px 24px">
            <AddStructureFileForm
              {...otherData}
              profile={profile}
              closeModal={closeModal}
            />
          </ModalContent>
        </ModalCenterLayout>
      )}
    </>

  )
}

export default ModalAddStructure
