import {
  useCallback,
  useMemo,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import Box from 'layout/Box'
import ButtonVideo from 'components/buttons/ButtonVideo'
import ButtonWrapper from 'components/buttons/ButtonWrapper'
import ButtonFullScreen from 'components/buttons/ButtonsFullScreen'
import ButtonMic from 'components/buttons/ButtonMic'
import ButtonClose from 'components/buttons/ButtonClose'
import CardLabel from 'components/card/CardLabel'
import Positioner from 'components/Positioner'

import { setCallContainerPosition, toggleCallFullscreen } from 'redux/Call/CallActions'

import { getTimeFromSeconds } from 'helpers/Date'
import VoxService from 'services/Voximplant/vox.service'
import { LOCAL_VIDEO_CONTAINER, REMOTE_VIDEO_CONTAINER } from 'services/Voximplant/call.config'
import { getUserFullName } from 'helpers/User'
import UserAvatar from 'components/User/UserAvatar'

const CallFullModal = ({
  isConnected,
  isMuteAudio,
  isLocalVideo,
  isRemoteVideo,
  newPosition,
  currentPosition,
  callUser = {},
  duration = 0,
  isFullscreen = false,
  me = {},
}) => {
  const dispatch = useDispatch()

  // DEBUG test vox account - free call < 60 sec
  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && typeof duration === 'number' && duration > 55) {
      VoxService.get().hangupCall()
    }
  }, [ duration ])

  const handleToggleMuteAudio = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    VoxService.get().toggleMuteAudioCall(!isMuteAudio)
  }, [ isMuteAudio ])

  const handleToggleMuteVideo = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()

    VoxService.get().toggleMuteVideoCall(!isLocalVideo)
  }, [ isLocalVideo ])

  const actions = useMemo(() => [
    {
      title: 'Камера',
      onClick: handleToggleMuteVideo,
      bdFilter: 'blur(10px)',
      defaultStyle: 'greyDark',
      Component: ButtonVideo,
      isMute: !isLocalVideo,
    },
    {
      title: 'Микрофон',
      onClick: handleToggleMuteAudio,
      bdFilter: 'blur(10px)',
      defaultStyle: 'greyDark',
      Component: ButtonMic,
      isMute: isMuteAudio
    },
    {
      title: 'Завершить',
      onClick: () => VoxService.get().hangupCall(),
      defaultStyle: 'danger',
      Component: ButtonClose,
    },
  ], [
    isMuteAudio,
    isLocalVideo,
    handleToggleMuteAudio,
    handleToggleMuteVideo,
  ])

  useEffect(() => {
    if (isConnected && !isFullscreen && (newPosition.x !== currentPosition.x || newPosition.y !== currentPosition.y)) {
      dispatch(setCallContainerPosition(newPosition))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ newPosition ])

  const handleToggleFullscreen = useCallback(() => {
    dispatch(toggleCallFullscreen())
  }, [ dispatch ])

  const btnFullscreen = useMemo(() => (
    <ButtonFullScreen
      onClick={handleToggleFullscreen}
      size="medium"
      // isDown
      bdFilter="blur(10px)"
      br="round"
      defaultStyle="greyDark"
    />
  ), [ handleToggleFullscreen ])

  const callActions = useMemo(() => actions.map(({
    Component,
    title,
    ...props
  }, idx) => (isFullscreen
    ? (
      <ButtonWrapper key={idx} title={title}>
        <Component
          {...props}
          size="medium"
          br="round"
        />
      </ButtonWrapper>
    ) : (
      <Component
        {...props}
        key={idx}
        className={idx !== 0 ? 'ml-3' : ''}
        size="medium"
        br="round"
      />
    ))), [ isFullscreen, actions ])

  const remoteVideo = useMemo(() => (
    <Box
      isRelative
      width="100%"
      bg="grey700"
      br="16px"
      flex="1"
      ai="center"
      id={REMOTE_VIDEO_CONTAINER}
      overflowHidden
    >
      {!isRemoteVideo && (
        <Positioner type="center">
          <UserAvatar
            size="huge"
            avatar={callUser.avatar}
            placeholderName={callUser.displayName}
          />
        </Positioner>
      )}
      {isFullscreen && callUser && (
        <Positioner bottom="8px" left="8px">
          <CardLabel title={callUser.displayName} />
        </Positioner>
      )}
    </Box>
  ), [ isRemoteVideo, isFullscreen, callUser ])

  return (
    <Box
      display={!isConnected ? 'none' : 'block'}
      bg="black"
      br="16px"
      className="call-modal"
      width="100%"
      p={!isFullscreen ? '0' : '24px 24px 0'}
      height="100%"
      style={{ pointerEvents: 'none' }}
    >
      <Box
        direction="column"
        width="100%"
        height="100%"
        ai="center"
        jc="center"
        textAlign="center"
        isRelative
      >
        <Box
          style={{ visibility: isFullscreen ? 'visible' : 'hidden' }}
          id={LOCAL_VIDEO_CONTAINER}
          bg={isLocalVideo ? 'grey700' : 'transparent'}
          width="200px"
          height="150px"
          br="16px"
          overflowHidden
          className={cn('local-video-c mb-4', {
            '--hidden': !isFullscreen,
            '--video-h': !isFullscreen || !isLocalVideo
          })}
          isRelative
        >
          {!isLocalVideo && isFullscreen && (
            <Positioner type="center">
              <UserAvatar
                size="huge"
                avatar={me.avatar}
                placeholderName={getUserFullName(me)}
              />
            </Positioner>
          )}
        </Box>

        {remoteVideo}
        {isFullscreen ? (
          <Box
            isRelative
            bg="black"
            width="100%"
            jc="center"
            p="12px"
            ai="center"
          >
            <ButtonWrapper title="Свернуть">
              {btnFullscreen}
            </ButtonWrapper>

            {callActions}

            <Positioner type="center-y" right="8px">
              <CardLabel isLight title={getTimeFromSeconds(duration)} />
            </Positioner>
          </Box>
        ) : (
          <>
            <Positioner top="6px" left="6px">
              {btnFullscreen}
            </Positioner>
            <Positioner type="center-x" bottom="0">
              <Box p="4px 6px">
                {callActions}
              </Box>
            </Positioner>
          </>
        )}
      </Box>
    </Box>
  )
}

export default CallFullModal
