export const randomInteger = (min, max) => {
  // случайное число от min до (max+1)
  const rand = min + (Math.random() * (max + 1 - min))
  return Math.floor(rand)
}

export const getUnicId = () => Math.random().toString(16).slice(2)

export const guidGenerator = () => {
  const S4 = function() {
    return (((1 + Math.random()) * 0x10000) || 0).toString(16).substring(1)
  }

  return (`${S4()}-${S4()}-${S4()}${S4()}${S4()}`)
}

export const getPadZeroNum = (n, v = 2) => String(n).padStart(v, '0')

export const parseStrToNumber = (str, fix = 2) => {
  if (typeof str === 'string') {
    if (str.includes('.')) {
      return parseFloat(str).toFixed(fix)
    }

    return parseInt(str)
  }

  if (typeof str === 'number') {
    return str
  }

  return 0
}

// eslint-disable-next-line no-restricted-globals
export const isNumber = (n) => !isNaN(parseFloat(n)) && !isNaN(n - 0)
