import {
  useRef,
  useEffect,
  useCallback,
} from 'react'
import s from 'styled-components'
import cn from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import ModalLogin from 'components/modals/ModalLogin'
import ModalConfirm from 'components/modals/ModalConfirm'
import ModalConfirmDelete from 'components/modals/ModalConfirmDelete'
import ModalPhoneConfirm from 'components/modals/ModalPhoneConfirm'
import ModalNotifications from 'components/modals/ModalNotifications'
import ModalVideo from 'components/modals/ModalVideo'
import ModalImagePreview from 'components/modals/ModalImagePreview'
import ModalChartSettings from 'components/modals/ModalChartSettings'
import ModalAddStructure from 'components/modals/ModalAddStructure'

import { MODALS_NAMES } from 'constants/SystemTypes'
import gsap from 'services/GsapService'
import useResponsive from 'hooks/useResponsive'

import { setModal } from 'redux/System/SystemActions'

const getStyles = ({
  show,
  isMobile,
  theme
}) => `
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  display: ${show ? 'block' : 'none'};
  width: 100%;
  height: 100%;
  min-height: 320px;
  max-height: 100vh;

  .inner,
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .inner {
    z-index: 2;
    display: flex;
    justify-content: center;
    transform: translate(-100%, 0, 0);
    opacity: 1;
    opacity: 0;
    pointer-events: none;
  }

  .backdrop {
    z-index: 1;
    background-color: ${theme.colors.dark30};
    opacity: 0;

    &.--dark {
      background-color: ${theme.colors.black70};
    }
  }

  .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease-in-out;

    &.--padded {
      padding: ${isMobile ? '16px' : '0'};
    }

    & > * {
      pointer-events: initial;
    }
  }
`

const ModalWrapperContainer = s.div`
  ${getStyles}
`

const modals = {
  [MODALS_NAMES.LOGIN]: ModalLogin,
  [MODALS_NAMES.CONFIRM]: ModalConfirm,
  [MODALS_NAMES.CONFIRM_DELETE]: ModalConfirmDelete,
  [MODALS_NAMES.PHONE_CONFIRM]: ModalPhoneConfirm,
  [MODALS_NAMES.NOTIFICATIONS]: ModalNotifications,
  [MODALS_NAMES.VIDEO]: ModalVideo,
  [MODALS_NAMES.IMG_PREVIEW]: ModalImagePreview,
  [MODALS_NAMES.CHART_SETTINGS]: ModalChartSettings,
  [MODALS_NAMES.ADD_STRUCTURE_FOLDER]: ModalAddStructure,
}

const ModalWrapper = () => {
  const { modal } = useSelector((state) => state.system)
  const { isLaptop, isMobile } = useResponsive()
  const dispatch = useDispatch()

  const wrapperRef = useRef(null)
  const innerRef = useRef(null)
  const backdropRef = useRef(null)

  const closeModal = useCallback(() => {
    const tl = gsap.timeline()
    const direction = modal.direction || 'y'

    tl.fromTo(backdropRef.current, {
      duration: 0.3,
      alpha: 1,
    }, {
      alpha: 0,
      onComplete() {
        document.body.classList.remove('noscroll')
        dispatch(setModal(null))
      },
    }, '0.1')
      .fromTo(innerRef.current, {
        duration: 0.2,
        [direction]: '0',
      }, { [direction]: '100%' }, '0')
  }, [ dispatch, modal ])

  const prevModal = useCallback(() => {
    if (modal.back && modal.prev) {
      dispatch(setModal({
        name: modal.prev,
        prev: modal.name,
      }))
    }
  }, [ dispatch, modal ])

  useEffect(() => {
    if (modal && modal.close) {
      closeModal()
      return
    }

    if (modal && !modal.close && innerRef.current && backdropRef.current) {
      if (!modal.prev) {
        const tl = gsap.timeline()
        const direction = modal.direction || 'y'

        document.body.classList.add('noscroll')

        tl
          .set(innerRef.current, {
            x: direction === 'x' ? '100%' : '0%',
            y: direction === 'y' ? '100%' : '0%',
            opacity: 1,
          })
          .fromTo(backdropRef.current, {
            duration: 0.3,
            alpha: 0,
          }, { alpha: 1 }, '0.3')

          .fromTo(innerRef.current, {
            duration: 0.4,
            [direction]: '100%',
          }, { [direction]: '0' }, 0.2)
      }
    }
    // eslint-disable-next-line
  }, [modal, innerRef.current, backdropRef.current])

  const renderModal = useCallback((key) => {
    const ModalComponent = modals[key]

    if (modal && (modal.name === key || modal.prev === key)) {
      return (
        <ModalComponent
          closeModal={closeModal}
          prevModal={prevModal}
          modal={modal}
          isMobile={isMobile}
          isLaptop={isLaptop}
        />
      )
    }

    return null
  }, [ isLaptop, modal, closeModal, prevModal, isMobile ])

  const transformstyles = useCallback((key) => {
    const transformDirection = (modal && modal.direction ? modal.direction : 'y').toUpperCase()
    if (!modal || modal.name !== key) return `translate${transformDirection}(100%)`
    return `translate${transformDirection}(0%)`
  }, [ modal ])

  const handleBackdrop = useCallback(() => {
    if (modal && modal.onBackdrop) {
      modal.onBackdrop()
    }

    closeModal()
  }, [ closeModal, modal ])

  return (
    <ModalWrapperContainer
      className="modal-wrapper"
      ref={wrapperRef}
      show={modal}
      isMobile={isMobile}
    >
      <div
        ref={backdropRef}
        role="button"
        tabIndex={0}
        onClick={handleBackdrop}
        className={cn('backdrop', { '--dark': modal && modal.name === MODALS_NAMES.VIDEO })}
      />
      <div ref={innerRef} className="inner">
        {Object.keys(modals).map((key) => (
          <div
            style={{
              transform: transformstyles(key),
              zIndex: modal && modal.name === key ? 10 : 5,
            }}
            className="slide"
            key={key}
          >
            {renderModal(key)}
          </div>
        ))}
      </div>
    </ModalWrapperContainer>
  )
}

export default ModalWrapper
