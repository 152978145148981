export const getDefaultSelectStyles = ({
  theme,
  icons,
  size,
  isError,
  $minWidth,
  $maxWidth,
  $noPadded,
  $isLabel,
  $isWhite,
  $color,
  $height,
  $defaultStyle,
}) => {
  const styleProps = $defaultStyle === 'transparent' ? `
    width: 100%;

    .select-group__control {
      background-color: ${theme.colors.transparent} !important;
    }

    .select-group__indicator {
      color: ${theme.colors.black} !important;
    }
  ` : ''

  return `
    position: relative;
    // display: flex;
    // align-items: center;
    height: ${$height || (size ? theme.sizes[size] || 'auto' : theme.sizes.normal)};
    width: 100%;
    min-width: ${$minWidth};
    max-width: ${$maxWidth};

    .select-group__control {
      // flex: 1;
      width: ${$isLabel ? '' : '100%'};
      background-color: ${theme.colors.grey100} !important;
      color: ${theme.colors[$color || ($isWhite ? 'white' : 'grey500')]};
      padding: ${$noPadded ? 0 : '0px 14px'};
      height: ${size && theme.sizes[size] ? theme.sizes[size] : `${theme.sizes.normal}`};
      border-radius: ${theme.radius.normal};
      cursor: pointer;
      border: unset;
      box-shadow: unset !important;

      &#{&}--is-disabled {
        color: ${isError ? `${theme.colors.red}` : theme.colors.grey400};
      }

      &:hover {
        color: ${isError ? `${theme.colors.red}` : theme.colors.grey600};

        .select-group__indicator {
          color: ${theme.colors.grey600};
        }
      }

      &--menu-is-open,
      &--menu-is-open:hover,
      &--menu-is-focused:hover,
      &--menu-is-focused {
        color: ${theme.colors.black};

        .select-group__indicator {
          color: ${theme.colors.black};
        }

        .select-group__menu-list > * {
          color: ${theme.colors.black};
        }
      }

      &--menu-is-open {
        .select-group__dropdown-indicator svg {
          transform: rotateX(180deg);
        }
      }
    }

    .select-group__option {
      color: ${theme.colors.grey600};
      background-color: ${theme.colors.transparent} !important;
    }

    .select-group__option:hover,
    .select-group__option--is-focused,
    .select-group__option--is-selected {
      color: ${theme.colors.black} !important;
    }

    .select-group__indicator-separator {
      display: none;
    }

    .select-group__indicator {
      display: flex;
      align-items: center;
    }

    .select-group__indicator {
      color: ${theme.colors.grey500};
      padding-right: 0;
      padding-left: ${$noPadded ? 0 : ''};

      svg {
        transition: transform 0.3s ease-in-out;
        transform: rotateX(0deg);
        stroke: currentColor !important;
        fill: currentColor !important;
      }
    }


    .select-group__single-value {
      color: currentColor;
      margin-left: 0 !important;
      max-width: 100% !important;
      display: flex;
    }

    .select-group__menu {
      right: 0;
      z-index: 10;
      min-width: 100%;
      width: auto;
      margin-top: 6px;
      padding: 8px 0;
      // overflow: hidden;
      background: ${theme.colors.white};
      border-radius: ${theme.radius.normal};
      border: 1px solid ${theme.colors.grey150};
      box-shadow: 0px 12px 32px 0 rgba(0, 0, 0, 0.12);

      &-list {
        background-color: ${theme.colors.white};
        // max-height: 250px;
        padding: 0 16px;

        & > * + * {
          border-top: 1px solid ${theme.colors.grey100};
        }
      }
    }

    .select-group__option {
      display: flex;
      align-items: center;
      min-height: 38px;
      background-color: ${theme.colors.transparent};
      transition: background-color 0.3s ease-in-out;
      user-select: none;

      &--is-focused,
      &:hover {
        background-color: ${theme.colors.grey100};
      }
    }

    .select-group__placeholder {
      color: currentColor;
      font-size: ${theme.fs.normal};
      line-height: ${theme.lh.normal};
      letter-spacing: ${theme.ls.main};
    }

    .select-group__value-container {
      padding: ${icons ? '5px' : '0 4px 0 0'};
      min-height: 30px;
      height: 100%;

      &--has-value .select-group__single-value {
        color: ${theme.colors.black};
      }

      &--is-multi {
        input {
          position: absolute;
        }
      }

      & > input {
        height: 0;
      }
    }

    ${styleProps}
  `
}

export const getCreatableSelectStyles = ({
  theme,
  icons,
  size,
  isError,
  $minWidth,
  $noBorder,
  $noPadded,
  $isLabel,
  $isWhite,
  $color
}) => `
  height: ${size ? theme.sizes[size] || 'auto' : theme.sizes.normal};
  width: 100%;
  min-width: ${$minWidth};

  .select-group__control {
    // flex: 1;
    width: ${$isLabel ? '' : '100%'};
    background-color: ${theme.colors.transparent} !important;
    color: ${$color || theme.colors[$isWhite ? 'white' : 'dark']} !important;
    padding: ${$noPadded ? 0 : `${theme.spacers[0]}px ${theme.spacers[4]}px`};
    height: ${size && theme.sizes[size] ? theme.sizes[size] : `${theme.sizes.normal}`};
    border-radius: ${theme.radius.small};
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    border: unset;
    box-shadow: ${$noBorder ? 'unset !important' : `0 0 0 1px ${theme.colors.grey100} inset !important`};

    &#{&}--is-disabled {
      color: ${isError ? `${theme.colors.red} !important` : theme.colors.grey400};
    }

    &:hover {
      background-color: ${theme.colors.white10};
      box-shadow: ${$noBorder ? 'unset !important' : `0 0 0 1px ${theme.colors.grey400} inset !important`};
    }

    &--menu-is-open,
    &--menu-is-open:hover,
    &--menu-is-focused:hover,
    &--menu-is-focused {
      background-color: ${theme.colors.transparent} !important;
      box-shadow: ${$noBorder ? 'unset !important' : `0 0 0 1px ${theme.colors.dark} inset !important`};
    }

    &--menu-is-open {
      .select-group__dropdown-indicator svg {
        transform: rotateX(180deg);
      }
    }
  }

  .select-group__indicator-separator {
    display: none;
  }

  .select-group__indicator {
    color: currentColor !important;
    padding-right: 0;
    padding-left: ${$noPadded ? 0 : ''};

    svg {
      transition: transform 0.3s ease-in-out;
      transform: rotateX(0deg);
      stroke: currentColor !important;
      fill: currentColor !important;
    }
  }


  .select-group__single-value {
    color: currentColor;
    margin-left: 0 !important;
    max-width: unset !important;
    display: flex;
  }

  .select-group__menu {
    right: 0;
    z-index: 10;
    min-width: 100%;
    width: auto;
    margin-top: 6px;
    padding: 8px 0;
    // overflow: hidden;
    background: ${theme.colors.white};
    border: unset !important;
    border-radius: ${theme.radius.xs};
    box-shadow: 0px 4px 20px 0 ${theme.colors.black20};

    &-list {
      // background-color: ${theme.colors.white10};
      // max-height: 250px;
      padding: 0;

      & > * + * {
        border-top: 1px solid ${theme.colors.grey200};
      }
    }
  }

  .select-group__menu-list {
    .select-group__option {
      display: flex;
      align-items: center;
    }
  }

  .select-group__option {
    min-height: ${theme.sizes.small};
    padding: 0 14px;
    background-color: ${theme.colors.transparent};
    transition: background-color 0.3s ease-in-out;
    user-select: none;

    &--is-focused,
    &:hover {
      background-color: ${theme.colors.grey100};
    }
  }

  .select-group__placeholder {
    // position: initial;
    // top: unset;
    color: currentColor;
    font-size: ${theme.fs.normal};
    line-height: ${theme.lh.normal};
    letter-spacing: ${theme.ls.main};
    // transform: unset;
  }

  .select-group__value-container {
    padding: ${icons ? '5px' : '0 4px 0 0'};
    min-height: 30px;
  }

  .select-group {
    &__multi-value {
      position: relative;
      padding: 5px 10px;
      margin: 3px;

      &__label {
        padding: 0;
        font-weight: ${theme.fw.m};
        font-size: 14px;
        line-height: 21px;
        color: ${theme.colors.black};
      }

      &__remove {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);

        &:hover {
          background-color: ${theme.colors.red30};
        }

        svg {
          display: none;
        }
      }
    }
  }

`
