import { useRef, useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, props) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      props.onClickOutside(e)
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

/**
 * Component that alerts if you click outside of it
 */
const OutsideAlerter = (props) => {
  const {
    className = '',
    style = {},
    children
  } = props
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, props)

  return (
    <div
      style={style}
      ref={wrapperRef}
      className={className}
    >
      {children}
    </div>
  )
}

export default OutsideAlerter
