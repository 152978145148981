import s from 'styled-components'
import cn from 'classnames'

const styles = ({ theme, size }) => {
  const componentTheme = theme.components.container
  const sizes = componentTheme.sizes[size] || componentTheme.sizes.normal
  const { maxWidth, padding } = sizes
  const paddingStyles = padding || componentTheme.defaultPadding

  return `
    width: 100%;
    max-width: ${maxWidth};
    margin: 0 auto;
    padding: ${paddingStyles};

    &.--rel {
      position: relative;
    }

    &.--fh {
      height: 100%;
    }

    &.--pad-z {
      padding: 0;
    }
  `
}

const ContainerStyled = s.div`
  ${(props) => styles(props)}
`

const Container = ({
  children,
  className = '',
  padding = null,
  fullHeight = false,
  relative = false,
  noPadding = false,
  ...props
}) => (
  <ContainerStyled
    {...props}
    padding={padding}
    className={cn('w-container', {
      '--fh': fullHeight,
      '--rel': relative,
      '--pad-z': noPadding,
    }, className)}
  >
    {children}
  </ContainerStyled>
)

export default Container
