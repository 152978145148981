export const LOCAL_VIDEO_CONTAINER = 'localvideo'
export const REMOTE_VIDEO_CONTAINER = 'remotevideo'

export const CallErrors = {
  480: 'Пользователь не доступен',
  403: 'Доступ к медиа запрещен',
  402: 'Требуется оплата тарифа',
  486: 'Линия занята',
  487: 'Звонок прекращен',
  603: 'Звонок отменен',
  404: 'Неправильный номер',
}
