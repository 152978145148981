import Text from 'components/typo/Text'
import CardBlockWithIcon from 'components/card/CardBlockWithIcon'

import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { DATE_FORMAT_MAIN, formatDate } from 'helpers/Date'
import FormikMaskedField from './FormikMaskedField'

const FormBlockMaskedField = ({
  placeholder = 'Выберите дату',
  name = 'date',
  title = 'Дата',
  isEdit = true,
  value = '',
  mask = '99.99.9999',
  maskChar = '_',
  formatter = DATE_FORMAT_MAIN
}) => (
  <CardBlockWithIcon title={title} Icon={IconCalendar}>
    {isEdit ? (
      <FormikMaskedField
        name={name}
        placeholder={placeholder}
        mask={mask}
        value={value}
        maskChar={maskChar}
        defaultStyle="transparent"
      />

    ) : <Text>{formatDate(value, formatter)}</Text>}
  </CardBlockWithIcon>
)

export default FormBlockMaskedField
