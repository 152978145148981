export const DiarysActionTypes = {
  SET_FETCHING_DIARYS_TYPES: 'SET_FETCHING_DIARYS_TYPES',
  FETCH_DIARYS_TYPES_SUCCESS: 'FETCH_DIARYS_TYPES_SUCCESS',
  FETCH_DIARYS_TYPES_ERROR: 'FETCH_DIARYS_TYPES_ERROR',

  SET_FETCHING_USER_DIARYS_RECORDS_STATS: 'SET_FETCHING_USER_DIARYS_RECORDS_STATS',
  FETCH_USER_DIARYS_RECORDS_STATS_SUCCESS: 'FETCH_USER_DIARYS_RECORDS_STATS_SUCCESS',
  FETCH_USER_DIARYS_RECORDS_STATS_ERROR: 'FETCH_USER_DIARYS_RECORDS_STATS_ERROR',

  SET_FETCHING_USER_DIARY_RECORDS: 'SET_FETCHING_USER_DIARY_RECORDS',
  FETCH_USER_DIARY_RECORDS_SUCCESS: 'FETCH_USER_DIARY_RECORDS_SUCCESS',
  FETCH_USER_DIARY_RECORDS_ERROR: 'FETCH_USER_DIARY_RECORDS_ERROR',
}
