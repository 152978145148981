import PhoneConfirm from 'components/forms/PhoneConfirm'
import ModalCenterLayoutFull from './ModalCenterLayoutFull'

const ModalPhoneConfirm = ({
  closeModal,
  modal,
}) => (
  <ModalCenterLayoutFull headPosition="absolute" onClose={closeModal}>
    <PhoneConfirm data={modal.data} />
  </ModalCenterLayoutFull>
)

export default ModalPhoneConfirm
