export const AdministratorsActionTypes = {
  SET_FETCHING_ADMINISTRATORS_LIST: 'SET_FETCHING_ADMINISTRATORS_LIST',
  FETCH_ADMINISTRATORS_LIST_SUCCESS: 'FETCH_ADMINISTRATORS_LIST_SUCCESS',
  FETCH_ADMINISTRATORS_LIST_ERROR: 'FETCH_ADMINISTRATORS_LIST_ERROR',
  CLEAR_ADMINISTRATORS_LIST: 'CLEAR_ADMINISTRATORS_LIST',
  SET_ADMINISTRATORS_LIST_LAST_SEARCH: 'SET_ADMINISTRATORS_LIST_LAST_SEARCH',
  SET_ADMINISTRATORS_LIST_COUNT: 'SET_ADMINISTRATORS_LIST_COUNT',
  ADD_ADMINISTRATORS_LIST: 'ADD_ADMINISTRATORS_LIST',

  SET_FETCHING_ADMINISTRATORS_SEARCH: 'SET_FETCHING_ADMINISTRATORS_SEARCH',
  FETCH_ADMINISTRATORS_SEARCH_SUCCESS: 'FETCH_ADMINISTRATORS_SEARCH_SUCCESS',
  FETCH_ADMINISTRATORS_SEARCH_ERROR: 'FETCH_ADMINISTRATORS_SEARCH_ERROR',
  CLEAR_ADMINISTRATORS_SEARCH: 'CLEAR_ADMINISTRATORS_SEARCH',

  SET_ANY_ADMINISTRATORS: 'SET_ANY_ADMINISTRATORS',
  SET_ADMINISTRATORS_LIST: 'SET_ADMINISTRATORS_LIST',

  SET_ADMINISTRATORS_SEARCH_VALUE: 'SET_ADMINISTRATORS_SEARCH_VALUE',
}
