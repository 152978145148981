export const filterArrByValue = (arr = [], value = '') => {
  if (Array.isArray(arr)) {
    return arr.filter((i) => i !== value)
  }

  return arr
}

export const arrayFindObjectByProp = (arr = [], value = null, prop = 'id') => {
  if (Array.isArray(arr)) {
    return arr.find((o) => o[prop] === value)
  }

  return null
}

export const arrayHasItem = (arr, value) => {
  if (Array.isArray(arr)) {
    return arr.includes(value)
  }

  return arr
}

export const sortArrayItemByString = (array = [], propName = 'name') => array.sort((a, b) => a[propName].localeCompare(b[propName]))

export const sortArrayItemByStringLight = (array = [], propName = 'name') => array.sort((a, b) => {
  const nameA = a[propName].toUpperCase()
  const nameB = b[propName].toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
})

export const groupArray = (array, chunkSize) => {
  const groups = []
  for (let i = 0; i < array.length; i += chunkSize) {
    groups.push(array.slice(i, i + chunkSize))
  }
  return groups
}

export const createSegments = (num1, num2, length) => {
  const segments = []

  if (num1 === num2) {
    return segments
  }

  const distance = Math.abs(num1 - num2)
  const numSegments = Math.ceil(distance / length)
  const segmentSize = distance / numSegments

  for (let i = 0; i < numSegments; i++) {
    const start = num1 + (i * segmentSize)
    let end = start + segmentSize

    if (end > num2) {
      end = num2
    }

    segments.push({
      start,
      end
    })
  }

  return segments
}
