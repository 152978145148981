import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import ErrorBoundary from 'layout/ErrorBoundary'

import { getUserSession } from 'redux/User/UserActions'
import store from 'redux/store/configureStore'

import { initPlollyfils } from 'polyfills'
import history from 'history/configureHistory'
import { theme } from 'theme'

import App from './App'
import reportWebVitals from './reportWebVitals'

// new styles
import 'assets/scss/index.scss'

// kick off the polyfill!
initPlollyfils()

getUserSession((user) => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <ErrorBoundary>
            <App history={history} isAuth={!!user} />
          </ErrorBoundary>
        </ThemeProvider>
      </Router>
    </Provider>,
    document.getElementById('root')
  )
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
