import { ModalContent } from 'components/modal'
import NotificationsList from 'components/notifications/NotificationsList'

import { fetchNotifications } from 'redux/Notifications/NotificationsActions'

import LoadMore from 'components/base/LoadMore'

const NotificationsContent = ({ closeModal, notifications = null }) => (
  <ModalContent flex p="0px" overflowHidden>
    <LoadMore
      listName="notifications"
      limit={50}
      isInitLoad={!notifications}
      onTrigger={fetchNotifications}
      itemsOffset={(notifications || []).length}
    >
      {({ isFetching }) => (
        <NotificationsList
          isFetching={isFetching}
          notifications={notifications}
          closeModal={closeModal}
        />
      )}
    </LoadMore>
  </ModalContent>
)

export default NotificationsContent
