export const ConsultationsTypes = {
  ALL: 'all',
  FIRST_ONLINE: 'first_online',
  REPEATED_ONLINE: 'repeated_online',
  FIRST_OFFLINE: 'first_offline',
  REPEATED_OFFLINE: 'repeated_offline',
  VISIT_HOME: 'visit_home',
  EMERGENCY: 'emergency'
}

export const ConsultationsSelectModel = [
  {
    id: ConsultationsTypes.ALL,
    title: 'Все консультации'
  },
  {
    id: ConsultationsTypes.FIRST_ONLINE,
    title: 'Первичная онлайн консультация'
  },
  {
    id: ConsultationsTypes.REPEATED_ONLINE,
    title: 'Повторная онлайн консультация'
  },
  {
    id: ConsultationsTypes.FIRST_OFFLINE,
    title: 'Первичная оффлайн консультация'
  },
  {
    id: ConsultationsTypes.REPEATED_OFFLINE,
    title: 'Повторная оффлайн консультация'
  },
  {
    id: ConsultationsTypes.VISIT_HOME,
    title: 'Консультация на дому'
  },
  {
    id: ConsultationsTypes.EMERGENCY,
    title: 'Экстренная консультация'
  },
]

export const ConsultationsSelectModelNotAll = [
  {
    id: ConsultationsTypes.FIRST_ONLINE,
    title: 'Первичная онлайн консультация'
  },
  {
    id: ConsultationsTypes.REPEATED_ONLINE,
    title: 'Повторная онлайн консультация'
  },
  {
    id: ConsultationsTypes.FIRST_OFFLINE,
    title: 'Первичная оффлайн консультация'
  },
  {
    id: ConsultationsTypes.REPEATED_OFFLINE,
    title: 'Повторная оффлайн консультация'
  },
  {
    id: ConsultationsTypes.VISIT_HOME,
    title: 'Консультация на дому'
  },
  {
    id: ConsultationsTypes.EMERGENCY,
    title: 'Экстренная консультация'
  },
]
