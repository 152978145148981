import { Box } from 'layout'
import { Modal } from 'components/modal'
import ModalHead from 'components/modal/ModalHead'

const ModalCenterLayout = ({
  children,
  br = 'normal',
  modalSize = 'normal',
  modalPadding = '',
  modalTitle = null,
  modalTitleSize = 'big',
  modalHeadAlign = 'center',
  noPointers = false,
  textCenter = false,
  modalNoPadding = false,
  modalInnerHeight = null,
  modalInnerWidth = '',
  headPosition = null,
  modalTitleElipsed = false,
  isDark = false,
  onClose = () => {},
  isOverflow = 'auto',
  modalHeadBg = '',
}) => {
  const padding = modalPadding || (modalNoPadding
    ? '64px 0 0 0'
    : headPosition === 'absolute'
      ? '64px 40px 40px 40px'
      : '40px')

  return (
    <Modal
      variant="full"
      height="full"
      bg="transparent"
      className="center-full"
      noPointers={noPointers}
      p="0"
    >
      <Box
        height="100%"
        ai="center"
        jc="center"
      >
        <Modal
          isOverflow={isOverflow}
          height={modalInnerHeight}
          width={modalInnerWidth}
          bg={isDark ? 'dark' : 'white'}
          variant={modalSize}
          textCenter={textCenter}
          relative
          br={br}
          p={padding}
        >
          <ModalHead
            onClose={onClose}
            title={modalTitle}
            titleSize={modalTitleSize}
            headAlign={modalHeadAlign}
            bg={modalHeadBg}
            isDark={isDark}
            position={headPosition}
            paddingSmall={modalNoPadding}
            titleElipsed={modalTitleElipsed}
          />
          {children}
        </Modal>
      </Box>
    </Modal>
  )
}

export default ModalCenterLayout
