import { useMemo } from 'react'
// import PropTypes from 'prop-types'
import cn from 'classnames'
import s from 'styled-components'
// import { colors } from 'theme'

import { getTypoCommonStyles } from './typo.styles'

const TextContainerDiv = s.div`
  ${getTypoCommonStyles}
`

const TextContainerP = s.p`
  ${getTypoCommonStyles}
`

const TextContainerSpan = s.span`
  ${getTypoCommonStyles}
`

const Text = ({
  children,
  size = 'normal',
  className = '',
  title = '',
  tag = null,
  textAlign = '',
  noSelect = false,
  cursor = '',
  hoverColor = '',
  display = '',
  maxWidth = '',
  color = 'current',
  noPointers = false,
  nowrap = false,
  caps = false,
  isBreakAll = false,
  opacity = '',
  minHeight = '',
  td = '',
  tt = '',
  fw = '',
  lh = '',
  fs = '',
  ff = '',
  ls = '',
  ws = '',
  isElipsed = false,
  alt = '',
  width = '',
  ...otherProps
}) => {
  const classes = useMemo(() => cn(
    'w-text',
    {
      [`--ta-${textAlign}`]: textAlign,
      '--no-select': noSelect,
      'text-elipsed': isElipsed,
      '--break': isBreakAll,
    },
    className
  ), [ textAlign, noSelect, isBreakAll, className, isElipsed ])

  const ComponentContainer = useMemo(() => {
    switch (tag) {
    case 'p':
      return TextContainerP
    case 'span':
      return TextContainerSpan

    default:
      return TextContainerDiv
    }
  }, [ tag ])

  const props = {
    className: classes,
    cursor,
    size,
    hoverColor,
    display,
    maxWidth,
    color,
    noPointers,
    nowrap,
    caps,
    td,
    fw,
    lh,
    fs,
    ff,
    ls,
    $minHeight: minHeight,
    $opacity: opacity,
    title: alt || '',
    $width: width,
    $ws: ws,
    $tt: tt,
    ...otherProps,
  }

  return <ComponentContainer {...props}>{children || title}</ComponentContainer>
}

// Text.propTypes = { color: PropTypes.oneOf(Object.keys(colors)) }

export default Text
