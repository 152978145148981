import CardUserBlock from 'components/card/CardUserBlock'
import { getUserRoleTitle } from 'helpers/User'
import NotifierMessageText from '../NotifierMessageText'

const NotifierChatMessage = ({
  message,
  users,
  titleColor,
}) => {
  const {
    user = {},
    text = '',
    sender = null
  } = message || {}

  let userInfo = user.userInfo || null

  if (!user.userInfo) {
    userInfo = users.find((c) => c.userId === sender) || {}
  }

  const title = text || 'Получено сообщение'

  return (
    <div className="notifier-message-content">
      <div className="mb-2 mr-40">
        <CardUserBlock
          ai="flex-start"
          isStatic
          fw="m"
          descrColor="grey500"
          descrSize="normalSm"
          user={userInfo}
          size="normalSmall"
          defaultTitle={user.displayName}
          defaultDescription={getUserRoleTitle(userInfo.role)}
        />
      </div>
      <NotifierMessageText
        color={titleColor}
        text={title}
      />
    </div>
  )
}

export default NotifierChatMessage
