import Text from 'components/typo/Text'
import NotifierMessageText from '../NotifierMessageText'

const NotifierCardDefault = ({
  message,
  description,
  titleColor,
}) => (
  <div className="notifier-message-content">
    <NotifierMessageText
      color={titleColor}
      text={message}
    />
    {description && (
      <div className="notifier-message-descr">
        <Text color="grey500">{description}</Text>
      </div>
    )}
  </div>
)

export default NotifierCardDefault
