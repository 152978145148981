import { useRef } from 'react'
import axios from 'axios'

const useAxios = () => {
  const cancelTokenSource = useRef(null)

  const getNewCancelTokenSource = () => {
    cancelTokenSource.current = axios.CancelToken.source()
    return cancelTokenSource.current
  }

  const clearCancelToken = () => {
    cancelTokenSource.current = null
  }

  const cancelRequest = (msg) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(msg)
      clearCancelToken()
    }
  }

  return {
    cancelTokenSource,
    getNewCancelTokenSource,
    cancelRequest,
    clearCancelToken,
  }
}

export default useAxios
