export const MediaContextTypes = {
  PLAN: 'PLAN',
  TASK: 'TASK',
  PROJECT: 'PROJECT',
  GALLERY: 'GALLERY',
  TASK_PRICE_FILE: 'TASK_ESTIMATE',
  TASK_PAYMENT_FILE: 'TASK_CHECK',
}

export const MediaFileTypes = {
  IMAGE: 'image',
  FILE: 'file',
  VIDEO: 'video',
  AUDIO: 'audio',
}

export const VideoExtTypes = {
  MOV: 'mov',
  MP4: 'mp4',
  AVI: 'avi',
}

export const FilesExtTypes = {
  PDF: 'pdf',
  XLS: 'xls',
  DEFAULT: 'any',
}

export const MediaAcceptTypes = {
  IMAGE: 'image/*',
  VIDEO: 'video/*',
  // VIDEO: 'video/mp4,video/*',
  FILES: '.pdf,.word,.excel,.xls,.txt',
}

export const MediaVideoExtensions = Object.values(VideoExtTypes)

export const getFileTypeNum = (fullType) => {
  let type = ''
  if (typeof fullType !== 'string' || fullType.length === 0) {
    return MediaFileTypes.FILE
  }

  const [ t ] = fullType.split('/')

  switch (t) {
  case 'image':
    type = MediaFileTypes.PHOTO
    break
  case 'video':
    type = MediaFileTypes.VIDEO
    break

  case '':
  default:
    type = MediaFileTypes.FILE
    break
  }

  return type
}
