export const FilesStructureTypes = {
  FOLDER: 'FOLDER',
  FILE: 'FILE',
}

export const FilesStructureLayoutTypes = {
  CATALOG: 'catalog',
  FOLDERS: 'folders',
}

export const FilesStructureItemsTypes = {
  PLANS: 'plans',
  FILES: 'files',
  CHILDREN: 'children',
}
