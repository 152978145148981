// redux
import ChartSettingsPressureForm from 'components/forms/ChartSettingsForm'
import ModalCenterLayout from './ModalCenterLayout'

const ModalChartSettings = ({ closeModal, modal: { chart = {} } }) => (
  <ModalCenterLayout
    modalSize="normalHuge"
    isOverflow="unset"
    modalPadding="24px"
    headPosition="absolute"
    onClose={closeModal}
    noPointers
  >
    {chart && <ChartSettingsPressureForm closeModal={closeModal} {...chart} />}
  </ModalCenterLayout>
)

export default ModalChartSettings
