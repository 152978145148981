import { KnowledgeBaseActionTypes } from 'redux/dashboard/KnowledgeBase/KnowledgeBaseTypes'

const INITIAL_STATE = {
  exercise: null,
  analysis: null,
  medicine: null,
  procedure: null,
  manipulation: null,
  research: null,
  instruction_doctor: null,
  instruction_admin: null,
  specialization: null,
  offset_exercise: 0,
  offset_analysis: 0,
  offset_medicine: 0,
  offset_procedure: 0,
  offset_manipulation: 0,
  offset_research: 0,
  offset_instruction_doctor: 0,
  offset_instruction_admin: 0,
  offset_specialization: 0,
  max_offset_exercise: 0,
  max_offset_analysis: 0,
  max_offset_medicine: 0,
  max_offset_procedure: 0,
  max_offset_manipulation: 0,
  max_offset_research: 0,
  max_offset_instruction_doctor: 0,
  max_offset_instruction_admin: 0,
  max_offset_specialization: 0,
}

const knowledgeBaseReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case KnowledgeBaseActionTypes.SET_EXERCISE:
    return {
      ...state,
      exercise: action.payload.results,
      max_offset_exercise: action.payload.count,
      offset_exercise: 40,
    }
  case KnowledgeBaseActionTypes.ADD_EXERCISE:
    return {
      ...state,
      exercise: state.exercise.concat(action.payload),
      offset_exercise: state.offset_exercise + 40
    }
  case KnowledgeBaseActionTypes.SET_ANALYSIS:
    return {
      ...state,
      analysis: action.payload.results,
      max_offset_analysis: action.payload.count,
      offset_analysis: 40,
    }
  case KnowledgeBaseActionTypes.ADD_ANALYSIS:
    return {
      ...state,
      analysis: state.analysis.concat(action.payload),
      offset_analysis: state.offset_analysis + 40
    }
  case KnowledgeBaseActionTypes.SET_MEDICINE:
    return {
      ...state,
      medicine: action.payload.results,
      max_offset_medicine: action.payload.count,
      offset_medicine: 40,
    }
  case KnowledgeBaseActionTypes.ADD_MEDICINE:
    return {
      ...state,
      medicine: state.medicine.concat(action.payload),
      offset_medicine: state.offset_medicine + 40
    }
  case KnowledgeBaseActionTypes.SET_PROCEDURE:
    return {
      ...state,
      procedure: action.payload.results,
      max_offset_procedure: action.payload.count,
      offset_procedure: 40,
    }
  case KnowledgeBaseActionTypes.ADD_PROCEDURE:
    return {
      ...state,
      procedure: state.procedure.concat(action.payload),
      offset_procedure: state.offset_procedure + 40
    }
  case KnowledgeBaseActionTypes.SET_MANIPULATION:
    return {
      ...state,
      manipulation: action.payload.results,
      max_offset_manipulation: action.payload.count,
      offset_manipulation: 40,
    }
  case KnowledgeBaseActionTypes.ADD_MANIPULATION:
    return {
      ...state,
      manipulation: state.manipulation.concat(action.payload),
      offset_manipulation: state.offset_manipulation + 40
    }
  case KnowledgeBaseActionTypes.SET_RESEARCH:
    return {
      ...state,
      research: action.payload.results,
      max_offset_research: action.payload.count,
      offset_research: 40,
    }
  case KnowledgeBaseActionTypes.ADD_RESEARCH:
    return {
      ...state,
      research: state.research.concat(action.payload),
      offset_research: state.offset_research + 40
    }
  case KnowledgeBaseActionTypes.SET_INSTRUCTION_DOCTOR:
    return {
      ...state,
      instruction_doctor: action.payload.results,
      max_offset_instruction_doctor: action.payload.count,
      offset_instruction_doctor: 40,
    }
  case KnowledgeBaseActionTypes.ADD_INSTRUCTION_DOCTOR:
    return {
      ...state,
      instruction_doctor: state.instruction_doctor.concat(action.payload),
      offset_instruction_doctor: state.offset_instruction_doctor + 40
    }
  case KnowledgeBaseActionTypes.SET_INSTRUCTION_ADMIN:
    return {
      ...state,
      instruction_admin: action.payload.results,
      max_offset_instruction_admin: action.payload.count,
      offset_instruction_admin: 40,
    }
  case KnowledgeBaseActionTypes.ADD_INSTRUCTION_ADMIN:
    return {
      ...state,
      instruction_admin: state.instruction.concat(action.payload),
      offset_instruction_admin: state.offset_instruction_admin + 40
    }
  case KnowledgeBaseActionTypes.SET_SPECIALIZATION:
    return {
      ...state,
      specialization: action.payload.results,
      max_offset_specialization: action.payload.count,
      offset_specialization: 40,
    }
  case KnowledgeBaseActionTypes.ADD_SPECIALIZATION:
    return {
      ...state,
      specialization: state.specialization.concat(action.payload),
      offset_specialization: state.offset_specialization + 40
    }
  default:
    return state
  }
}

export default knowledgeBaseReducer
