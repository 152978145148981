export const UserActionTypes = {
  USER_LOGIN_START: 'USER_LOGIN_START',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',

  USER_RESET_PASSWORD_START: 'USER_RESET_PASSWORD_START',
  USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
  USER_RESET_PASSWORD_ERROR: 'USER_RESET_PASSWORD_ERROR',

  USER_RESET_PASSWORD_SUBMIT_START: 'USER_RESET_PASSWORD_SUBMIT_START',
  USER_RESET_PASSWORD_SUBMIT_SUCCESS: 'USER_RESET_PASSWORD_SUBMIT_SUCCESS',
  USER_RESET_PASSWORD_SUBMIT_ERROR: 'USER_RESET_PASSWORD_SUBMIT_ERROR',

  USER_SET_NEW_PASSWORD_START: 'USER_SET_NEW_PASSWORD_START',
  USER_SET_NEW_PASSWORD_SUCCESS: 'USER_SET_NEW_PASSWORD_SUCCESS',
  USER_SET_NEW_PASSWORD_ERROR: 'USER_RESET_PASSWORD_SUBMIT_ERROR',

  SET_FETCHING_UPDATE_PROFILE: 'SET_FETCHING_UPDATE_PROFILE',
  FETCH_UPDATE_PROFILE_ERROR: 'FETCH_UPDATE_PROFILE_ERROR',
  FETCH_UPDATE_PROFILE_SUCCESS: 'FETCH_UPDATE_PROFILE_SUCCESS',

  FETCH_CHANGE_PASSWORD_START: 'FETCH_CHANGE_PASSWORD_START',
  FETCH_CHANGE_PASSWORD_SUCCESS: 'FETCH_CHANGE_PASSWORD_SUCCESS',
  FETCH_CHANGE_PASSWORD_ERROR: 'FETCH_CHANGE_PASSWORD_ERROR',

  SET_ERROR: 'SET_ERROR',
  SET_SUBMITTING: 'SET_SUBMITTING',
  SET_PROFILE_COMPANY: 'SET_PROFILE_COMPANY',

  SET_FETCHING_CREATE_PROFILE_COMPANY: 'SET_FETCHING_CREATE_PROFILE_COMPANY',
  FETCH_CREATE_PROFILE_COMPANY_ERROR: 'FETCH_CREATE_PROFILE_COMPANY_ERROR',
  FETCH_CREATE_PROFILE_COMPANY_SUCCESS: 'FETCH_CREATE_PROFILE_COMPANY_SUCCESS',

  SET_FETCHING_UPDATE_PROFILE_COMPANY: 'SET_FETCHING_UPDATE_PROFILE_COMPANY',
  FETCH_UPDATE_PROFILE_COMPANY_ERROR: 'FETCH_UPDATE_PROFILE_COMPANY_ERROR',
  FETCH_UPDATE_PROFILE_COMPANY_SUCCESS: 'FETCH_UPDATE_PROFILE_COMPANY_SUCCESS',

  SET_FETCHING_USER_BINDS: 'SET_FETCHING_USER_BINDS',
  FETCH_USER_BINDS_ERROR: 'FETCH_USER_BINDS_ERROR',
  FETCH_USER_BINDS_SUCCESS: 'FETCH_USER_BINDS_SUCCESS',
  ADD_USER_BIND: 'ADD_USER_BIND',

  SET_USER_PLACE: 'SET_USER_PLACE',
}
