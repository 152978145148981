import styled from 'styled-components'
import cn from 'classnames'
import { ReactComponent as IconClose } from 'assets/icons/close.svg'
import Title from 'components/typo/Title'
import { pageHeight } from 'layout/PageHeader'
import ButtonRound from 'components/base/ButtonRound'

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $headAlign }) => $headAlign || 'center'};
  width: 100%;
  padding: 16px 40px;
  min-height: ${({ $minHeight }) => $minHeight || ''};
  position: relative;

  &.--dark {
    background-color: ${({ $bg, theme }) => ($bg ? theme.colors[$bg] || $bg : theme.colors.black)};
  }

  .a-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  &.--p {
    top: 0;
    z-index: 200;

    &-fixed {
      position: fixed;
    }

    &-absolute {
      left: 0;
      position: absolute;
    }

    &-sticky {
      position: sticky;
    }
  }

  &.--pad-small {
    padding: 16px 40px 16px 24px;
  }
`

const ModalHead = ({
  className = '',
  children = null,
  onClose = null,
  title = '',
  titleSize = 'big',
  headAlign = 'center',
  isDark = false,
  position = null,
  minHeight = pageHeight,
  paddingSmall = false,
  titleElipsed = false,
  isFull = false,
  bg = '',
}) => (
  <ModalContainer
    $minHeight={minHeight}
    $bg={bg}
    $headAlign={headAlign}
    className={cn('m-head', {
      [`--p --p-${position}`]: position,
      '--pad-small': paddingSmall,
      '--dark': isDark
    }, className)}
  >
    {children}
    {!!title && (
      <Title
        color={isDark ? 'white' : 'black'}
        size={titleSize}
        fw={titleSize !== 'big' ? 'm' : 'b'}
        title={title}
        isElipsed={titleElipsed}
      />
    )}
    {!!onClose && (
      <ButtonRound
        onClick={onClose}
        height="auto"
        className="a-close"
        color={isDark ? 'white' : 'dark'}
        defaultStyle={isFull ? 'light' : 'transparent'}
        p="0px"
        br={isFull ? '50%' : ''}
        // isIcon
        size={isFull ? 'normal' : 'xs'}
      >
        <IconClose />
      </ButtonRound>
    )}
  </ModalContainer>
)

export default ModalHead
