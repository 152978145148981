import { ReactComponent as IconMic } from 'assets/icons/mic.svg'
import { ReactComponent as IconMicMute } from 'assets/icons/mic-mute.svg'

import ButtonRound from 'components/base/ButtonRound'

const ButtonMic = ({
  defaultStyle = 'acsent',
  br = '8px',
  isMute = false,
  ...props
}) => (
  <ButtonRound
    br={br}
    defaultStyle={defaultStyle}
    renderIcon={() => (isMute ? <IconMicMute /> : <IconMic />)}
    {...props}
  />
)

export default ButtonMic
