export const FiltersTypes = {
  GROUP: 'group_panel',
  SEARCH: 'search',
  ORDERING: 'ordering',
}

export const FiltersSortUserTypes = {
  ALPHABET_DOWN: 'last_name',
  ALPHABET_UP: '-last_name',
  CREATED_ASC: 'created_at',
  CREATED_DESC: '-created_at',
}

export const FiltersSortUserModel = [
  {
    id: FiltersSortUserTypes.ALPHABET_DOWN,
    title: 'По алфавиту А-Я'
  },
  {
    id: FiltersSortUserTypes.ALPHABET_UP,
    title: 'По алфавиту Я-А'
  },
  {
    id: FiltersSortUserTypes.CREATED_ASC,
    title: 'По дате регистрации ↑'
  },
  {
    id: FiltersSortUserTypes.CREATED_DESC,
    title: 'По дате регистрации ↓'
  },
]

export const FiltersProjectGroupTypes = {
  EXPLOITATION: 'EXPLOITATION',
  ARCHIVE: 'ARCHIVE',
  WORK: 'WORK',
}

export const FiltersDateTypes = {
  ALL: 'ALL',
  LAST_WEEK: 'LAST_WEEK',
  LAST_MONTH: 'LAST_MONTH',
}

export const FiltersFormTypes = {
  DATE_START: 'date_start',
  DATE_END: 'date_end',
  PRIORITY: 'priority',
  CATEGORY: 'category',
  TAGS: 'tags',
  PARTICIPANTS: 'participants',
  RANGE_TYPE: 'range_type',
}

export const FiltersTaskDateModel = [
  {
    id: FiltersDateTypes.ALL,
    title: 'За все время',
  },
  {
    id: FiltersDateTypes.LAST_WEEK,
    title: 'Последняя неделя',
  },
  {
    id: FiltersDateTypes.LAST_MONTH,
    title: 'Последний месяц',
  }
]
