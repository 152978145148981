import { Field } from 'formik'

import FormikField from './FormikField'
import FormFieldMasked from './FormFieldMasked'

const FormikMaskedField = ({
  type = 'text',
  mask = '9 9 9 9',
  name = '',
  value = '',
  placeholder = '',
  label = '',
  maskChar = '_',
  onClear = null,
  icon = null,
  readOnly = false,
  alwaysShowMask = false,
  defaultStyle,
}) => (
  <Field>
    {() => (
      <FormikField
        type={type}
        tag="mask"
        name={name}
        value={value}
        placeholder={placeholder}
        mask={mask}
        label={label}
        alwaysShowMask={alwaysShowMask}
        customComponent={FormFieldMasked}
        onClear={onClear}
        icon={icon}
        readOnly={readOnly}
        maskChar={maskChar}
        defaultStyle={defaultStyle}
      />
    )}
  </Field>
)

export default FormikMaskedField
