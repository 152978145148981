export const MODALS_NAMES = {
  LOGIN: 'login',
  CONFIRM: 'confirm',
  CONFIRM_DELETE: 'confirm-delete',
  PHONE_CONFIRM: 'phone-confirm',
  NOTIFICATIONS: 'notifications',
  VIDEO: 'video',
  IMG_PREVIEW: 'img-preview',
  CHART_SETTINGS: 'chart-settings',
  ADD_STRUCTURE_FOLDER: 'add-structure-folder',
  ADD_STRUCTURE_FILE: 'add-structure-file',
  MOVE_STRUCTURE: 'move-structure',
  DIARY_CHART: 'diary-chart',
}

export const MODAL_CONFIRM_TYPES = {
  RESET: 'reset',
  DELETE: 'delete',
  SAVE: 'save',
  EXIT: 'exit',
}

export const EMPTY_DEFAULT_TEXT = 'Не указано'
