export const ReferenceManipulationsTypes = {
  SET_FETCHING_REFERENCE_MANIPULATIONS_LIST: 'SET_FETCHING_REFERENCE_MANIPULATIONS_LIST',
  FETCH_REFERENCE_MANIPULATIONS_LIST_SUCCESS: 'FETCH_REFERENCE_MANIPULATIONS_LIST_SUCCESS',
  FETCH_REFERENCE_MANIPULATIONS_LIST_ERROR: 'FETCH_REFERENCE_MANIPULATIONS_LIST_ERROR',
  CLEAR_REFERENCE_MANIPULATIONS_LIST: 'CLEAR_REFERENCE_MANIPULATIONS_LIST',
  SET_REFERENCE_MANIPULATIONS_LIST_LAST_SEARCH: 'SET_REFERENCE_MANIPULATIONS_LIST_LAST_SEARCH',
  SET_REFERENCE_MANIPULATIONS_LIST_COUNT: 'SET_REFERENCE_MANIPULATIONS_LIST_COUNT',
  ADD_REFERENCE_MANIPULATIONS_LIST: 'ADD_REFERENCE_MANIPULATIONS_LIST',
  DELETE_REFERENCE_MANIPULATIONS_LIST_ITEM: 'DELETE_REFERENCE_MANIPULATIONS_LIST_ITEM',

  SET_FETCHING_REFERENCE_MANIPULATIONS_SEARCH: 'SET_FETCHING_REFERENCE_MANIPULATIONS_SEARCH',
  FETCH_REFERENCE_MANIPULATIONS_SEARCH_SUCCESS: 'FETCH_REFERENCE_MANIPULATIONS_SEARCH_SUCCESS',
  FETCH_REFERENCE_MANIPULATIONS_SEARCH_ERROR: 'FETCH_REFERENCE_MANIPULATIONS_SEARCH_ERROR',
  CLEAR_REFERENCE_MANIPULATIONS_SEARCH: 'CLEAR_REFERENCE_MANIPULATIONS_SEARCH',

  SET_ANY_REFERENCE_MANIPULATIONS: 'SET_ANY_REFERENCE_MANIPULATIONS',
  SET_REFERENCE_MANIPULATIONS_LIST: 'SET_REFERENCE_MANIPULATIONS_LIST',

  SET_REFERENCE_MANIPULATIONS_SEARCH_VALUE: 'SET_REFERENCE_MANIPULATIONS_SEARCH_VALUE',
}

export const ReferenceManipulationEditTypes = {
  ADD: 'add',
  CHANGE: 'change',
}
