import audioCallingSrc from './sounds/calling.mp3'

const audioCalling = new Audio(audioCallingSrc)

function startPlaying() {
  this.currentTime = 0
  this.play()
}

export const playCallingSound = () => {
  audioCalling.addEventListener('ended', startPlaying)
  audioCalling.play()
}

export const stopCallingSound = () => {
  audioCalling.removeEventListener('ended', startPlaying)
  audioCalling.pause()
  audioCalling.currentTime = 0
}
