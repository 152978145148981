import {
  PAGE_DASHBOARD_PATIENTS,
  PATIENT_ANALYZES,
  PATIENT_CONCLUSIONS,
  PATIENT_EXTRACTS,
  PATIENT_MEDICAL_DOCUMENTS,
  PATIENT_RECIPES,
  PATIENT_RESEARCHES,
  PATIENT_SICKS
} from 'constants/PathsTypes'

export const PatientMappedPaths = {
  analysis: PATIENT_ANALYZES,
  certificate_recovery: PATIENT_EXTRACTS,
  electronic_recipe: PATIENT_RECIPES,
  medical_documents: PATIENT_MEDICAL_DOCUMENTS,
  instrumental_research: PATIENT_RESEARCHES,
  sick_leave: PATIENT_SICKS,
  conclusions: PATIENT_CONCLUSIONS,
}

export const getPatientUrl = (patientId) => `${PAGE_DASHBOARD_PATIENTS}/${patientId}`

export const getPatientFolderUrl = (patientId, partition) => `${PAGE_DASHBOARD_PATIENTS}/${patientId}${PatientMappedPaths[partition]}`
