import {
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useDispatch } from 'react-redux'

import { Spinner } from 'components/base'
import VIsibleTrigger from 'components/base/VIsibleTrigger'
import useAxios from 'hooks/useAxios'

const LoadMore = ({
  onTrigger = null,
  limit = 50,
  limitStop = null,
  children,
  itemsOffset = 0,
  isEmpty = false,
  setEmpty = null,
  additionalProps = {},
  isCancelable = false,
  isInitLoad = false,
  // listName // for DEBUG
}) => {
  const {
    cancelTokenSource,
    cancelRequest,
    getNewCancelTokenSource
  } = useAxios()

  const isNext = useRef(true)
  const offset = useRef(itemsOffset)
  const dispatch = useDispatch()
  const [ isFetching, setFetching ] = useState(false)

  useEffect(() => {
    if (itemsOffset !== offset) {
      offset.current = itemsOffset
    }
  }, [ itemsOffset ])

  const loadItems = useCallback((currentOffset = 0) => {
    if (!onTrigger) {
      return false
    }

    setFetching(true)

    const requestData = {
      limit,
      offset: currentOffset,
      ...additionalProps
    }

    if (isCancelable) {
      requestData.cancelTokenSource = getNewCancelTokenSource()
    }

    return dispatch(onTrigger(requestData, true))
      .then((response) => {
        isNext.current = !!response.next
        offset.current = limit + currentOffset
        setFetching(false)

        if (setEmpty) {
          if (response.count === 0 && !isEmpty) {
            setEmpty(true)
          }
          else if (response.count > 0 && isEmpty) {
            setEmpty(false)
          }
        }

        return Promise.resolve(response.count)
      })
      .catch(() => {
        setFetching(false)
        isNext.current = false
      })
  }, [
    dispatch,
    isEmpty,
    limit,
    onTrigger,
    setEmpty,
    additionalProps,
    isCancelable,
    getNewCancelTokenSource,
  ])

  useEffect(() => () => {
    if (isCancelable && cancelTokenSource.current) {
      cancelRequest('Request aborted')
    }
    // eslint-disable-next-line
  }, [ isCancelable ])

  useEffect(() => {
    if (isInitLoad) {
      loadItems(offset.current)
    }
    // eslint-disable-next-line
  }, [ isInitLoad ])

  return (
    <div className="load-more-list">
      {children({ isFetching })}
      {isFetching && !isInitLoad && <Spinner relative height="150px" />}
      {!isFetching && isNext.current
          && (itemsOffset > 0)
          && (limitStop === null || itemsOffset < limitStop)
          && <VIsibleTrigger onTrigger={() => loadItems(offset.current)} />}
    </div>
  )
}

export default LoadMore
