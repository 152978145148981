/* eslint-disable no-promise-executor-return */
import ApiService from 'services/ApiService'
import StorageService from 'services/StorageService'

import { messageTypes } from 'constants/NotifierTypes'
import { setNotification } from '../System/SystemActions'

import { AuthActionTypes } from './AuthTypes'

export const setFetchingGetUser = (value) => ({
  type: AuthActionTypes.SET_FETCHING_GET_USER,
  payload: value,
})

export const fetchGetUserSuccess = (user) => ({
  type: AuthActionTypes.FETCH_GET_USER_SUCCESS,
  payload: user,
})

export const setIsNewUser = (newValue) => ({
  type: AuthActionTypes.SET_IS_NEW_USER,
  payload: newValue,
})

export const fetchGetUser = (token = null) => (dispatch) => {
  dispatch(setFetchingGetUser(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.auth.GET_ME,
    token,
    isToken: true,
  })
    .then((user) => {
      StorageService.setUserSession(user)
      dispatch(fetchGetUserSuccess(user))
      dispatch(setFetchingGetUser(false))

      return res(user)
    })
    .catch(({ data }) => {
      dispatch(setFetchingGetUser(false))
      return rej(data)
    }))
}

// export const setFetchingGetToken = (value) => ({
//   type: AuthActionTypes.SET_FETCHING_GET_AUTH_TOKEN,
//   payload: value,
// })

// export const fetchGetTokenSuccess = (user) => ({
//   type: AuthActionTypes.FETCH_GET_AUTH_TOKEN_SUCCESS,
//   payload: user,
// })

// export const fetchGetTokenError = (user) => ({
//   type: AuthActionTypes.FETCH_GET_AUTH_TOKEN_ERROR,
//   payload: user,
// })

// export const fetchGetToken = (data) => (dispatch) => {
//   dispatch(setFetchingGetToken(true))

//   return new Promise((res, rej) => ApiService.apiCall({
//     url: ApiService.paths.auth.GET_TOKEN,
//     data,
//     method: 'POST'
//   })
//     .then(({ token }) => {
//       StorageService.setToken(token)
//       dispatch(setFetchingGetToken(false))
//       dispatch(fetchGetTokenSuccess(token))
//       return res(token)
//     })
//     .catch(({ data }) => {
//       dispatch(setFetchingGetToken(false))
//       dispatch(fetchGetTokenError(data))
//       return rej(data)
//     }))
// }

export const setFetchingLogin = (value) => ({
  type: AuthActionTypes.SET_FETCHING_LOGIN,
  payload: value,
})

export const fetchLoginSuccess = (value) => ({
  type: AuthActionTypes.FETCH_LOGIN_SUCCESS,
  payload: value,
})

export const fetchLogin = (data) => (dispatch) => {
  dispatch(setFetchingLogin(true))

  dispatch({
    type: AuthActionTypes.SET_IS_404,
    payload: false,
  })

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.auth.LOGIN,
    data,
    method: 'POST',
  })
    .then(({ token, user }) => {
      StorageService.setToken(token)
      StorageService.setUserSession(user)

      dispatch(setFetchingGetUser(false))
      dispatch(fetchGetUser(token))

      if (user.is_new) dispatch(setIsNewUser(user.is_new))

      return res(token)
    })
    .catch(({ data }) => {
      dispatch(setFetchingGetUser(false))
      return rej(data)
    }))
}

export const fetchLogoutSuccess = (value) => ({
  type: AuthActionTypes.FETCH_LOGOUT_SUCCESS,
  payload: value,
})

export const fetchLogout = (is404 = false) => (dispatch) => {
  dispatch(fetchLogoutSuccess(false))

  if (is404) {
    dispatch({
      type: AuthActionTypes.SET_IS_404,
      payload: true,
    })
  }

  StorageService.removeUserSession()
  StorageService.removeToken()
  StorageService.removeLastConversation()
}

export const setFetchingChangePassword = (value) => ({
  type: AuthActionTypes.SET_FETCHING_CHANGE_PASSWORD,
  payload: value,
})

export const fetchChangePasswordSuccess = (value) => ({
  type: AuthActionTypes.FETCH_CHANGE_PASSWORD,
  payload: value,
})

export const fetchChangePassword = (data, token = null) => (dispatch) => {
  dispatch(setFetchingChangePassword(true))

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    data,
    token: token || StorageService.getToken(),
    url: ApiService.paths.auth.CHANGE_PASSWORD,
  })
    .then((response) => {
      dispatch(setFetchingChangePassword(false))
      dispatch(
        setNotification({
          message: 'Пароль успешно изменен',
          type: messageTypes.MESSAGE,
        })
      )

      return res(response)
    })
    .catch((error) => {
      dispatch(setFetchingChangePassword(false))
      return rej(error)
    }))
}

export const setFetchingCodeVerification = (value) => ({
  type: AuthActionTypes.SET_FETCHING_CODE_VERIFICATION,
  payload: value,
})

export const fetchCodeVerificationSuccess = (value) => ({
  type: AuthActionTypes.FETCH_CODE_VERIFICATION_SUCCESS,
  payload: value,
})

export const fetchCodeVerificationError = (value) => ({
  type: AuthActionTypes.FETCH_CODE_VERIFICATION_ERROR,
  payload: value,
})

export const fetchCodeVerification = ({ phone, code }) => (dispatch) => {
  dispatch(setFetchingCodeVerification(true))

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.auth.CODE_VERIFY,
    data: {
      phone,
      code
    },
    method: 'POST'
  })
    .then(({ token }) => {
      StorageService.setToken(token)
      dispatch(fetchCodeVerificationSuccess())
      dispatch(setFetchingCodeVerification(false))
      return res(token)
    })
    .catch((error) => {
      dispatch(fetchCodeVerificationError(error))
      dispatch(setFetchingCodeVerification(false))

      return rej(error)
    }))
}
