export const AnalyticClinicalIndicatorsActionTypes = {
  SET_SORT_BY: 'SET_SORT_BY',
  SET_MONTH_START: 'SET_MONTH_START',
  SET_MONTH_END: 'SET_MONTH_END',
  SET_CONSULTATIONS_INFO: 'SET_CONSULTATIONS_INFO',
  SET_DOCTORS_INFO: 'SET_DOCTORS_INFO',
  SET_PATIENTS_INFO: 'SET_PATIENTS_INFO',
  SET_TASKS_INFO: 'SET_TASKS_INFO',
  SET_DATA_TABLE: 'SET_DATA_TABLE',
  SET_FULL_SCREEN: 'SET_FULL_SCREEN',
  ADD_DATA_TABLE: 'ADD_DATA_TABLE',
}
