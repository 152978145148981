import { useMemo } from 'react'
import s from 'styled-components'
import cn from 'classnames'

import Title from 'components/typo/Title'
import LinkBack from 'components/typo/LinkBack'
import ButtonClose from 'components/buttons/ButtonClose'
import Positioner from 'components/Positioner'

const getStyles = ({
  theme,
  $borderBottom,
  $bgColor,
  $p
}) => `
  position: sticky;
  top: 0;
  padding: ${$p || '0 24px'};
  border-radius: 24px 24px 0 0;
  border-bottom: ${$borderBottom ? `1px solid ${theme.colors[$borderBottom] || theme.colors.grey150}` : ''};
  background: ${theme.colors[$bgColor]};

  .s-inner {
    padding: 16px 0;
    min-height: 69px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &-right {
      margin-left: auto;
      margin-right: 30px;
      padding-left: 16px;
    }
  }

  .a-close {
    position: absolute;
    top: 8px;
    right: 8px;
  }
`

const ModalContainer = s.div`
  ${getStyles}
`

const ModalHeadSidebar = ({
  className = '',
  onClose = null,
  renderModalContent = () => {},
  headRight = null,
  title = '',
  renderTitle = null,
  borderBottom = null,
  onBack = null,
  bgColor = 'white',
  p = '',
}) => {
  const titleText = useMemo(
    () => (renderTitle
      ? renderTitle()
      : title
        ? (
          <Title
            size="medium"
            title={title}
          />
        ) : null),
    [ title, renderTitle ]
  )

  const titleContent = useMemo(
    () => (onBack ? (
      <LinkBack title={title} onBack={onBack} />
    ) : titleText),
    [ onBack, titleText, title ]
  )

  return (
    <ModalContainer
      className={cn('s-head', className)}
      $borderBottom={borderBottom}
      $p={p}
      $bgColor={bgColor}
    >
      <div className="s-inner">
        {titleContent}
        {!!onClose && (
          <Positioner right="16px" top="16px">
            <ButtonClose size="xs" onClick={onClose} />
          </Positioner>
        )}
        {headRight && (
          <div className="s-inner-right">
            {headRight}
          </div>
        )}
      </div>
      {renderModalContent()}
    </ModalContainer>
  )
}

export default ModalHeadSidebar
