import { combineReducers } from 'redux'

import analyses from './Analyses/ReferenceAnalysesReducer'
import manipulations from './Manipulations/ReferenceManipulationsReducer'
import root from './ReferenceReducer'

const rootReferenceReducer = combineReducers({
  analyses,
  manipulations,
  root,
})

export default rootReferenceReducer
