import s from 'styled-components'
import cn from 'classnames'

import { Link, Text } from 'components/typo'
import useResponsive from 'hooks/useResponsive'
import Spinner from 'components/base/Spinner'

const styles = ({
  size = 'normal',
  theme,
  defaultStyle,
  fill,
  stroke,
  fillHover,
  p,
  svgWidth,
  svgHeight,
  bgHover,
  isFetching,
  $br,
  $bdFilter,
}) => {
  const btnTheme = theme.components.buttonRound
  const btnThemeStyles = theme.components.button.styles
  const sizes = btnTheme.sizes[size] || btnTheme.sizes.normal
  const sizesMob = size === 'xl' ? btnTheme.sizes.big : sizes
  const styles = btnThemeStyles[defaultStyle] || btnThemeStyles.acsent

  return `
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${sizes.size};
    height: ${sizes.size};
    padding: ${p || sizes.padding};
    background-color: ${styles.bg};
    box-shadow: ${styles.bs || ''};
    border: 1px solid ${styles.bc || theme.colors.transparent};
    color: ${styles.color || ''};
    border-radius: ${$br ? theme.radius[$br] || $br : theme.radius.small};
    transition: background-color 0.3s ease-in-out;
    backdrop-filter: ${styles.blur || ''};
    flex-shrink: 0;
    pointer-events: all;
    backdrop-filter: ${$bdFilter};

    &:disabled {
      background-color: ${styles.disabledBg || ''};
      background-image: unset;
      color: ${styles.disabledColor || ''};
      opacity: ${styles.disabledBg ? '' : 0.4};
      pointer-events: none;

      &.--opacity-f {
        opacity: 1;
      }
    }

    &.--blured {
      backdrop-filter: blur(40px);
    }

    @media (max-width: 768px) {
      width: ${sizesMob.size};
      height: ${sizesMob.size};
      padding: ${p || sizesMob.padding};
    }

    svg {
      width: ${svgWidth || 'auto'};
      height: ${svgHeight || 'auto'};
      transition: fill 0.3s ease-in-out, stroke 0.3s ease-in-out;
      fill: ${fill ? theme.colors[fill] || fill : styles.fill || styles.color} !important;
      stroke: ${((stroke || fill) && theme.colors[stroke || fill]) ? theme.colors[stroke || fill] : styles.fill} !important;
    }

    &.--hovered,
    &:hover {
      background-color: ${bgHover && theme[bgHover] ? theme[bgHover] || bgHover : styles.bgHover || ''};

      svg {
        fill: ${fillHover ? theme.colors[fillHover] : styles.fillHover} !important;
        stroke: ${fillHover ? theme.colors[fillHover] : styles.fillHover} !important;
      }
    }

    .count-wrapper {
      position: absolute;
      top: -2px;
      right: -1px;
      z-index: 3;
    }

    svg {
      pointer-events: none;
    }

    ${isFetching ? `
      & > * {
        opacity: 0;
      }
    ` : ''}
  `
}

const ButtonRoundBtnContainer = s.button`
  ${(props) => styles(props)}
`

const ButtonRoundLinkContainer = s(Link)`
  ${(props) => styles(props)}
`

const ButtonRound = ({
  icon = null,
  renderIcon = null,
  type = 'button',
  className = '',
  as,
  children,
  title = '',
  defaultStyle = 'light',
  size = 'normal',
  isHovered = false,
  isFetching = false,
  isDisabled = false,
  spinnerColor = 'currentColor',
  br = '',
  bdFilter = '',
  innerRef = null,
  ...otherProps
}) => {
  const classes = cn('w-button-round', {
    '--hovered': isHovered,
    '--disabled': isDisabled
  }, className)

  const props = {
    defaultStyle,
    size,
    className: classes,
    isDisabled,
    isFetching,
    $br: br,
    ...otherProps,
  }
  const { isLaptopOrMobile } = useResponsive()

  const Component = as === 'link'
    ? ButtonRoundLinkContainer
    : ButtonRoundBtnContainer

  return (
    <Component
      type={type}
      disabled={isDisabled}
      $bdFilter={bdFilter}
      ref={innerRef}
      {...props}
    >
      {children}
      {title && (
        <Text
          fw="sb"
          size={isLaptopOrMobile ? 'normal' : 'medium'}
          className="--h5"
          color="white"
        >
          {title}
        </Text>
      )}
      {!isFetching && renderIcon ? renderIcon() : icon}
      {isFetching && <Spinner size="small" fillColor={spinnerColor} />}
    </Component>
  )
}

export default ButtonRound
