import { useCallback } from 'react'
import s from 'styled-components'

import TabsItem from './TabsItem'

const defaultStyles = ({
  theme,
  $jc,
  $isBorder,
  $p
}) => `
  display: flex;
  border-bottom: ${$isBorder ? `1px solid ${theme.colors.grey150}` : ''};
  padding: ${$p};
  whidth: 100%;
  flex-wrap: wrap;
  justify-content: ${$jc};
  gap: 8px;
`

const TabsContainer = s.div`
  ${(props) => defaultStyles(props)}
`

const Tabs = ({
  children,
  items = [],
  onToggle = () => {},
  active,
  isBorder = false,
  isTags = false,
  isSingle = false,
  isDisabled = false,
  jc = 'flex-start',
  ...otherProps
}) => {
  const isActive = useCallback((id) => {
    if (!active || isDisabled) {
      return false
    }

    if (Array.isArray(active)) {
      return active.includes(id)
    }

    return active === id
  }, [ active, isDisabled ])

  return (
    <TabsContainer
      className="w-tabs"
      $isBorder={isBorder}
      $p="sdf"
      $jc={jc}
      {...otherProps}
    >
      {items.map((tab, idx) => (
        <TabsItem
          {...tab}
          key={tab.id}
          isTag={isTags}
          active={isActive(tab.id)}
          isDisabled={tab.isDisabled || isDisabled}
          onClick={isDisabled ? null : () => onToggle(tab.id)}
          isSingle={tab.isSingle || isSingle}
        />
      ))}
    </TabsContainer>
  )
}

export default Tabs
