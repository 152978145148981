export const MediaActionTypes = {
  SET_MEDIA_LOADING_START: 'SET_MEDIA_LOADING_START',
  SET_MEDIA_LOADING_SUCCESS: 'SET_MEDIA_LOADING_SUCCESS',
  SET_MEDIA_LOADING_ERROR: 'SET_MEDIA_LOADING_ERROR',

  SET_FETCHING_MEDIA_START: 'SET_FETCHING_MEDIA_START',
  FETCH_MEDIA_ERROR: 'FETCH_MEDIA_ERROR',
  FETCH_MEDIA_SUCCESS: 'FETCH_MEDIA_SUCCESS',

  SET_FETCHING_UPLOAD_MEDIA_START: 'SET_FETCHING_UPLOAD_MEDIA_START',
  FETCH_UPLOAD_MEDIA_ERROR: 'FETCH_UPLOAD_MEDIA_ERROR',
  FETCH_UPLOAD_MEDIA_SUCCESS: 'FETCH_UPLOAD_MEDIA_SUCCESS',

  SET_FETCHING_DELETE_MEDIA_START: 'SET_FETCHING_DELETE_MEDIA_START',
  FETCH_DELETE_MEDIA_ERROR: 'FETCH_DELETE_MEDIA_ERROR',
  FETCH_DELETE_MEDIA_SUCCESS: 'FETCH_DELETE_MEDIA_SUCCESS',

  SET_FETCHING_UPDATE_MEDIA_START: 'SET_FETCHING_UPDATE_MEDIA_START',
  FETCH_UPDATE_MEDIA_ERROR: 'FETCH_UPDATE_MEDIA_ERROR',
  FETCH_UPDATE_MEDIA_SUCCESS: 'FETCH_UPDATE_MEDIA_SUCCESS',

  SET_FETCHING_S3_CREATE_PRESIGN_URL: 'SET_FETCHING_S3_CREATE_PRESIGN_URL',
  FETCH_S3_CREATE_PRESIGN_URL_ERROR: 'FETCH_S3_CREATE_PRESIGN_URL_ERROR',
  FETCH_S3_CREATE_PRESIGN_URL_SUCCESS: 'FETCH_S3_CREATE_PRESIGN_URL_SUCCESS',

  SET_FETCHING_UPLOAD_MEDIA_S3: 'SET_FETCHING_UPLOAD_MEDIA_S3',
  FETCH_UPLOAD_MEDIA_S3_ERROR: 'FETCH_UPLOAD_MEDIA_S3_ERROR',
  FETCH_UPLOAD_MEDIA_S3_SUCCESS: 'FETCH_UPLOAD_MEDIA_S3_SUCCESS',

  SET_MEDIA_LOADED: 'SET_MEDIA_LOADED',
  SET_MEDIA_UPLOAD_PROGRESS: 'SET_MEDIA_UPLOAD_PROGRESS',
  ADD_FILE_CANCEL_UPLOADING: 'ADD_FILE_CANCEL_UPLOADING',
}
