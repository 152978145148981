import { ReactComponent as Icon } from 'assets/icons/close.svg'

import ButtonRound from 'components/base/ButtonRound'

const ButtonClose = ({
  defaultStyle = 'transparent',
  br = '50%',
  size = 'small',
  ...props
}) => (
  <ButtonRound
    br={br}
    size={size}
    defaultStyle={defaultStyle}
    renderIcon={() => <Icon />}
    {...props}
  />
)

export default ButtonClose
