import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import s from 'styled-components'

import { theme } from 'theme'
import { getTypoCommonStyles } from './typo.styles'

const LinkContainerBrowser = s.a`
  ${getTypoCommonStyles}
`

const LinkContainerRouter = s(Link)`
  ${getTypoCommonStyles}
`

const linkStyles = {
  acsent: [ 'black', 'blue80' ],
  blue: [ 'blue', 'blue700' ]
}

const LinkRoute = ({
  children,
  defaultStyle = 'acsent',
  size = 'normal',
  className = '',
  title = '',
  textAlign = '',
  isBrowser = false,
  noSelect = false,
  cursor = '',
  hoverColor,
  display = '',
  maxWidth = '',
  color,
  noPointers = false,
  nowrap = false,
  caps = false,
  isActive = false,
  td = '',
  fw = '',
  lh = '',
  fs = '',
  ff = '',
  ls = '',
  ...otherProps
}) => {
  const classes = useMemo(() => cn(
    'b-link',
    {
      [`--ta-${textAlign}`]: textAlign,
      '--no-select': noSelect,
      '--active': isActive,
    },
    className
  ), [ textAlign, noSelect, className, isActive ])

  const [ styleColor, styleHoverColor ] = useMemo(
    () => (defaultStyle
      ? linkStyles[defaultStyle] || []
      : []),
    [ defaultStyle ]
  )

  const props = {
    className: classes,
    cursor,
    size,
    $hoverColor: hoverColor || styleHoverColor,
    display,
    $maxWidth: maxWidth,
    color: color || styleColor,
    $noPointers: noPointers,
    $nowrap: nowrap,
    $caps: caps,
    td,
    fw,
    lh,
    fs,
    ff,
    ls,
    ...otherProps,
  }

  if (isBrowser) {
    return <LinkContainerBrowser target="blank" {...props}>{children || title}</LinkContainerBrowser>
  }
  return <LinkContainerRouter {...props}>{children || title}</LinkContainerRouter>
}

LinkRoute.propTypes = { color: PropTypes.oneOf(Object.keys(theme.colors)) }

export default LinkRoute
