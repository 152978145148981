import { AdministratorsActionTypes } from 'redux/dashboard/Administrators/AdministratorsTypes'
import StorageService from 'services/StorageService'

const INITIAL_STATE = {
  list: null,
  offset: 0,
  maxOffset: 0,
  listCount: 0,
  lastSearch: null,
  isFetching: false,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: StorageService.getAdministratorsFilters() || {},

  error: null,
}

const administratorsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AdministratorsActionTypes.SET_FETCHING_ADMINISTRATORS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }
  case AdministratorsActionTypes.SET_FETCHING_ADMINISTRATORS_LIST:
    return {
      ...state,
      isFetching: action.payload
    }
  case AdministratorsActionTypes.SET_ADMINISTRATORS_LIST:
    return {
      ...state,
      list: action.payload.results,
      maxOffset: action.payload.count,
      offset: 40,
    }
  case AdministratorsActionTypes.SET_ADMINISTRATORS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }
  case AdministratorsActionTypes.SET_ADMINISTRATORS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }
  case AdministratorsActionTypes.FETCH_ADMINISTRATORS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }
  case AdministratorsActionTypes.SET_ADMINISTRATORS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }
  case AdministratorsActionTypes.CLEAR_ADMINISTRATORS_SEARCH:
    return {
      ...state,
      searchList: []
    }
  case AdministratorsActionTypes.CLEAR_ADMINISTRATORS_LIST:
    return {
      ...state,
      list: null
    }
  default:
    return state
  }
}

export default administratorsReducer
