import { ButtonRound } from 'components/base'
import { ReactComponent as IconTrash } from 'assets/icons/trash.svg'
import { ReactComponent as IconCircle } from 'assets/icons/clear-circle.svg'
import { ReactComponent as IconCross } from 'assets/icons/close.svg'
import { useMemo } from 'react'

const ButtonClear = ({
  size = 'xs',
  defaultStyle = 'settings',
  onClick = () => {},
  icon = 'trash',
  isHovered = false,
  isFetching = false,
  br = 'round',
  fillHover = 'grey500',
  iconProps = null,
  isDisabled = false,
}) => {
  const Icon = useMemo(() => {
    switch (icon) {
    case 'circle':
      return IconCircle
    case 'cross':
      return IconCross
    case 'trash':
    default:
      return IconTrash
    }
  }, [ icon ])

  return (
    <ButtonRound
      defaultStyle={defaultStyle}
      size={size}
      renderIcon={() => <Icon {...iconProps} />}
      onClick={() => {
        if (!isDisabled) onClick()
      }}
      isHovered={isHovered}
      isFetching={isFetching}
      br={br}
      fillHover={fillHover}
      isDisabled={isDisabled}
    />
  )
}

export default ButtonClear
