import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { DoctorsActionTypes } from 'redux/dashboard/Doctors/DoctorsTypes'

import { getNewFilters } from 'redux/helpers/FilterHelpers'
import { FiltersSortUserTypes, FiltersTypes } from 'constants/FiltersTypes'

const INITIAL_STATE = {
  list: null,
  listCount: 0,
  lastSearch: null,
  isFetching: false,

  doctorsActiveCount: 0,
  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: { [FiltersTypes.ORDERING]: FiltersSortUserTypes.ALPHABET_DOWN },

  error: null,
}

const doctorsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case DoctorsActionTypes.SET_FETCHING_DOCTORS_LIST:
    return {
      ...state,
      isFetching: action.payload,
    }

  case DoctorsActionTypes.FETCH_DOCTORS_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload,
    }

  case DoctorsActionTypes.FETCH_UPLOAD_DOCTORS_LIST_SUCCESS:
    return {
      ...state,
      list: [ ...state.list, ...action.payload ]
    }

  case DoctorsActionTypes.SET_DOCTORS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case DoctorsActionTypes.SET_DOCTORS_ACTIVE_COUNT:
    return {
      ...state,
      doctorsActiveCount: action.payload
    }

  case DoctorsActionTypes.SET_DOCTORS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case DoctorsActionTypes.SET_DOCTORS_FILTERS:
    return {
      ...state,
      filters: getNewFilters(state.filters, action.payload)
    }

  case DoctorsActionTypes.SET_DOCTORS_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value
      }
    }

  case DoctorsActionTypes.SET_FETCHING_DOCTORS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case DoctorsActionTypes.FETCH_DOCTORS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case DoctorsActionTypes.CLEAR_DOCTORS_SEARCH:
    return {
      ...state,
      searchList: []
    }

  case DoctorsActionTypes.SET_DOCTORS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  default:
    return state
  }
}

export default doctorsReducer
