export const DoctorsActionTypes = {
  SET_FETCHING_DOCTORS_LIST: 'SET_FETCHING_DOCTORS_LIST',
  FETCH_DOCTORS_LIST_SUCCESS: 'FETCH_DOCTORS_LIST_SUCCESS',
  FETCH_DOCTORS_LIST_ERROR: 'FETCH_DOCTORS_LIST_ERROR',
  CLEAR_DOCTORS_LIST: 'CLEAR_DOCTORS_LIST',
  SET_DOCTORS_LIST_LAST_SEARCH: 'SET_DOCTORS_LIST_LAST_SEARCH',
  SET_DOCTORS_LIST_COUNT: 'SET_DOCTORS_LIST_COUNT',
  SET_DOCTORS_ACTIVE_COUNT: 'SET_DOCTORS_ACTIVE_COUNT',

  SET_FETCHING_DOCTORS_SEARCH: 'SET_FETCHING_DOCTORS_SEARCH',
  FETCH_DOCTORS_SEARCH_SUCCESS: 'FETCH_DOCTORS_SEARCH_SUCCESS',
  FETCH_DOCTORS_SEARCH_ERROR: 'FETCH_DOCTORS_SEARCH_ERROR',
  CLEAR_DOCTORS_SEARCH: 'CLEAR_DOCTORS_SEARCH',

  SET_FETCHING_DOCTOR_SPECIALIZATIONS: 'SET_FETCHING_DOCTOR_SPECIALIZATIONS',
  FETCH_DOCTOR_SPECIALIZATIONS_SUCCESS: 'FETCH_DOCTOR_SPECIALIZATIONS_SUCCESS',
  FETCH_DOCTOR_SPECIALIZATIONS_ERROR: 'FETCH_DOCTOR_SPECIALIZATIONS_ERROR',
  CLEAR_DOCTOR_SPECIALIZATIONS: 'CLEAR_DOCTOR_SPECIALIZATIONS',

  SET_FETCHING_UPLOAD_DOCTORS_LIST: 'SET_FETCHING_UPLOAD_DOCTORS_LIST',
  FETCH_UPLOAD_DOCTORS_LIST_SUCCESS: 'FETCH_UPLOAD_DOCTORS_LIST_SUCCESS',
  FETCH_UPLOAD_DOCTORS_LIST_ERROR: 'FETCH_UPLOAD_DOCTORS_LIST_ERROR',

  SET_DOCTORS_FILTERS: 'SET_DOCTORS_FILTERS',
  SET_DOCTORS_FILTER: 'SET_DOCTORS_FILTER',
  SET_DOCTORS_SEARCH_VALUE: 'SET_DOCTORS_SEARCH_VALUE',

}
