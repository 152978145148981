import { combineReducers } from 'redux'

import auth from 'redux/Auth/AuthReducer'
import dashboard from './dashboard/rootDashboardReducer'
import media from './Media/MediaReducer'
import user from './User/UserReducer'
import rates from './Rates/RatesReducer'
import system from './System/SystemReducer'
import location from './Location/LocationReducer'
import notifications from './Notifications/NotificationsReducer'

const rootReducer = combineReducers({
  auth,
  dashboard,
  user,
  location,
  media,
  notifications,
  rates,
  system,
})

export default rootReducer
