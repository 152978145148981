export const PatientsActionTypes = {
  SET_FETCHING_PATIENTS_LIST: 'SET_FETCHING_PATIENTS_LIST',
  FETCH_PATIENTS_LIST_SUCCESS: 'FETCH_PATIENTS_LIST_SUCCESS',
  FETCH_PATIENTS_LIST_ERROR: 'FETCH_PATIENTS_LIST_ERROR',
  CLEAR_PATIENTS_LIST: 'CLEAR_PATIENTS_LIST',
  SET_PATIENTS_LIST_LAST_SEARCH: 'SET_PATIENTS_LIST_LAST_SEARCH',
  SET_PATIENTS_LIST_COUNT: 'SET_PATIENTS_LIST_COUNT',
  ADD_PATIENT_TO_LIST: 'ADD_PATIENT_TO_LIST',

  SET_FETCHING_PATIENTS_SEARCH: 'SET_FETCHING_PATIENTS_SEARCH',
  FETCH_PATIENTS_SEARCH_SUCCESS: 'FETCH_PATIENTS_SEARCH_SUCCESS',
  FETCH_PATIENTS_SEARCH_ERROR: 'FETCH_PATIENTS_SEARCH_ERROR',
  CLEAR_PATIENTS_SEARCH: 'CLEAR_PATIENTS_SEARCH',

  SET_FETCHING_UPLOAD_PATIENTS_LIST: 'SET_FETCHING_UPLOAD_PATIENTS_LIST',
  FETCH_UPLOAD_PATIENTS_LIST_ERROR: 'FETCH_UPLOAD_PATIENTS_LIST_ERROR',
  FETCH_UPLOAD_PATIENTS_LIST_SUCCESS: 'FETCH_UPLOAD_PATIENTS_LIST_SUCCESS',
  SET_PATIENTS_LIST: 'SET_PATIENTS_LIST',
  ADD_PATIENTS_LIST: 'ADD_PATIENTS_LIST',

  SET_PATIENTS_FILTERS: 'SET_PATIENTS_FILTERS',
  SET_PATIENTS_FILTER: 'SET_PATIENTS_FILTER',
  SET_PATIENTS_SEARCH_VALUE: 'SET_PATIENTS_SEARCH_VALUE',

  SET_PATIENTS_COUNT_ALL: 'SET_PATIENTS_COUNT_ALL',
  SET_PATIENTS_COUNT_IS_ACCEPTED: 'SET_PATIENTS_COUNT_IS_ACCEPTED',
  SET_PATIENTS_LIST_LAYOUT: 'SET_PATIENTS_LIST_LAYOUT',
}
