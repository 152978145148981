import { AuthActionTypes } from 'redux/Auth/AuthTypes'
import { ClientsActionTypes } from 'redux/dashboard/Clients/ClientsTypes'

import { getNewFilters } from 'redux/helpers/FilterHelpers'
import { FiltersSortUserTypes, FiltersTypes } from 'constants/FiltersTypes'

const INITIAL_STATE = {
  list: null,
  listCount: 0,
  lastSearch: '',
  isFetching: false,

  searchList: null,
  isFetchingSearchList: false,
  searchValue: '',

  filters: { [FiltersTypes.ORDERING]: FiltersSortUserTypes.ALPHABET_DOWN },

  error: null,
}

const clientsReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE }

  case ClientsActionTypes.FETCH_CLIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: action.payload
    }

  case ClientsActionTypes.SET_FETCHING_CLIENTS_LIST:
    return {
      ...state,
      isFetching: action.payload
    }

  case ClientsActionTypes.SET_CLIENTS_LIST_COUNT:
    return {
      ...state,
      listCount: action.payload
    }

  case ClientsActionTypes.SET_CLIENTS_LIST_LAST_SEARCH:
    return {
      ...state,
      lastSearch: action.payload
    }

  case ClientsActionTypes.FETCH_UPLOAD_CLIENTS_LIST_SUCCESS:
    return {
      ...state,
      list: [ ...state.list, ...action.payload ]
    }

  case ClientsActionTypes.SET_CLIENTS_FILTERS:
    return {
      ...state,
      filters: getNewFilters(state.filters, action.payload)
    }

  case ClientsActionTypes.SET_CLIENTS_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.payload.name]: action.payload.value
      }
    }

  case ClientsActionTypes.SET_FETCHING_CLIENTS_SEARCH:
    return {
      ...state,
      isFetchingSearchList: action.payload
    }

  case ClientsActionTypes.FETCH_CLIENTS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload
    }

  case ClientsActionTypes.CLEAR_CLIENTS_SEARCH:
    return {
      ...state,
      searchList: [],
      searchValue: '',
    }

  case ClientsActionTypes.SET_CLIENTS_SEARCH_VALUE:
    return {
      ...state,
      searchValue: action.payload
    }

  default:
    return state
  }
}

export default clientsReducer
