class StorageService {
  constructor() {
    this.storage = localStorage
    this.sessionStorage = sessionStorage
    this.token = 't_token'
    this.uSession = 't_usession'
    this.emailResetPassword = 'email_reset_password'
    this.codeResetPassword = 'code_reset_password'
    this.filters = 'patient_filters'
    this.filtersClinicRecord = 'filters_clinic_record'
    this.filtersClinicRecordSearch = 'filters_clinic_record_search'
    this.structureLayout = 'st_lt'
    this.notificationsSettings = 'ntfctns_sttngs'
    this.tasksView = 't_vw'
    this.diaryAnalyticsSettings = 't_das'
    this.lastConversation = 'last_conversation'
    this.tokenLastConversation = 'token_last_conversation'
  }

  /* storage common actions */
  setItem(name, value) {
    if (name) this.storage.setItem(name, value)
  }

  getItem(name) {
    return this.storage.getItem(name) || ''
  }

  removeItem(name) {
    this.storage.removeItem(name)
  }

  /* storage token actions */
  getToken() {
    return this.storage.getItem(this.token) || ''
  }

  setToken(token) {
    if (token) this.storage.setItem(this.token, token)
  }

  removeToken() {
    this.storage.removeItem(this.token)
  }

  /* user auth session */
  getUserSession() {
    return JSON.parse(this.storage.getItem(this.uSession)) || ''
  }

  setUserSession(uSession) {
    if (uSession) this.storage.setItem(this.uSession, JSON.stringify(uSession))
  }

  removeUserSession() {
    this.storage.removeItem(this.uSession)
  }

  /* filters */
  getPatientFilters() {
    return JSON.parse(this.storage.getItem(this.filters)) || null
  }

  setPatientFilters(filters) {
    if (filters) this.storage.setItem(this.filters, JSON.stringify(filters))
  }

  removePatientFilters() {
    this.storage.removeItem(this.filters)
  }

  getAdministratorsFilters() {
    return JSON.parse(this.storage.getItem(this.filters)) || null
  }

  /* filters clinic record */
  getClinicRecordFilters() {
    return JSON.parse(this.sessionStorage.getItem(this.filtersClinicRecord)) || null
  }

  setClinicRecordFilters(filters) {
    if (filters) this.sessionStorage.setItem(this.filtersClinicRecord, JSON.stringify(filters))
  }

  removeClinicRecordFilters() {
    this.sessionStorage.removeItem(this.filtersClinicRecord)
  }

  /* filters clinic record search */
  getClinicRecordFilterSearch() {
    return this.sessionStorage.getItem(this.filtersClinicRecordSearch) || null
  }

  setClinicRecordFilterSearch(filters) {
    if (filters) this.sessionStorage.setItem(this.filtersClinicRecordSearch, filters)
  }

  removeClinicRecordFilterSearch() {
    this.sessionStorage.removeItem(this.filtersClinicRecordSearch)
  }

  /* layout */
  getStructureLayout(type) {
    const layouts = JSON.parse(this.storage.getItem(this.structureLayout)) || null

    if (layouts && type) {
      return layouts[type] || null
    }

    return layouts
  }

  setLayouts(type, value) {
    if (type) {
      const prevLayouts = this.getStructureLayout()

      this.storage.setItem(this.structureLayout, JSON.stringify(prevLayouts ? {
        ...prevLayouts,
        [type]: value
      } : { [type]: value }))
    }
  }

  removeLayouts() {
    this.storage.removeItem(this.structureLayout)
  }

  /* tasks view */
  getTasksView() {
    return JSON.parse(this.storage.getItem(this.tasksView)) || null
  }

  setTasksView(value) {
    this.storage.setItem(this.tasksView, JSON.stringify(value))
  }

  removeTasksView() {
    this.storage.removeItem(this.tasksView)
  }

  /* diary analytic settings */
  getDiaryAnalyticSettings(type) {
    const settingsAll = JSON.parse(this.storage.getItem(this.diaryAnalyticsSettings)) || null
    return settingsAll ? settingsAll[type] || null : null
  }

  setDiaryAnalyticSettings(type, settings) {
    const settingsAll = JSON.parse(this.storage.getItem(this.diaryAnalyticsSettings)) || {}
    this.storage.setItem(this.diaryAnalyticsSettings, JSON.stringify({
      ...settingsAll,
      [type]: settings
    }))
  }

  removeDiaryAnalyticSettings() {
    this.storage.removeItem(this.diaryAnalyticsSettings)
  }

  /* storage token last conversation */
  getTokenLastConversation() {
    return this.storage.getItem(this.tokenLastConversation) || ''
  }

  setTokenLastConversation() {
    this.storage.setItem(this.tokenLastConversation, this.getToken())
  }

  removeTokenLastConversation() {
    this.storage.removeItem(this.tokenLastConversation)
  }

  /* last conversation */
  setLastConversation(conversation) {
    this.storage.setItem(this.lastConversation, JSON.stringify(conversation))
  }

  getLastConversation() {
    return JSON.parse(this.storage.getItem(this.lastConversation))
  }

  removeLastConversation() {
    this.storage.removeItem(this.lastConversation)
  }

  /* notifications settings */
  getNotificationsSettings() {
    return JSON.parse(this.storage.getItem(this.notificationsSettings)) || {}
  }

  setNotificationsSettings(value = {}) {
    const settings = this.getNotificationsSettings()
    this.storage.setItem(this.notificationsSettings, JSON.stringify({
      ...settings,
      ...value
    }))
  }

  removeNotificationsSettings() {
    this.storage.removeItem(this.notificationsSettings)
  }

  removePasswordChangeData() {
    this.storage.removeItem(this.emailResetPassword)
    this.storage.removeItem(this.codeResetPassword)
  }
}

export default new StorageService()
